import React from "react";
import AuthorizedComponent from "./AuthorizedComponent";
import {useSelector} from "react-redux";

const SelectCompanyDropdown = (props)=>{
    const companyList = useSelector((state) =>  state.users?.companyList);
    return (
        <AuthorizedComponent allowedRoles={props.allowedRoles} AuthComponent={<div className='input-box'>
            <span className='details'>Company Name</span>
            <select className='select'  name={props.name ?? 'source'} value={props.source} onChange={(e)=> props.handleChange(e)}>
               {/* <option value={""}>Select Company</option>*/}
                {companyList?.map((company) => {
                    return <option key={company?.id} value={company?.id} selected={company?.id === props.source}>{company?.name}</option>
                })}
            </select>
        </div>} Component={<input type={'hidden'} name={props.name ?? 'source'} value={props.source}/>}/>
    );
}
export default SelectCompanyDropdown;
