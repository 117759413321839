import React, { useState, useEffect } from 'react';
import Loader from './Loader';

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <body>
        <div className="linear-background">
            <div className="inter-draw"></div>
            <div className="inter-crop"></div>
            <div className="inter-right--top"></div>
            <div className="inter-right--bottom"></div>
        </div>
        <div className="linear-background">
            <div className="inter-draw"></div>
            <div className="inter-crop"></div>
            <div className="inter-right--top"></div>
            <div className="inter-right--bottom"></div>
        </div>
        <div className="linear-background">
            <div className="inter-draw"></div>
            <div className="inter-crop"></div>
            <div className="inter-right--top"></div>
            <div className="inter-right--bottom"></div>
        </div>
        </body>
      )}
    </div>
  );
};

export default App;
