import React from "react";
import Header from "./Header.js";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

//import Breadcrumb from "./BreadCrumb";

function AdminLayout() {
    return (
      <>
          <Header/>
          {/*<Breadcrumb/>*/}
          <Outlet/>
          <ToastContainer position="top-right" autoClose={3000} />
      </>
    )
  }

  export default AdminLayout;
