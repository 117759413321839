import React, {useMemo, useState,  useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useFetchData} from "../../../hooks/useFetchData";
import useAuth from "../../../hooks/useAuth";
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import CompanyPipeline from "../../WebSetting/website_settings/company/CompanyPipeline";
import {
    actionToGetOrderDetailApiCall,
    actionToGetOrderProcessingDetailApiCall
} from "../../../store/action/inventoryActions";
import {faEye, faFile, faFileUpload, faSitemap, faTruck} from "@fortawesome/free-solid-svg-icons";
import OrderStatusUpdate from "./OrderStatusUpdate";
import OrderModal from "./OrderModal";
import {indianDateFormat, indianDateTimeFormat} from "../../../store/utility/DatatableUtility";
import OrderItemModal from "./OrderItemModal";
import OrderRelateFileUploadFormModal from "./OrderRelateFileUploadFormModal";
import OrderRelatedDataTable from "./OrderRelatedDataTable";
import {actionToGetCompanyListApiCall} from "../../../store/action/commonActions";
import {useEffectOnce} from "../../../hooks/useEffectOnce";

const OrderDataTable = ({ setFetchDataTable, fetchDataTable }) => {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const eventTypeId = useSelector((state) => state.inventory?.inventoryEventTypeId);
    const [advanceFilter, setAdvanceFilter] = useState({ 'o.company_id': 2 });

    const { data: orders, fetchData, settings } = useFetchData('inventory/get-orders-table-data', [],advanceFilter);

    const [isEditOrderOpen, setIsEditOrderOpen] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    // Memoize the columns for performance
    const columns = useMemo(() => {
        const baseColumns = [
            { name: 'id', selector: row => row.id, selector_field:'id',
                cell: row => (
                    <>
                        {row.id}
                    </>
                ), sortable: true },

            { name: 'Item List', cell: row => (<>
                    <Tippy content='View Item List'>
                        <button onClick={()=>{setIsEditOrderOpen('item_list');
                            dispatch(actionToGetOrderDetailApiCall({id:row.id}));}} className='action-button'>
                            <FontAwesomeIcon className='action-icon' icon={faSitemap} />
                        </button>
                    </Tippy>
                </>) },
            { name: 'Status', selector: row => row.status, selector_field:'status',sortable: true },
            { name: 'Created Date', selector: row => row.created_at && indianDateTimeFormat(row.created_at), selector_field:'created_at',sortable: true },
            {
                name: 'Actions',
                cell: row => (
                    <>

                        <div className='action-buttons'>
                            <Tippy content='View Order'>
                                <button onClick={()=>{openOrderDetailModal(row)}} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faEye} />
                                </button>
                            </Tippy>
                        </div>
                        {!['Payment Failed','Delivered'].includes(row.status)  && <div className='action-buttons'>
                            <Tippy content='update Status'>
                                <button onClick={()=>{setIsEditOrderOpen('update-status');  setSelectedOrder(row); dispatch(actionToGetOrderProcessingDetailApiCall({id:row.id}))}} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTruck} />
                                </button>
                            </Tippy>
                        </div>}
                        {row.school_name  &&<><div className='action-buttons'>
                            <Tippy content='upload order related document'>
                                <button onClick={()=>{setIsEditOrderOpen('upload-order');  setSelectedOrder(row);}} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faFileUpload} />
                                </button>
                            </Tippy>
                        </div><div className='action-buttons'>
                            <Tippy content='order related document'>
                                <button onClick={()=>{setIsEditOrderOpen('order-related-document');  setSelectedOrder(row);}} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faFile} />
                                </button>
                            </Tippy>
                        </div></>}
                    </>
                ),
            },
        ];
        if(advanceFilter['o.company_id'] !== 2){
            baseColumns.splice(1, 0, { name: 'School', selector: row => row.school_name, sortable: true, width: '200px' });
            baseColumns.splice(1, 0,   { name: 'Order Name', selector: row => row.lead_name, sortable: true, width: '150px' },);
             baseColumns.splice(3, 0,   { name: 'Order Date', selector: row => row.order_date && indianDateFormat(row.order_date), sortable: true, width: '150px' },);
        }else{
            baseColumns.splice(1, 0,     { name: 'User Name', selector: row => row.user_name, selector_field:'user_name',sortable: true },);
        }

        return baseColumns;
    }, [orders]);

    const openOrderDetailModal = (row)=>{
        setIsEditOrderOpen('view_order');
        dispatch(actionToGetOrderDetailApiCall({id:row.id}));
    }

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable, fetchData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...advanceFilter,
            [name]: value
        };
        setAdvanceFilter(updatedFormData);
        setFetchDataTable(true);
    };
    useEffectOnce(()=>{
        dispatch(actionToGetCompanyListApiCall({in_sales_use:1}));
    })
    return (
        <>
            <CompanyPipeline source={advanceFilter['o.company_id']} changeCompany={val=>handleChange({target:{name:'o.company_id',value:val.id}})}/>
            <DataTableLayout keyField="id" columns={columns} data={orders.data} dataTableSetting={settings} fetchData={fetchData} totalRows={orders?.total} />
            {isEditOrderOpen ==='update-status' && <OrderStatusUpdate isOpen={isEditOrderOpen ==='update-status'} selectedOrder={selectedOrder} fetchData={fetchData} onRequestClose={() => {setIsEditOrderOpen(false); setSelectedOrder(false);}} />}
            {isEditOrderOpen ==='item_list' && <OrderItemModal isOpen={isEditOrderOpen ==='item_list'} selectedOrder={selectedOrder} fetchData={fetchData} onRequestClose={() => {setIsEditOrderOpen(false); setSelectedOrder(false);}} />}
            {isEditOrderOpen ==='upload-order' && <OrderRelateFileUploadFormModal isOpen={isEditOrderOpen ==='upload-order'} selectedOrder={selectedOrder} onRequestClose={() => {setIsEditOrderOpen(false); setSelectedOrder(false);}} />}
            {isEditOrderOpen ==='order-related-document' && <OrderRelatedDataTable  isOpen={isEditOrderOpen ==='order-related-document'} selectedOrder={selectedOrder} onRequestClose={() => {setIsEditOrderOpen(false); setSelectedOrder(false);}} />}
            <OrderModal isOpen={isEditOrderOpen === 'view_order'} onRequestClose={() => { setIsEditOrderOpen(false); }} />
            {}
        </>
    );
};

export default OrderDataTable;
