import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetCurriculumListApiCall} from "../../../store/action/curriculumAction";
import {callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {Editor} from "@tinymce/tinymce-react";
import {toast} from "react-toastify";


const TinyMceEditor= ({ label,error, onChange,value,name }) =>(
        <div className='editor-box'>
            <span className='details'>{label}</span>
            <Editor
                apiKey="nchrb4md98keu69dxd2sjsc25q52blhw3z0ryes84hw6wscr"
                initialValue={value}
                init={{
                    height: 500,
                    plugins: [
                        'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                        'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                        'table', 'emoticons', 'help'
                    ],
                    toolbar: 'undo redo | print preview code media fullscreen | styles bold italic | alignleft aligncenter alignright alignjustify |codeformat | ' +
                        'bullist numlist outdent indent | link image | ' +
                        'forecolor backcolor emoticons | help',
                    menu: {
                        favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                    },
                    menubar: 'favs file edit view insert format tools table help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                }}
                onEditorChange={(content)=>onChange({target:{name:name,value:content}})}
            />
            {error && <span className="error-message">{error}</span>}
        </div>

    );


export default TinyMceEditor;
