import {
    CURRICULUM_CONTENT_DATA,
    CURRICULUM_TABLE_DATA, GRADE_SUBJECT_TOPIC_CURRICULUM_TABLE_DATA, GRADE_SUBJECT_TOPIC_TABLE_DATA,
    GRADES_TABLE_DATA,
    SUBJECT_TABLE_DATA, TOPIC_TABLE_DATA
} from "../constant/curriculumConstant";

const initialState = {
    curriculumListData:[],
    curriculumContentListData:[],
    gradesListData:[],
    subjectsListData:[],
    topicsListData:[],
    gradeSubjectTopicListData:[],
    gradeSubjectTopicCurriculumListData:[],
    curriculumEventTypeId:4
}
export  const curriculumReducer = (state = initialState, action) => {
    switch (action.type) {
        case CURRICULUM_TABLE_DATA:
            return { ...state, curriculumListData: action.payload };
        case CURRICULUM_CONTENT_DATA:
            return { ...state, curriculumContentListData: action.payload };
        case GRADES_TABLE_DATA:
            return { ...state, gradesListData: action.payload };
        case SUBJECT_TABLE_DATA:
            return { ...state, subjectsListData: action.payload };
        case TOPIC_TABLE_DATA:
            return { ...state, topicsListData: action.payload };
        case GRADE_SUBJECT_TOPIC_TABLE_DATA:
            return { ...state, gradeSubjectTopicListData: action.payload };
        case GRADE_SUBJECT_TOPIC_CURRICULUM_TABLE_DATA:
            return { ...state, gradeSubjectTopicCurriculumListData: action.payload };
        default:
            return state
    }
}
