import React from 'react';
import Modal from 'react-modal';
import { useSelector} from "react-redux";


const MenuPreview= ({ isOpen,selectedData, onRequestClose }) => {
    const menuDataList = useSelector((state) =>  state.webSetting.menuDataList);

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='region-state-modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'> Menu Preview</div>
                <div className='vendor-details'>
                    <div>
                        <span>Company Name: </span>
                        <span>{selectedData?.company_name}</span>
                    </div>
                    <div>
                        <span className='details'>Parent Menu: </span>
                        <span>{menuDataList !== 'loading' && menuDataList?.map(menuData => {
                            return (selectedData?.parent_id == menuData.id) && menuData?.name;
                        })}</span>
                    </div>
                    <div>
                        <span>Menu Name: </span>
                        <span>{selectedData?.name}</span>
                    </div>
                    <div>
                        <span >Url: </span>
                        <span>{selectedData?.url}</span>
                    </div>
                    <div>
                        <span>Tooltip: </span>
                        <span>{selectedData?.tooltip}</span>
                    </div>
                    <div>
                        <span>Ordering: </span>
                        <span>{selectedData?.ordering}</span>
                    </div>
                    <div>
                        <span>Component: </span>
                        <span>{selectedData?.component}</span>
                    </div>
                    <div>
                        <span className='details'>Exact Url: </span>
                        <span>{selectedData?.exact ==='1' ? "Yes" : "No"}</span>
                    </div>
                    <div>
                        <span className='details'>Show in Mobile Navbar: </span>
                        <span>{selectedData?.show_in_mobile_navbar ==='1' ? "Yes" : "No"}</span>
                    </div>
                    <div>
                        <span className='details'>Show in Web Navbar: </span>
                        <span>{selectedData?.show_in_web_navbar ==='1' ? "Yes" : "No"}</span>
                    </div>
                    <div>
                        <span className='details'>Show in Footer Menu: </span>
                        <span>{selectedData?.footer_menu ==='1' ? "Yes" : "No"}</span>
                    </div>
                    <div>
                        <span className='details'>Show in Footer Menu: </span>
                        <span>{selectedData?.second_footer_menu ==='1' ? "Yes" : "No"}</span>
                    </div>
                    <div>
                        <span className='details'>Display Only Logged in: </span>
                        <span>{selectedData?.display_only_logged_in ==='1' ? "Yes" : "No"}</span>
                    </div>
                    <div>
                        <span className='details'>Show in Left Side: </span>
                        <span>{selectedData?.left_menu ==='1' ? "Yes" : "No"}</span>
                    </div>
                    <div>
                        <span className='details'>Show in Right Side: </span>
                        <span>{selectedData?.right_menu ==='1' ? "Yes" : "No"}</span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};


export default MenuPreview;
