import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import '../../../assets/modal.css'
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {
    actionToGetBrandsApiCall,
    actionToGetCategoriesApiCall,  actionToGetProductTableListApiCall,
    actionToGetSubCategoriesApiCall
} from "../../../store/action/inventoryActions";
import useAuth from "../../../hooks/useAuth";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import SelectBrandDropdown from "../brand/SelectBrandDropdown";
import SelectSubCategoryDropdown from "../sub-category/SelectSubCategoryDropdown";
import CategoryDropdown from "../category/CategoryDropdown";
import SelectCompanyDropdown from "../../auth/SelectCompanyDropdown";
import {toast} from "react-toastify";


const AddProduct = ({ isOpen, onRequestClose }) => {
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const productTableData = useSelector((state) =>  state.inventory?.productTableData);
    const companyList = useSelector((state) =>  state.users?.companyList);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const {auth} = useAuth();
    const [formData, setFormData] = useState({source:auth?.source});
    const [companyData, setCompanyData] = useState([]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        if(name === 'source'){
            companyList.map(company=>{
                if(company.id==value){
                    setCompanyData(company);
                    return true;
                }
            })
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            obj['created_by'] = auth?.id;
            let param = { slug: obj['slug'] };
            delete obj['slug'];
            delete obj['source'];
            delete obj['category_id'];

            try {
                const res = await dispatch(callInsertDataWithLogFunction({
                    tableName: 'products',
                    data: obj,
                    userId: auth?.id,
                    eventTypeId
                }));

                param['product_id'] = res?.id;
                const status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                if (status === 'success') {
                    toast.success('Product added successfully!');
                    resetForm();
                    onRequestClose();
                } else {
                    toast.error('Failed to add the product. Please try again.');
                }

                // Handle product details insertion if applicable
                if (companyData.in_website_use === '1') {
                    await dispatch(callInsertDataWithLogFunction({
                        tableName: 'product_details',
                        data: param,
                        userId: auth?.id,
                        eventTypeId
                    }));
                }

                // Refresh the product table data
                productTableData?.payload && dispatch(actionToGetProductTableListApiCall(productTableData?.payload));

            } catch (error) {
                console.error('Error during product insertion:', error);
                setSaveStatus('fail');
                toast.error('An error occurred while adding the product.');
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.');
        }
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.name.trim()) {
            errors.name = 'Product Name is required';
        }
         if (!data.sub_category_id) {
             errors.sub_category_id = 'Sub-Category is required';
         }
         if (!data.in_stock_quantity) {
             errors.in_stock_quantity = 'Quantity is required';
         }
         if (!data.purchase_price) {
             errors.purchase_price = 'Purchase price is required';
         }
         if (!data.sale_price) {
             errors.sale_price = 'Sale price is required';
         }

         if (!data.gst) {
             errors.gst = 'GST is required';
         }

        return errors;
    };
    const resetForm = () => {
        document.getElementById('productForm')?.reset();
        setErrors({});
        setSaveStatus({});
        setFormData({source: auth?.source});
        companyList.map(company=>{
            if(company.id==auth?.source){
                setCompanyData(company);
                return true;
            }
        })
    };
    useEffect(() => {
        if(isOpen){
            resetForm();
        }
    }, [isOpen]);
    useEffectOnce(()=>{
        dispatch(actionToGetCategoriesApiCall());
        dispatch(actionToGetSubCategoriesApiCall());
        dispatch(actionToGetBrandsApiCall());
    })
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Product</div>
                <form action='#' onSubmit={handleSubmit} id='productForm'>
                    <div className='vendor-details'>
                        <SelectCompanyDropdown allowedRoles={[ROLES.Admin,ROLES.SuperAdmin,ROLES.WebsiteEditor]} source={formData?.source} handleChange={handleChange}/>
                        <div className='input-box'>
                            <span className='details'>Product Name</span>
                            <input type='text'
                                   placeholder='Enter Name of the Product'
                                   name='name'
                                   onChange={handleChange}
                                   />
                            {errors.name &&  <span className="error-message">{errors.name} </span>}
                        </div>

                        {companyData.in_website_use === '1' ? <div className='input-box'>
                            <span className='details'>Product Slug</span>
                            <input type='text' name={'slug'} readOnly={true}
                                   placeholder='Enter Slug of the Product' value={formData?.name?.toLowerCase()?.split(' ').join('-')}/>
                            {errors.slug &&  <span className="error-message">{errors.slug} </span>}
                        </div> :''}
                        <CategoryDropdown errors={errors} source={formData?.source} name={'category_id'} category={formData.category_id} handleChange={handleChange} />
                        {formData.category_id &&<SelectSubCategoryDropdown errors={errors} name={'sub_category_id'} category_id={formData.category_id} sub_category_id={formData.sub_category_id} handleChange={handleChange}/>}
                        <SelectBrandDropdown errors={errors} name={'brand_id'} brand_id={formData.brand_id} handleChange={handleChange}/>
                        <div className='input-box'>
                            <span className='details'>Product Type</span>
                            <select className='select' name='type' placeholder='Product Type'>
                                 <option value="component">Component</option>
                                <option value="product" selected={true}>Product</option>
                                <option value="product_component">Product and Component Both</option>
                                <option value="packing_material">Packing Material</option>
                                <option value="miscellaneous">Miscellaneous</option>
                            </select>
                            {errors.type && <span className="error-message"> {errors.type}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Product Description</span>
                            <textarea className='text-area'  placeholder='Enter Short Description of the Product' name='description' />
                            {errors.description && <span className="error-message">{errors.description} </span> }
                        </div>

                        <div className='input-box'>
                            <span className='details'>Location in Inventory</span>
                            <input type='text' placeholder='Enter Location' name='location'/>
                            {errors.location && <span className="error-message">{errors.location}</span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>GST (percentage)</span>
                            <input type='number'
                                   placeholder='Enter GST' name='gst' />
                            {errors.gst && <span className="error-message"> {errors.gst} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Enter In stock Quantity</span>
                            <input type='number'
                                   placeholder='Enter In stock Quantity'
                                    name='in_stock_quantity' />
                            {errors.in_stock_quantity && <span className="error-message"> {errors.in_stock_quantity} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>HSN Code</span>
                            <input type='number'
                                   placeholder='Enter In hsn code'
                                    name='hsn' />
                            {errors.hsn && <span className="error-message"> {errors.hsn} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Enter Purchase Price of the unit Product</span>
                            <input type='number' placeholder='Enter Purchase Price of the unit Product'
                                    name='purchase_price' step=".01"/>
                            {errors.purchase_price &&  <span className="error-message"> {errors.purchase_price}</span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Enter Sale Price of the unit Product</span>
                            <input type='number' placeholder='Enter Sale Price of the unit Product'
                                    name='sale_price'/>
                            {errors.sale_price &&  <span className="error-message"> {errors.sale_price}</span>}
                        </div>

                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Product Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};

export default AddProduct;
