import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faUserPlus

} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {Link, useParams} from "react-router-dom";
import UsersTable from "./users/UsersTable";
import AddUsers from "./users/AddUsers";
import {subDomainUrl} from "../../hooks/config";


const UsersComponent = React.memo(function () {
    let {tabName} = useParams();
    const [addModalName, setAddModalName] = useState(false);
    const [activeTab, setActiveTab] = useState('inventory');

    const getTabTable =() =>{
        switch (activeTab){
            case 'users':
                return <UsersTable />;
            default:
                return <UsersTable />;
        }
    }
    useEffect(() => {
        setActiveTab(tabName);
    }, [tabName]);
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                                <Link to={subDomainUrl+'/inventory/inventory'} className='tab'><button
                                    className={`tab ${activeTab === 'inventory' ? 'active' : ''}`}>
                                    Users
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add Users'>
                                <button  className='common-button' onClick={() => setAddModalName('add_users')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faUserPlus} />
                                </button>
                            </Tippy>
                            <AddUsers isOpen={addModalName === 'add_users'} onRequestClose={() => setAddModalName(false)} />
                        </div>

                    </div>
                </div>
                <div className="tab-content">
                    {getTabTable()}
                </div>
            </section>
        </>
    )
});

export default UsersComponent;
