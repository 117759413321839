import {api} from '../../hooks/api/ApiConfig.js';
import {
    STATE_LIST, CITY_LIST, ZIP_CODE_LIST, FORM_AUTH_RULE
} from "../constant";

export const actionToGetStateListApiCall = () => async (dispatch) => {
    const {data} = await api.post(`common/get-state-list`);
    dispatch({ type: STATE_LIST, payload: data });
    return data;
}
export const actionToGetCityListApiCall = () => async (dispatch) => {
    const {data} = await api.post(`common/get-city-list`);
    dispatch({ type: CITY_LIST, payload: data });
    return data;
}
export const actionToGetPinCodeListApiCall = () => async (dispatch) => {
    const {data} = await api.post(`common/get-pin-code-list`);
    dispatch({ type: ZIP_CODE_LIST, payload: data });
    return data;
}
export const actionToGetFormAuthRuleApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`common/get-form-auth-rule`,payload);
    dispatch({ type: FORM_AUTH_RULE, payload: data });
    return data;
}