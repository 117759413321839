// components/SchoolForm.js
import React, {useEffect} from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import DateField from "../../Layout/Form/DateField";
import SelectContactPersonDropdown from "../contact-person/SelectContactPersonDropdown";
import SelectFollowUpTypeDropdown from "../client-lead-interaction/SelectFollowUpTypeDropdown";
import SelectSchoolDropdown from "../school/SelectSchoolDropdown";
import {CLIENT_LEAD_LIST, CONTACT_PERSON_DATA} from "../../../store/constant/salesConstant";
import {actionToGetClientLeadApiCall, actionToGetContactPersonApiCall} from "../../../store/action/salesAction";
import SelectSchoolClientLeadDropdown from "../client-lead/SelectSchoolClientLeadDropdown";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import TextArea from "../../Layout/Form/TextArea";

const TodaySalesForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const validationRules = {
        notes: { required: true, minLength: 3 },
        current_follow_up_type_id: { required: true,field_name:'Current Followup Type' },
        school_id: { required: true,field_name:'School' },
        school_client_lead_id: { required: true,field_name:'Business regarding' },
        school_contact_person_id: { required: true,field_name:'Contact Person' },
    };
    const onSuccess = (res)=>{
        handleExternalFetch?.(res,['lead-interaction']);
        onRequestClose();
    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );
    useEffect(()=>{
        if(isOpen){
            dispatch({ type: CONTACT_PERSON_DATA, payload: [] });
            dispatch({ type: CLIENT_LEAD_LIST, payload: [] });
        }
    },[isOpen])
    useEffect(() => {
        if(formData.school_id){
            dispatch(actionToGetContactPersonApiCall({school_id:formData.school_id}));
            dispatch(actionToGetClientLeadApiCall({school_id:formData.school_id}));
        }
    }, [formData.school_id]);

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={formData?.school_id ? 'Modal' :'sales-report-modal'}>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? `Edit My Work Report` : `Add My Work Report`}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'school_client_lead_interaction',{created_by:auth?.id},['school_id'])}>
                    <div className='vendor-details'>
                        <SelectSchoolDropdown placeholder='Select School' name={'school_id'} school_id={formData?.school_id} handleChange={handleChange} error={errors?.school_id} />
                        {formData?.school_id && <>
                            <SelectContactPersonDropdown placeholder='Select/Add Contacted Person' school_contact_person_id={formData.school_contact_person_id} name={'school_contact_person_id'} school_id={formData?.school_id} handleChange={handleChange} />
                            <SelectSchoolClientLeadDropdown smallText={'for multiple entry use comma'} placeholder='Select/Add Business Regarding' school_client_lead_id={formData.school_client_lead_id} name={'school_client_lead_id'} school_id={formData?.school_id} handleChange={handleChange}  error={errors?.school_client_lead_id} />

                            <TextArea
                                className='text-area'
                                name="notes"
                                placeholder="Enter Conversation"
                                value={formData.notes || ''}
                                error={errors.notes}
                                onChange={handleChange} label={'Detailed Conversation'}
                            />
                            <SelectFollowUpTypeDropdown placeholder='Select Current Follow Up Type' label={'Select Current Follow Type'} errors={errors} name="current_follow_up_type_id" follow_up_type_id={formData.current_follow_up_type_id} handleChange={handleChange}  />
                            <SelectFollowUpTypeDropdown placeholder='Select Next Follow Up Type' label={'Select Next Follow Type'} errors={errors} name="next_follow_up_type_id" follow_up_type_id={formData.next_follow_up_type_id} handleChange={handleChange}  />
                            <DateField label="Interaction Date"
                                       name={'interaction_date'}
                                       value={formData.interaction_date || ''}
                                       onChange={handleChange}
                                       error={errors.interaction_date} placeholder='Interaction Date' />
                            <DateField label="Next Follow Up date"
                                       name={'next_follow_up_date'}
                                       minDate={new Date()}
                                       value={formData.next_follow_up_date || ''}
                                       onChange={handleChange}
                                       error={errors.next_follow_up_date} placeholder='Next Follow up Date' />
                        </>}

                    </div>
                    <div className='buttons'>
                        {formData?.school_id && <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>}
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default TodaySalesForm;
