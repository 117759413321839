// components/SchoolTable.js
import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from '../../../hooks/useFetchData';
import {actionToGetContactPersonApiCall} from '../../../store/action/salesAction';
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from '../../../store/action/commonActions';
import SchoolForm from './SchoolForm';
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import {truncateHTMLText} from "../../../store/utility/DatatableUtility";
import useAuth from "../../../hooks/useAuth";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faBriefcaseClock,
    faFileCirclePlus,
    faPenToSquare,
    faUser,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import SchoolContactPersonForm from "../contact-person/SchoolContactPersonForm";
import SchoolContactPersonTableModal from "../contact-person/SchoolContactPersonTableModal";
import SchoolClientLeadForm from "../client-lead/SchoolClientLeadForm";
import SchoolClientLeadTableModal from "../client-lead/SchoolClientLeadTableModal";
import {uploadFileOnAws} from "../../../store/action/inventoryActions";
import GemSchoolForm from "./GemSchoolForm";
const SchoolTable = ({setFetchDataTable,fetchDataTable}) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const ROLES = useSelector((state) => state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);
    const hiddenFileInput = useRef(null);
    const { data:schoolData, fetchData,settings } = useFetchData(`sales/get-schools`, [], { source: auth?.source });
    const [isSchoolModalOpen, setIsSchoolModalOpen] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);

    const columns = [
        { name: 'Sr No', selector: row => row.id, selector_field:'id',
            cell: (row,index) => (
                <>
                    {index+1}
                </>
            ),width:'60px'  },
        { name: 'Name', selector: row => row.name, selector_field:'name',
            cell: row => (
                <>
                    {row.name}
                </>
            ), sortable: true ,width:'150px' },
        { name: 'email', selector: row => row.email, selector_field:'email',sortable: true,width:'250px'  },
        { name: 'phone', selector: row => row.phone, selector_field:'phone',sortable: true,width:'120px'  },
        { name: 'creator', selector: row => row.creator, selector_field:'creator',sortable: true,width:'200px' },
        { name: 'Address', selector: row =>  <div className="truncate-text" title={row.address}>
                {truncateHTMLText(row.address, 50)} {/* Adjust maxLength as needed */}
            </div>,
            selector_field:'address',sortable: true ,width:'200px'},
        { name: 'region name', selector: row => row.region_name, selector_field:'region_name',sortable: true,width:'100px' },
        { name: 'state name', selector: row => row.state_name, selector_field:'state_name',sortable: true,width:'100px' },
        { name: 'city name', selector: row => row.city_name, selector_field:'city_name',sortable: true,width:'100px' },
        { name: 'School Type', selector: row => row.school_type_name, selector_field:'school_type_name',sortable: true,width:'100px'},
        { name: 'Image', selector: row => row.photo, selector_field:'sale_price',sortable: false, width:'150px',cell: row => (
                <>
                    <div className={'test'} onClick={()=>{allowedRoles?.includes(auth?.role) && handlePhotoClick(row)}}>
                        <img src={row?.photo} height="35" width="35" alt={row?.name}/></div>
                </>
            ) },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    <TableEditActions
                        tooltip={'Edit School'}
                        row={row}
                        onEdit={() => { setSelectedSchool(row); setIsSchoolModalOpen('edit'); }}
                    />
                    <TableDeleteActions
                        auth_key="school_delete"
                        tooltip="Delete School"
                        row={row}
                        onDelete={(row) => dispatch(callSoftDeleteDataWithLogFunction({ tableName: 'schools', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                    <Tippy content="Add Contact Person">
                        <button className='action-button' onClick={() => { setSelectedSchool(row); setIsSchoolModalOpen('add_contact_person'); }}>
                            <FontAwesomeIcon className='action-icon' icon={faUserPlus} />
                        </button>
                    </Tippy>
                    <Tippy content="Contact Person Table">
                        <button className='action-button' onClick={() => { setSelectedSchool(row); setIsSchoolModalOpen('contact_person_table'); }}>
                            <FontAwesomeIcon className='action-icon' icon={faUser} />
                        </button>
                    </Tippy>
                    <Tippy content="Add Business Regarding">
                        <button className='action-button' onClick={() => { setSelectedSchool(row); setIsSchoolModalOpen('add_client_lead'); }}>
                            <FontAwesomeIcon className='action-icon' icon={faBriefcaseClock} />
                        </button>
                    </Tippy>
                    <Tippy content="Business Regarding Table">
                        <button className='action-button' onClick={() => { setSelectedSchool(row); setIsSchoolModalOpen('client_lead_table');   dispatch(actionToGetContactPersonApiCall({school_id:row.id}));}}>
                            <FontAwesomeIcon className='action-icon' icon={faBriefcase} />
                        </button>
                    </Tippy>
                </div>
            ), width: '150px' }
    ];

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);

    const handlePhotoClick = (row) => {
        setSelectedSchool(row);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        let name = selectedSchool?.name.replace(/\s+/g, '-').toLowerCase();
        let filename = `schools/${name}-${selectedSchool.id}.png`;
        let deletedFileName =  selectedSchool.photo?.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/','');
        let url = await dispatch(uploadFileOnAws({filename:filename,deletedFilename:deletedFileName,photo:fileUploaded,type:'image/png'}));
        dispatch(callUpdateDataWithLogFunction({tableName:'schools',data: {photo:url+'?v='+Math.floor(1000 + Math.random() * 9000)},id:selectedSchool?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            fetchData()
        })
    }

    return (
        <div className='table-container'>
            <DataTableLayout columns={columns} data={schoolData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={schoolData?.total} />


            {isSchoolModalOpen === 'edit' && (
                <SchoolForm
                    handleExternalFetch={fetchData}
                    isOpen={isSchoolModalOpen=== 'edit'}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                    selectedData={selectedSchool}
                    isEditMode={true}
                />
            )}  {isSchoolModalOpen === 'add_contact_person' && (
                <SchoolContactPersonForm
                    isOpen={isSchoolModalOpen=== 'add_contact_person'}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                    selectedData={{school_id:selectedSchool?.id,school_name:selectedSchool?.name}}
                    isEditMode={false}
                />
            )} {isSchoolModalOpen === 'contact_person_table' && (
                <SchoolContactPersonTableModal
                    isOpen={isSchoolModalOpen=== 'contact_person_table'}
                    selectedSchool={selectedSchool}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                />
            )} {isSchoolModalOpen === 'add_client_lead' && (
                <SchoolClientLeadForm
                    isOpen={isSchoolModalOpen=== 'add_client_lead'}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                    selectedData={{school_id:selectedSchool?.id,school_name:selectedSchool?.name}}
                    isEditMode={false}
                />
            )} {isSchoolModalOpen === 'client_lead_table' && (
                <SchoolClientLeadTableModal
                    isOpen={isSchoolModalOpen=== 'client_lead_table'}
                    selectedSchool={selectedSchool}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                />
            )}
        </div>
    );
};

export default SchoolTable;
