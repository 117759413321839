import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import SelectFolderDropdown from "./SelectFolderDropdown";
import {actionToGetGalleryListApiCall} from "../../../store/action/galleryActions";
import {uploadFileOnAws} from "../../../store/action/inventoryActions";
import {toast} from "react-toastify";

const AddFolderDocument = ({ isOpen,selectedData, onRequestClose }) => {
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const eventTypeId = useSelector((state) =>  state.gallery.galleryEventTypeId);
    const [uploadedFile,setUploadedFile] = useState([]);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const [formData, setFormData] = useState({ });
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleImageUpload = (event) => {
        const files = event.target.files;
        setUploadedFile(Array.from(files));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDetail = new FormData(e.target);
        const obj = Object.fromEntries(formDetail.entries());

        // Validate form data
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            obj['created_by'] = auth?.id;

            try {
                let status;

                if (obj['type'] === 'youtube' || obj['type'] === 'file_url') {
                    const res = await dispatch(callInsertDataWithLogFunction({
                        tableName: 'gallery_folder',
                        data: obj,
                        userId: auth?.id,
                        eventTypeId
                    }));

                    status = res?.success === 1 ? 'success' : 'fail';
                } else {
                    // Process each uploaded file
                    for (const photo of uploadedFile) {
                        const fileExtension = photo.name.split('.').pop();
                        const filename = `gallery/${auth.name.trim()}/content-${Date.now()}.${fileExtension}`;

                        // Upload the file to AWS
                        obj['url'] = await dispatch(uploadFileOnAws({
                            filename,
                            photo,
                            showOnWebsite: 'stemcity',
                            type: photo.type
                        }));

                        const res = await dispatch(callInsertDataWithLogFunction({
                            tableName: 'gallery_folder',
                            data: obj,
                            userId: auth?.id,
                            eventTypeId
                        }));

                        status = res?.success === 1 ? 'success' : 'fail';
                    }
                }

                // Get gallery list and handle status
                dispatch(actionToGetGalleryListApiCall(!allowedRoles?.includes(auth?.role) ? { user_id: auth?.id } : {}));
                setSaveStatus(status);

                if (status === 'success') {
                    toast.success('Gallery item added successfully!');
                    resetForm();
                } else {
                    toast.error('Failed to add gallery item.');
                }
            } catch (error) {
                console.error('Error during submission:', error);
                setSaveStatus('fail');
                toast.error('An error occurred while submitting the form.');
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.');
        }
    };

    const resetForm = () => {
        document.getElementById('grade-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Document Name is required';
        }
        if (!data.type.trim()) {
            errors.type = 'Type is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Document</div>
                <form onSubmit={handleSubmit} id='grade-form'>
                    <div className='vendor-details'>
                        <input type={'hidden'} value={selectedData.id} name={'parent_id'}/>
                        <div className='input-box'>
                            <span className='details'>Add Document Name</span>
                            <input type='text' placeholder='Document Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        <div className='input-box'>
                            <select className='select' name='type' placeholder='File Type' onChange={handleChange}>
                                <option value={''}>Please select File Type</option>
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                                <option value="youtube">Youtube</option>
                                <option value="file_url">File Url</option>
                                <option value="file">file</option>
                            </select>
                            {errors.type && <span className="error-message"> {errors.type}</span> }
                        </div>
                        {formData.type === 'youtube' || formData.type === 'file_url' ?
                            <div className={'input-box'}>
                                <input type={'text'} name={'url'} placeholder={'please paste youtube link'}/></div>:
                            <input
                                type="file"
                                multiple
                                accept={formData.type ==="image" ? "image/*" : formData.type === 'video' ? "video/*" :
                                    formData.type === 'file' ? "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf": "*"}
                                name={'url'}
                                className="upload-input"
                                onChange={handleImageUpload}
                            />}
                        <div className='input-box'>
                            <span className='details'>Description</span>
                            <textarea className='text-area' name={"description"} placeholder={'description'} />
                            {errors.description &&  <span className="error-message"> {errors.description} </span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Folder Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddFolderDocument;
