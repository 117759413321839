import React from 'react';
import InventoryHeader from "../InventoryHeader.js";
import DefectiveForm from "./DefectiveForm.js";
import DefectiveTable from "./DefectiveTable.js";

const DefectiveProducts = () => {

    return (

        <section className='admin-tab-section'>
            <InventoryHeader/>
            <div className={"row"}>
                <div className={"col-md-5"}>
                    <DefectiveForm />
                </div>
                <div className={"col-md-7"}>
                    <DefectiveTable />
                </div>

            </div>


        </section>
    )
}

export default DefectiveProducts
