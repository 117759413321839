import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {
    actionToGetMenuDetailApiCall,
    actionToGetWebSettingSectionApiCall
} from "../../../../store/action/webSettingActions";
import useAuth from "../../../../hooks/useAuth";
import {
    callDeleteDataWithLogFunction,
    callInsertDataWithLogFunction,
    callUpdateDataWithLogFunction
} from "../../../../store/action/commonActions";
import SelectMenuDropdown from "../menu/SelectMenuDropdown";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

const AddMenuSection = (props) => {
    const dispatch = useDispatch();
    const {menu_id} = useParams();
    const {auth} = useAuth();
    const [errors, setErrors] = useState({});
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const selectedData = props.selectedData;
    const [formData, setFormData] = useState({  });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const updateOrdering = async () => {
        if (formData.ordering) {
            dispatch(callUpdateDataWithLogFunction({tableName:'menu_website_section',data:{ordering:formData.ordering},id:formData?.id,userId:auth?.id,eventTypeId})).then(res => {
                dispatch(actionToGetWebSettingSectionApiCall())
            })
        } else {
            let error = {}
            error.ordering = 'Ordering is required';
            setErrors(error);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = await validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // Dispatch the action to insert data
            dispatch(callInsertDataWithLogFunction({
                tableName: 'menu_website_section',
                data: {
                    website_section_id: selectedData.id,
                    menu_id: obj['menu_id'],
                    ordering: obj['ordering']
                },
                userId: auth?.id,
                eventTypeId: eventTypeId
            })).then(res => {
                if (res?.success === 1) {
                    toast.success('Menu section added successfully!'); // Success notification
                    dispatch(actionToGetMenuDetailApiCall({ id: menu_id }));
                    resetForm();
                } else {
                    toast.error('Failed to add menu section.'); // Failure notification
                }
            }).catch(() => {
                toast.error('An error occurred while adding the menu section.'); // Catching any other errors
            });
        } else {
            toast.error('Please correct the errors in the form.'); // Validation error notification
        }
    };
    const resetForm = () => {
        document.getElementById('menu-section')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.menu_id.trim()) {
            errors.menu_id = 'Menu is required';
        }
        if (!data.ordering.trim()) {
            errors.ordering = 'Ordering is required';
        }

        return errors;
    };
    const editWebsiteSectionMenuOrdering = (menu) =>{
        setFormData({id:menu.id, ordering:menu.ordering});
    }
    const deleteWebsiteSectionMenu = (id) =>{
        swal({
            title: "Are you sure?",
            text: "You want to delete this section menu ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'menu_website_section',id:id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetWebSettingSectionApiCall())
                    })
                }
            });

    }
    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='region-state-modal'>
                    <div className='container'>
                        <div className='title'>Website Section</div>
                        {formData?.id ?
                            <form id={'update-ordering'}  >
                                  <div className='input-box'>
                                    <span className='details'>Position Of Section</span>
                                    <input type='number' name='ordering' className='form-input' value={formData?.ordering}  onChange={handleChange}/>
                                    {errors.ordering &&  <span className="error-message"> {errors.ordering} </span> }
                                </div>
                                <button className='add-button' type={"button"} onClick={()=>updateOrdering()}>Update Ordering</button>
                                <button className='add-button' type={"button"} onClick={()=>{setFormData({}); setErrors({});}}>Clear</button>
                            </form>
                            :
                        <form id={'menu-section'} onSubmit={handleSubmit} className='mt-4' >
                            <div className='vendor-details'>
                                <SelectMenuDropdown name={'menu_id'} menu_id={formData?.menu_id} errors={errors} handleChange={handleChange} source={selectedData?.source}/>
                                    <div className='input-box'>
                                        <span className='details'>Position Of Section:</span>
                                        <input type='number' name='ordering' className='text-area' value={formData?.ordering}  onChange={handleChange}/>
                                        {errors.ordering &&  <span className="error-message"> {errors.ordering} </span> }
                                     </div>
                                <button className='add-button' type={"submit"}>Add Menu</button>
                            </div>
                        </form>}
                        <table className="image-table">
                            <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>Menu Name</th>
                                <th>Ordering</th>
                                <th>Edit Ordering</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedData?.menu_data?.map((menu,index) => (
                                <tr key={menu.id}>
                                    <td>{index+1}</td>
                                    <td>{menu.menu_name}</td>
                                    <td>{menu.ordering}</td>
                                    <td><FontAwesomeIcon className='action-icon' icon={faPen} onClick={()=> editWebsiteSectionMenuOrdering(menu)} /></td>
                                    <td>{selectedData?.menu_data?.length !== 1 &&<FontAwesomeIcon className='action-icon' icon={faTrash} onClick={()=> deleteWebsiteSectionMenu(menu.id)} />}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
            </Modal>
        </div>
    );
};

export default AddMenuSection;
