import React from 'react';
import InputField from '../../Layout/Form/InputField';
import DateField from "../../Layout/Form/DateField";
import TextArea from "../../Layout/Form/TextArea";
import { useSelector } from "react-redux";

const OrderDeliveryForm = ({ formData, handleChange, handleFileChange, errors }) => {
    const packingData = useSelector(state => state.inventory?.orderProcessingDetailListData?.[0]?.packingData);

    // Collect all tracking entries across packing items
    const trackingArray = packingData
        ?.flatMap(item => item.tracking || [])  // Flatten all tracking arrays into one array
        .filter(tracking => !tracking.delivered_date);          // Remove any null values if present

    return (
        <div className="container">
            <div className="title">Delivery Details</div>
            <div className="vendor-details">

                {/* Render tracking array as radio buttons */}
                {trackingArray && trackingArray.length > 0 && (
                    <div className="tracking-options">
                        <label>Select Tracking Number:</label>
                        {trackingArray.map((trackingItem) => (
                            <div key={trackingItem.tracking_id} className="radio-option">
                                <input
                                    type="radio"
                                    id={`tracking-item-${trackingItem.tracking_id}`}
                                    name="selected_tracking"
                                    value={trackingItem.tracking_number}
                                    onChange={handleChange}
                                />
                                <label htmlFor={`tracking-item-${trackingItem.tracking_id}`}>
                                    {trackingItem.tracking_number}
                                </label>
                            </div>
                        ))}
                    </div>
                )}

                {/* Delivered Date Field */}
                <DateField
                    label="Delivered Date"
                    name="delivered_date"
                    value={formData.delivered_date || ''}
                    onChange={handleChange}
                    error={errors.delivered_date}
                    placeholder="Delivered Date"
                />

                {/* Receiving Receipt File Input */}
                <InputField
                    label="Receiving Receipt File"
                    type="file"
                    name="receiving_receipt_file"
                    onChange={handleFileChange}  // New handler specifically for files
                    error={errors.receiving_receipt_file}
                />

                {/* Description TextArea */}
                <TextArea
                    className="text-area"
                    name="tracking_description"
                    value={formData.tracking_description || ''}
                    onChange={handleChange}
                    placeholder="Description"
                    error={errors.tracking_description}
                    label="Add Description"
                />
            </div>
        </div>
    );
};

export default OrderDeliveryForm;
