import React, { useRef, useState, useEffect } from 'react';
import SweetAlert from 'sweetalert2';
import useOutsideClick from "../../hooks/useOutsideClick";

const Badge = ({ label, color, options, onUpdate, isOpen, onDropdownToggle, uniqueKey, id, tableContainerRef }) => {
    const dropdownRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState('below');

    useOutsideClick(dropdownRef, () => onDropdownToggle(null));

    useEffect(() => {
        if (isOpen && dropdownRef.current ) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const tableRect = tableContainerRef && tableContainerRef.current.getBoundingClientRect();
            const dropdownHeight = options.length * 36; // Approximate height per item
            const spaceBelow = (tableRect?.bottom ?? window.innerHeight) - dropdownRect.bottom;

            setDropdownPosition(spaceBelow < dropdownHeight ? 'above' : 'below');
        }
    }, [isOpen, options.length, tableContainerRef]);

    const handleSelect = (option) => {
        if (option.label !== label) {
            SweetAlert.fire({
                title: 'Confirm Selection',
                text: `Are you sure you want to change to "${option.label}"?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    onUpdate(option.value, uniqueKey, id);
                }
            });
        } else {
            onDropdownToggle(uniqueKey);
        }
    };

    return (
        <div className="badge-container" key={uniqueKey} style={{ position: 'relative' }}>
            <span
                className={`badge ${color}`}
                onClick={() => onDropdownToggle(uniqueKey)}
                style={{ cursor: 'pointer', padding: '5px', border: '1px solid gray', borderRadius: '4px' }}
            >
                {label}
            </span>

            {isOpen && (
                <div
                    ref={dropdownRef}
                    className="badge-dropdown"
                    style={{
                        position: 'absolute',
                        top: dropdownPosition === 'below' ? '100%' : 'auto',
                        bottom: dropdownPosition === 'above' ? '100%' : 'auto',
                        left: 0,
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        zIndex: 1000
                    }}
                >
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="dropdown-item"
                            onClick={() => handleSelect(option)}
                            style={{ cursor: 'pointer', padding: '5px 10px', borderBottom: '1px solid #ddd' }}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Badge;
