import {USER_ROLE_LIST, USERS_TABLE_LIST} from "../constant/usersConstant";
import {COMPANY_LIST} from "../constant";

const initialState = {
    usersListData:[],
    usersRoleList:[],
    userEventTypeId:1,
    userCompanyId:3,
    companyList:[]
}
export  const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case USERS_TABLE_LIST:
            return { ...state, usersListData: action.payload };
        case USER_ROLE_LIST:
            return { ...state, usersRoleList: action.payload };
        case COMPANY_LIST:
            return { ...state, companyList: action.payload };
        default:
            return state
    }
}