import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";

const SelectSchoolTypeDropdown = (props) =>{
    const schoolTypeList = useSelector((state) =>  state.sales.schoolTypeList);
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState(null);

    useEffect(() => {
        let componentArray = [];
        let selectedData = null;
        schoolTypeList !== 'loading'  && Object.values(schoolTypeList)?.map(val=>{
            componentArray.push({value:val.id,label:val.name});
            if(props?.school_type_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [schoolTypeList,props?.school_type_id]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: '50px',
            width: '95%',
            borderRadius: '5px',
            border: state.isFocused ? '1px solid #efdda8' : '1px solid #ccc',
            paddingLeft: '5px',
            fontSize: '13px',
            boxShadow: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
                borderColor: '#efdda8'
            },

        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            width: '95%',
            marginTop: '2px',
            zIndex: 2,
            overflowY: 'auto',
            maxHeight: '150px',

        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#ccc',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#999'
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
            backgroundColor: state.isFocused ? '#efdda8' : '#fff',
            color: '#000',
            '&:hover': {
                backgroundColor: '#efdda8',
                color: '#000'
            }
        })
    };
    return(
        <div className='input-box'>
            <span className='details'>Type of School</span>
            <Select name={props.name}
                    value={selectedDropDown}
                    onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                    options={componentDropdown} placeholder='Select School Type' styles={customStyles}
            />
            {props.errors && props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
        </div>
    )
}
export default SelectSchoolTypeDropdown;
