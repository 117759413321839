import React, {useMemo, useState, useEffect, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBox,
    faFileArrowDown, faFileCirclePlus
} from "@fortawesome/free-solid-svg-icons";
import {useFetchData} from "../../../hooks/useFetchData";
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import useAuth from "../../../hooks/useAuth";
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import LeadToOrderFormModal from "../../Sales/lead-to-order/LeadToOrderFormModal";
import {indianDateFormat, truncateHTMLText} from "../../../store/utility/DatatableUtility";
import Badge from "../../Layout/Badge";
import GemSchoolForm from "../../Sales/school/GemSchoolForm";

const GemOrderTable = ({ setFetchDataTable, fetchDataTable, selectedClientLead }) => {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const { data: offlineOrder, fetchData, settings } = useFetchData('sales/get-gem-order', [],{ client_lead_id: selectedClientLead?.id,gem_order:'1' });

    const [isEditLeadOrderOpen, setIsEditLeadOrderOpen] = useState(null);
    const [selectedLeadOrder, setSelectedLeadOrder] = useState(null);

    const [openBadge, setOpenBadge] = useState(null);
    const tableContainerRef = useRef(null);


    // Dropdown options memoized
    const dropdownOptions = useMemo(() => ({
        participation: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
            { value: 'L1', label: 'L1' },
        ]
    }), []);

    const handleDropdownToggle = useCallback((uniqueKey) => {
        setOpenBadge(prevKey => prevKey === uniqueKey ? null : uniqueKey);
    }, []);

    const handleBadgeUpdate = useCallback((newStatus, key, id) => {
        const columnName = key.split('-')[0];
        const payload = { [columnName]: newStatus };
        dispatch(callUpdateDataWithLogFunction({
            tableName: 'gem_bid',
            data: payload,
            id,
            userId: auth?.id,
            eventTypeId
        })).then(() => {
            setOpenBadge(null);
            fetchData();
        });
    }, [auth?.id, dispatch, eventTypeId, fetchData]);

    const getBadgeHtml = (key, label, color, options, id) => (
        <Badge
            key={key}
            label={label}
            color={color}
            options={options}
            isOpen={openBadge === key}
            onDropdownToggle={() => handleDropdownToggle(key)}
            uniqueKey={key}
            id={id}
            onUpdate={(newStatus) => handleBadgeUpdate(newStatus, key, id)}
            tableContainerRef={tableContainerRef}
        />
    );

    // Memoize the columns for performance
    const columns = useMemo(() => {
        const baseColumns = [
            { name: 'Sr No', selector: (row, index) => index + 1, width: '60px' },
            { name: 'Contract number', selector: row => row.contract_number, sortable: true, width: '150px' },
            { name: 'Bid number', selector: row => row.bid_number, sortable: true, width: '110px' },
            {
                name: 'Bid Copy',
                selector: row => row.bid_copy,
                sortable: false,
                width: '90px',
                cell: row => (
                   <>{row.bid_copy ?  <Tippy content="Download Order Copy">
                       <a href={row.bid_copy} download target="_blank" rel="noopener noreferrer">
                           <button className='action-button'>
                               <FontAwesomeIcon className='action-icon' icon={faFileArrowDown} />
                           </button>
                       </a></Tippy> : ''}
                   </>
                )
            },
            { name: 'Opening Date',selector: row => row.opening_date && indianDateFormat(row.opening_date), selector_field:'opening_date',sortable: true, width: '150px' },
            {
                name: 'Techbotz',
                selector: row => row.techbotz_participation,
                cell: (row, index) => getBadgeHtml(`techbotz_participation-${index}`, row.techbotz_participation === '1' ? 'Yes' : row.techbotz_participation === '0' ? 'No' :row.techbotz_participation,
                    row.techbotz_participation === '0' ? 'red' : row.techbotz_participation === '1' ? 'orange' : 'green', dropdownOptions.participation, row.id),
                width: '100px'
            },
            {
                name: 'Shikshak',
                selector: row => row.shikshak_participation,
                cell: (row, index) => getBadgeHtml(`shikshak_participation-${index}`, row.shikshak_participation === '1' ? 'Yes' : row.shikshak_participation === '0' ? 'No':row.shikshak_participation,
                    row.shikshak_participation === '0' ? 'red' :row.shikshak_participation === '1' ? 'orange' :  'green', dropdownOptions.participation, row.id),
                width: '100px'
            },
            {
                name: 'LT',
                selector: row => row.lt_participation,
                cell: (row, index) => getBadgeHtml(`lt_participation-${index}`, row.lt_participation === '1' ? 'Yes' : row.lt_participation === '0' ? 'No':row.lt_participation,
                    row.lt_participation === '0' ? 'red' : row.lt_participation === '1' ? 'orange' : 'green', dropdownOptions.participation, row.id),
                width: '100px'
            },
            {
                name: 'BK',
                selector: row => row.bktraders_participation,
                cell: (row, index) => getBadgeHtml(`bktraders_participation-${index}`, row.bktraders_participation === '1' ? 'Yes' : row.bktraders_participation === '0' ? 'No':row.bktraders_participation,
                    row.bktraders_participation === '0' ? 'red' :row.bktraders_participation === '1' ? 'orange' :  'green', dropdownOptions.participation, row.id),
                width: '100px'
            },
            { name: 'Remark', selector: row =>  <div className="truncate-text" title={row.remark}>
                    {truncateHTMLText(row.remark, 50)} {/* Adjust maxLength as needed */}
                </div>,
                selector_field:'remark',sortable: true ,width:'200px'},
            {
                name: 'Actions', cell: (row) => (
                    <div className='action-components'>
                        { row.id && <><TableEditActions
                            tooltip={"Edit Gem Bid"}
                            row={row}
                            onEdit={() => { setSelectedLeadOrder(row); setIsEditLeadOrderOpen('edit'); }}
                        />
                        <TableDeleteActions
                            tooltip={"Delete Gem Bid"}
                            row={row}
                            onDelete={() => dispatch(callSoftDeleteDataWithLogFunction({
                                tableName: 'gem_bid',
                                id: row.id,
                                userId: auth?.id,
                                eventTypeId
                            })).then(fetchData)}
                        /></>}
                        {!selectedClientLead && !row.lead_order_id && <Tippy content="Upload Order">
                            <button className='action-button' onClick={() => { setSelectedLeadOrder(row);  setIsEditLeadOrderOpen('sales_order_form'); }}>
                                <FontAwesomeIcon className='action-icon' icon={faBox} />
                            </button>
                        </Tippy> }
                        {!selectedClientLead && <Tippy content="Add Gem Bid">
                            <button className='action-button' onClick={() => { setSelectedLeadOrder(row); setIsEditLeadOrderOpen('add_gem_details');}}>
                                <FontAwesomeIcon className='action-icon' icon={faFileCirclePlus} />
                            </button>
                        </Tippy> }

                    </div>
                ), width: '150px'
            }
        ];

        if (!selectedClientLead) {
            baseColumns.splice(1, 0, { name: 'School', selector: row => row.school_name, sortable: true, width: '200px' });
            baseColumns.splice(1, 0,   { name: 'Order Name', selector: row => row.lead_name, sortable: true, width: '150px' },);
        }

        return baseColumns;
    }, [selectedClientLead, offlineOrder,dropdownOptions, handleBadgeUpdate]);

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable, fetchData]);

    return (
        <>
            <DataTableLayout keyField="id" columns={columns} data={offlineOrder.data} dataTableSetting={settings} fetchData={fetchData} totalRows={offlineOrder?.total} tableContainerRef={tableContainerRef}/>
           {isEditLeadOrderOpen === 'edit' && (
                <LeadToOrderFormModal
                    handleExternalFetch={fetchData}
                    isOpen
                    onRequestClose={() => setIsEditLeadOrderOpen(null)}
                    selectedData={selectedLeadOrder}
                    isEditMode
                />
            )}
           {isEditLeadOrderOpen === 'sales_order_form' && (
               <LeadToOrderFormModal
                   isOpen
                   onRequestClose={() => setIsEditLeadOrderOpen(null)}
                   selectedData={{client_lead_id:selectedLeadOrder.client_lead_id,school_id:selectedLeadOrder.school_id,gem_bid_id:selectedLeadOrder.id}}
               />
            )}
            {isEditLeadOrderOpen === 'add_gem_details' && (
                <GemSchoolForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditLeadOrderOpen=== 'add_gem_details'}
                    onRequestClose={() => setIsEditLeadOrderOpen(false)}
                    selectedData={{client_lead_id:selectedLeadOrder.lead_id,school_id:selectedLeadOrder.school_id}}
                    isEditMode={false}
                />
            )}
        </>
    );
};

export default GemOrderTable;
