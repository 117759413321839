import {api} from "../../hooks/api/ApiConfig";
import {
    CURRICULUM_CONTENT_DATA,
    CURRICULUM_TABLE_DATA, GRADE_SUBJECT_TOPIC_CURRICULUM_TABLE_DATA, GRADE_SUBJECT_TOPIC_TABLE_DATA,
    GRADES_TABLE_DATA,
    SUBJECT_TABLE_DATA,
    TOPIC_TABLE_DATA
} from "../constant/curriculumConstant";

export const actionToGetCurriculumListApiCall = () => async (dispatch) => {
    dispatch({ type: CURRICULUM_TABLE_DATA, payload: 'loading'});
    const {data} = await api.post(`curriculum/get-curriculum-list`);
    dispatch({ type: CURRICULUM_TABLE_DATA, payload: data });
    return data;
}
export const actionToGetCurriculumFileByCurriculumIdApiCall = (payload) => async (dispatch) => {
    dispatch({ type: CURRICULUM_CONTENT_DATA, payload: 'loading'});
    const {data} = await api.post(`curriculum/get-curriculum-file-by-curriculum-id`,{id:payload?.curriculum_id});
    dispatch({ type: CURRICULUM_CONTENT_DATA, payload: data });
    return data;
}
export const actionToGetGradesApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`curriculum/get-grades`,{id:payload?.curriculum_id});
    dispatch({ type: GRADES_TABLE_DATA, payload: data });
    return data;
}
export const actionToGetSubjectsApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`curriculum/get-subjects`,{id:payload?.curriculum_id});
    dispatch({ type: SUBJECT_TABLE_DATA, payload: data });
    return data;
}
export const actionToGetTopicsApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`curriculum/get-topics`,{id:payload?.curriculum_id});
    dispatch({ type: TOPIC_TABLE_DATA, payload: data });
    return data;
}
export const actionToGetGradeSubjectTopicApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`curriculum/get-subject-grade-topic`,payload);
    dispatch({ type: GRADE_SUBJECT_TOPIC_TABLE_DATA, payload: data });
    return data;
}
export const actionToGetGradeCurriculumTopicApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`curriculum/get-curriculum-topic`,payload);
    dispatch({ type: GRADE_SUBJECT_TOPIC_CURRICULUM_TABLE_DATA, payload: data });
    return data;
}
