import {
    BUSINESS_TYPE,
    CLIENT_LEAD_LIST, CONTACT_PERSON_DATA, FOLLOW_UP_TYPE_LIST, LEAD_ADDRESS_DATA, ORDER_TYPE_LIST,
    REGION_LIST, SALES_DASHBOARD_CARD_COUNT,
    SCHOOL_LIST, SCHOOL_TYPE_LIST
} from "../constant/salesConstant";

const initialState = {
    clientLeadList:[],
    schoolList:[],
    regionList:[],
    schoolTypeList:[],
    businessTypeList:[],
    orderTypeList:[],
    followUpTypeList:[],
    contactPersonData:[],
    salesDashboardData:[],
    leadAddressList:[],
    salesEventTypeId:5,
}
export  const salesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_LEAD_LIST:
            return { ...state, clientLeadList: action.payload };
        case SCHOOL_LIST:
            return { ...state, schoolList: action.payload };
        case REGION_LIST:
            return { ...state, regionList: action.payload };
        case SCHOOL_TYPE_LIST:
            return { ...state, schoolTypeList: action.payload };
        case ORDER_TYPE_LIST:
            return { ...state, orderTypeList: action.payload };
        case BUSINESS_TYPE:
            return { ...state, businessTypeList: action.payload };
        case SALES_DASHBOARD_CARD_COUNT:
            return { ...state, salesDashboardData: action.payload };
        case FOLLOW_UP_TYPE_LIST:
            return { ...state, followUpTypeList: action.payload };
        case CONTACT_PERSON_DATA:
            return { ...state, contactPersonData: action.payload };
        case LEAD_ADDRESS_DATA:
            return { ...state, leadAddressList: action.payload };
        default:
            return state
    }
}
