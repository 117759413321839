import React from "react";
import { useSelector } from "react-redux";

export default function CompanyPipeline(props) {
    const companyList = useSelector((state) => state.users?.companyList);

    return (
        <div className="pipeline-tabs-container">
            {companyList.map(item => (
                <div
                    key={item.id}
                    className={`pipeline-tab ${props.source === item.id ? 'pipeline-active' : ''}`}
                    onClick={() => props.changeCompany(item)}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
}
