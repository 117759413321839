// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import SelectOrderTypeDropdown from "./SelectOrderTypeDropdown";
import DateField from "../../Layout/Form/DateField";
import SelectField from "../../Layout/Form/SelectField";
import {actionToGetClientLeadApiCall} from "../../../store/action/salesAction";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import SelectBusinessTypeDropdown from "../contact-person/SelectBusinessTypeDropdown";
import SelectSchoolDropdown from "../school/SelectSchoolDropdown";

const SchoolClientLeadForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const validationRules = {
        name: { required: true, minLength: 3, maxLength: 100 },
        order_type_id: { required: true,field_name:'Order Type' },
    };
    const onSuccess = (res)=>{
        const schoolId = !isEditMode ? selectedData?.id :formData?.school_id;
        if(schoolId){
            dispatch(actionToGetClientLeadApiCall({school_id:schoolId}));
        }
        handleExternalFetch?.(res,['client-lead']);
        onRequestClose();
    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );
    const getBusinessTypeHtml = (business_type_id,user_id)=>{
        let html = '';
        if(!isEditMode){
            if(selectedData?.school_id){
                html = <>
                    <input type={"hidden"} name={"school_id"} value={selectedData?.school_id}/>
                    <input type={"hidden"} name={"business_type_id"} value={1}/>
                </>;
            }else if(business_type_id === 1){
                html = <><SelectBusinessTypeDropdown errors={errors} name="business_type_id" business_type_id={formData.business_type_id} handleChange={handleChange} />
                <SelectSchoolDropdown placeholder='Select School' name={'school_id'} school_id={formData?.school_id} handleChange={handleChange} /></>
            }else if(user_id){
                html = <><input type={"hidden"} name={"user_id"} value={user_id}/>
                    <input type={"hidden"} name={"business_type_id"} value={business_type_id}/></>
            }else{
                html = <>
                    <SelectBusinessTypeDropdown errors={errors} name="business_type_id" business_type_id={formData.business_type_id} handleChange={handleChange} />
                </>
            }
        }
        return html;
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Business Regarding' : 'Add Business Regarding'} second_crumb={formData.school_name}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'school_client_lead',{created_by:auth?.id},[])}>
                    <div className='vendor-details'>
                        {getBusinessTypeHtml(formData?.business_type_id,formData?.user_id)}
                    <InputField
                        label="Name of the Business*"
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleChange}
                        error={errors.name} placeholder='Enter Name of the Business'
                    />
                    <SelectOrderTypeDropdown errors={errors} name="order_type_id" order_type_id={formData.order_type_id} handleChange={handleChange}  />
                    <InputField
                        label="Order Value"
                        type="number"
                        name="order_value"
                        value={formData.order_value || ''}
                        onChange={handleChange}
                        error={errors.order_value}
                        placeholder={'Order Value'}
                        min="0" // Set minimum value to avoid negative amounts
                        step="0.01" // Allows decimal input for amounts with cents
                    />
                    <SelectField label={"Interest Level"}
                                 name={'interest_level'}
                                 value={formData.interest_level || ''}
                                 onChange={handleChange}
                                 valueArray={[ { value: 'High', label: 'High' },
                                     { value: 'Medium', label: 'Medium' },
                                     { value: 'Low', label: 'Low' }]}
                                 error={errors.interest_level} className='select'/>
                    <SelectField label={"Lead Status"}
                                 name={'lead_status'}
                                 value={formData.lead_status || ''}
                                 onChange={handleChange}
                                 valueArray={[{value:'New',label:'New'},{value:'Contacted',label:'Contacted'},{value:'Demo Scheduled',label:'Demo Scheduled'},
                                     {value:'Closed Won',label:'Closed Won'},{value:'Closed Lost',label:'Closed Lost'}]}
                                 error={errors.lead_status} className='select'/>
                        <DateField label="Expected Date to Close"
                                   name={'expect_date_to_close'}
                                   minDate={new Date()}
                                   value={formData.expect_date_to_close || ''}
                                   onChange={handleChange}
                                   error={errors.expect_date_to_close}
                                   placeholder={'Expected Closing Date'} className='form-input'/>
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default SchoolClientLeadForm;
