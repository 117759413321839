import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
    useEffect(() => {
        // Function to handle clicks outside the element
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(); // Execute the callback if click is outside the ref element
            }
        }

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Remove event listener on cleanup
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};

export default useOutsideClick;