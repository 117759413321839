import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelectField from "../../Layout/Form/CreatableSelectField";
import SchoolForm from "./SchoolForm";
import {makeApiCall} from "../../../hooks/api/makeApiCall";

const SelectSchoolDropdown = ({name,handleChange,school_id,placeholder,error}) =>{
    const dispatch=useDispatch();
    const schoolList = useSelector((state) =>  state.sales?.schoolList);
    const [addModalName, setAddModalName] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(false);
    const [contactPersonDataDropdown, setContactPersonDataDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState(null);

    useEffect(() => {
        let componentArray = [];
        let selectedData = null;
        schoolList !== 'loading'  && schoolList?.length > 0 && schoolList?.map(val=>{
                componentArray.push({value:val.id,label:val.name});
                if(school_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setContactPersonDataDropdown([...componentArray]);
    }, [schoolList,school_id]);
    const handleCreate = (val) => {
        setSelectedSchool({name:val});
        setAddModalName('add_school');
    };
    const handleFetchData = (res)=>{

        makeApiCall(`sales/get-school-list-dropdown`,SCHOOL_LIST,dispatch).then(response=>{
            handleChange({target:{name:name,value:res.id}});
            }
        );
    }
    const customStyles = {controlHeight:'50px', menuListMaxHeight:'150px'}

    return(<>
        <CreatableSelectField name={name} label={placeholder} customStyles={customStyles} placeholder={placeholder} error={error} dataDropdown={contactPersonDataDropdown}
                                  handleCreate={handleCreate} selectedDropDown={selectedDropDown} onChange={handleChange}/>
        {addModalName === 'add_school' && (
            <SchoolForm
                selectedData={selectedSchool}
                handleExternalFetch={handleFetchData}
                isOpen={addModalName === 'add_school'}
                onRequestClose={() => setAddModalName(false)}
                isEditMode={false}
            />
        )}
    </>
    )
}
export default SelectSchoolDropdown;
