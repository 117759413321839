import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faBroom, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PreLoader from "../../../loader/PreLoader";
import swal from "sweetalert";
import {callSoftDeleteDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import {
    actionToDeleteCacheApiCall,
    actionToGetWebSettingSectionApiCall
} from "../../../../store/action/webSettingActions";
import Modal from "react-modal";
import EditWebsiteSectionContent from "./EditWebsiteSectionContent";
import Tippy from "@tippyjs/react";
export const WebsiteSectionContentTable = ({ isOpen, onRequestClose,selectedData }) => {
    const [isEditSectionOpen, setIsEditSectionOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const websiteSectionContentData = selectedData?.content;
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const deleteSection = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this SectionContent "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'website_section_content',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        row.menu_data?.map(menu=>
                            dispatch(actionToDeleteCacheApiCall({key:'shikshak-website-section-content'+menu?.url+row.source}))
                        )
                        dispatch(actionToGetWebSettingSectionApiCall())
                    })
                }
            });

    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(websiteSectionContentData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === websiteSectionContentData.length);
    };

    const renderContentValue = (value, type) => {
        switch (type) {
            case 'image':
                return <img src={value} alt="content" style={{maxWidth: '40px', maxHeight: '40px'}} />;
            case 'video':
                return (
                    <video width="150" height="100" controls>
                        <source src={value} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'text':
                return <p>{value}</p>;
            case 'code':
                return <pre><code>{value}</code></pre>;
            default:
                return <span>{value}</span>;
        }
    };
    return(
        <>
            <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='description-modal'>
            <div className='table-container'>
                <div className='flex justify-end'>
                    <Tippy content='Clean Cache'>
                        <button  className='submit-button mb-2'>
                            <FontAwesomeIcon icon={faBroom} />
                        </button>
                    </Tippy></div>
            {
                websiteSectionContentData === 'loading' ?
                    <PreLoader/>
                    : websiteSectionContentData?.length > 0 ?
                    <>
                        <div className="website-table">
                            <div className="website-row-header">
                                <div className="seven-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectAll}
                                           onChange={()=>handleSelectAll()}/>
                                    Name
                                </div>

                                <div className="seven-column-cell">position</div>
                                <div className="seven-column-cell">Object Heading</div>
                                <div className="seven-column-cell">Object Type</div>
                                <div className="seven-column-cell">Object Class</div>
                                <div className="seven-column-cell">Value</div>
                                <div className="seven-column-cell">Action</div>
                            </div>
                            <Virtuoso style={{ height: '600px' }} totalCount={websiteSectionContentData.length} itemContent={index =>
                                <div className="table-row">
                                    <div className="seven-column-cell">
                                        <input className='checkbox' type="checkbox"
                                               checked={selectedRows.includes(websiteSectionContentData[index]?.id)}
                                               onChange={() => handleSelectRow(websiteSectionContentData[index]?.id)}/>
                                        {websiteSectionContentData[index]?.name}
                                    </div>
                                    <div className="seven-column-cell"> {websiteSectionContentData[index]?.position}</div>
                                    <div className="seven-column-cell"> {websiteSectionContentData[index]?.object_heading}</div>
                                    <div className="seven-column-cell"> {websiteSectionContentData[index]?.object_type}</div>
                                    <div className="seven-column-cell"> {websiteSectionContentData[index]?.object_class}</div>
                                    <div className="seven-column-cell">
                                        {renderContentValue(websiteSectionContentData[index]?.object_value, websiteSectionContentData[index]?.object_type)}
                                    </div>
                                    <div className="seven-column-cell">
                                        <div className='action-components'>
                                            <div className='action-buttons'>
                                                <button className='action-button' onClick={() => {setIsEditSectionOpen('edit_content'); setSelectedIndex(index);}} >
                                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                                </button>
                                            </div>
                                            <div className='action-buttons'>
                                                <button onClick={()=>deleteSection(websiteSectionContentData[index])} className='action-button'>
                                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                </button>
                                            </div>
                                    </div>
                                </div>
                                </div>
                            } />
                        </div>
                        <EditWebsiteSectionContent isOpen={isEditSectionOpen === 'edit_content'} mainData={selectedData} selectedData={websiteSectionContentData[selectedIndex]} onRequestClose={() => {setIsEditSectionOpen(false); setSelectedIndex(false);}} />

                    </> :''
            }
            </div>
            </Modal>
            </>
    )
}
