import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PreLoader from "../../loader/PreLoader";
import EditBrands from "./EditBrands";
import swal from "sweetalert";
import {actionToGetBrandsApiCall} from "../../../store/action/inventoryActions";
import {callSoftDeleteDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
export const BrandTable = () => {
    const [isEditBrandsOpen, setIsEditBrandsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const brandData = useSelector((state) =>  state.inventory?.brandData);
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);

    useEffectOnce(()=>{
        dispatch(actionToGetBrandsApiCall());
    })

    const deleteBrand = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Brand "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'brand',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetBrandsApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(brandData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === brandData.length);
    };
    return(
        <>
            {
                brandData === 'loading' ?
                    <PreLoader/>
                    :
        <div className="table">
            <div className="row-header">
                <div className="two-column-cell">
                    <input className='checkbox' type="checkbox"
                           checked={selectAll}
                           onChange={()=>handleSelectAll()}/>
                    Brand Name
                </div>
                <div className="two-column-cell">Action</div>
            </div>
            <Virtuoso style={{ height: '600px' }} totalCount={brandData.length} itemContent={index =>
                <div className="table-row">
                    <div className="two-column-cell">
                        <input className='checkbox' type="checkbox"
                               checked={selectedRows.includes(brandData[index]?.id)}
                               onChange={() => handleSelectRow(brandData[index]?.id)}/>
                        {brandData[index]?.name}
                    </div>
                    <div className="two-column-cell">
                        <div className='action-components'>
                            <div className='action-buttons'>
                                <Tippy content='Edit'>
                                <button className='action-button' onClick={() => {setIsEditBrandsOpen(true); setSelectedIndex(index);}} >
                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                </button></Tippy>
                            </div>
                            <div className='action-buttons'>
                                <Tippy content='Delete'>
                                <button onClick={()=>deleteBrand(brandData[index])} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                </button></Tippy>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        </div>
            }
            <EditBrands isOpen={isEditBrandsOpen} selectedData={brandData[selectedIndex]} onRequestClose={() => {setIsEditBrandsOpen(false); setSelectedIndex(false);}} />
        </>
    )
}
