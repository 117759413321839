import  React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCaretRight,
    faFolder,
    faFolderOpen,
    faFolderPlus,
    faPlusCircle,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import AddFolderDocument from "./AddFolderDocument";
import AddFolders from "./AddFolders";
import swal from "sweetalert";
import {callSoftDeleteDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {useDispatch, useSelector} from "react-redux";
import {actionToGetGalleryListApiCall} from "../../../store/action/galleryActions";

const FileNode = ({ node }) => {
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const [expanded, setExpanded] = useState(false);
    const [selectedData, setSelectedData] = useState(false);
    const [addModalName, setAddModalName] = useState(false);
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.gallery.galleryEventTypeId);
    const dispatch = useDispatch();

    const toggleExpand = () => {
        setExpanded(!expanded);
    };


    const deleteFileFolder = (row) => {
        let message = "You want to delete this  "+row?.name;
        message +=(row.type=='folder') &&'. you will loose all document under this folder';
        swal({
            title: "Are you sure?",
            text: message,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'gallery_folder',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetGalleryListApiCall(!allowedRoles?.includes(auth?.role) ? {user_id:auth?.id}:{}))
                    })
                }
            });

    };

    return (<>

        <div className="file-node" key={node?.id}>
            <div onClick={toggleExpand} className="file-node-label">

                <div className={node.level ===2 ? 'indent-4' : node.level ===3 ? 'indent-16' : node.level ===4 ? 'indent-24': ''}>
                    {node.type === "folder" ? (
                        expanded ? (
                            <span className='gallery-icon'><FontAwesomeIcon icon={faFolderOpen}/></span>
                        ) : (
                            <span className='gallery-icon'><FontAwesomeIcon icon={faFolder}/></span>
                        )
                    ) : (
                        <span className='gallery-icon'>📄</span>
                    )}
                <span className='folder-title'>{node.name}</span>
                <div className='tree-buttons'>{node.type === "folder" && node.level <4 ? <>
                        <button className='action-button' onClick={()=>{setAddModalName('add_folder_document')
                            setSelectedData(node);}}><FontAwesomeIcon icon={faPlusCircle} /></button>
                        <button className='action-button' onClick={()=>{setAddModalName('add_folder')
                            setSelectedData(node);}}><FontAwesomeIcon icon={faFolderPlus} /></button>
                        <button className='action-button' onClick={()=>{deleteFileFolder(node);}}><FontAwesomeIcon icon={faTrash} /></button>
                    </>
                    : ''}</div>
                </div>
            </div>
            {expanded && node.children && (
                <div className="file-node-children">
                    {node.children.map((childNode) => (
                        <FileNode key={childNode.name} node={childNode} />
                    ))}
                </div>
            )}
        </div>
            <AddFolderDocument selectedData={selectedData} isOpen={addModalName === 'add_folder_document'} onRequestClose={() => setAddModalName(false)} />
            <AddFolders selectedData={selectedData} isOpen={addModalName === 'add_folder'} onRequestClose={() => setAddModalName(false)} />
    </>

    );
};

export default FileNode;
