import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {capitalizeFirstLetter} from "../../../store/helper/commonHelper";
import {actionToGetUsersListApiCall, actionToUpdateUser} from "../../../store/action/usersActions";
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";


const EditUsers=  React.memo(({ isOpen, onRequestClose,selectedUser }) => {
    const dispatch = useDispatch();
    const genderList = ['male', 'female', 'others'];
    const usersRoleList = useSelector((state) =>  state.users?.usersRoleList);
    const [formData, setFormData] = useState(false);
    const {auth} = useAuth();
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(actionToUpdateUser(obj, selectedUser.id)).then(res => {
                let status = res?.data?.response?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                if (status === 'success') {
                    toast.success('User updated successfully!'); // Success toast
                    dispatch(actionToGetUsersListApiCall());
                    onRequestClose();
                } else {
                    toast.error('Failed to update user.'); // Error toast
                }
            });
        } else {
            setSaveStatus('fail');
            toast.error('Form submission failed due to validation errors.'); // Validation error toast
        }
    };

    useEffect(() => {
        setFormData(selectedUser);
    }, [selectedUser]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!data.email && !data.mobile) {
            errors.contact = 'Either Email or Mobile is required';
        } else {
            if (data.email && !/\S+@\S+\.\S+/.test(data.email)) {
                errors.email = 'Email is invalid';
            }
            if (data.mobile && !/^\d{10}$/.test(data.mobile)) {
                errors.mobile = 'Mobile must be a 10-digit number';
            }
        }

        return errors;
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Edit User</div>
                {formData && <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            {/*<span className='details'>Name</span>*/}
                            <input type='text' placeholder='Name*'
                                   name='name' className='form-input'
                                   value={formData?.name}
                                   onChange={handleChange}/>
                            {errors.name &&
                                <span className="error-message">
                            {errors.name}
                        </span>
                            }
                        </div>
                        <div className='input-box'>
                            <input type='email' name='email' className='form-input'
                                   placeholder='Email' value={formData?.email}
                                   onChange={handleChange}/>
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className='input-box'>
                            <input type='text' name='mobile' className='form-input'
                                   placeholder='Mobile' value={formData?.mobile}
                                   onChange={handleChange}/>
                            {errors.mobile && <span className="error">{errors.mobile}</span>}
                        </div>
                        {/*<div className='input-box'>
                            <input type='text' name='password' className='form-input'
                                   placeholder='password' value={formData?.password}
                                   onChange={handleChange}/>
                        </div>*/}
                        <div className='input-box'>
                            <select className='select' name='role' >
                                {usersRoleList !== 'loading' && usersRoleList?.map((role,index) => {
                                    return role.in_option==='1' && <option key={role?.id} value={role?.id} selected={index === formData?.role}>{role?.role}</option>
                                })}
                            </select>
                        </div>
                        <div className='input-box'>
                            <select className='select' name='gender' >
                                {genderList?.map((gender) => {
                                    return <option key={gender} value={gender} selected={gender === formData?.gender} onSelect={handleChange}>{capitalizeFirstLetter(gender)}</option>
                                })}
                            </select>
                        </div>
                        <div className='input-box'>
                            <input type={'date'} name={'date_of_birth'} className='form-input' required value={formData?.date_of_birth && new Date(formData?.date_of_birth).toISOString().split('T')[0]} onChange={handleChange}/>
                            {/*<DatePicker name={"date_of_birth"} placeholderText={'Date of Birth'} className='form-input'/>*/}
                        </div>
                        <div className='input-box'>
                            <textarea className='text-area' name={"address"} placeholder={'Address'}  onChange={handleChange} value={formData?.address} />
                        </div>
                    {errors.contact && <span className="error">{errors.contact}</span>}
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">User Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
                }
            </div>
        </Modal>
    );
});


export default EditUsers;
