import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetBrandsApiCall} from "../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";


const EditBrands= ({ isOpen,selectedData, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [formData, setFormData] = useState({
        name: ''
    });

    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedData));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());

        // Validate form data
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const res = await dispatch(callUpdateDataWithLogFunction({
                    tableName: 'brand',
                    data: obj,
                    id: selectedData?.id,
                    userId: auth?.id,
                    eventTypeId,
                }));

                // Determine the status based on the response
                const status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                // Show appropriate feedback to the user
                if (status === 'success') {
                    dispatch(actionToGetBrandsApiCall());
                    toast.success('Brand updated successfully!');
                    onRequestClose();
                } else {
                    toast.error('Failed to update brand. Please try again.');
                }
            } catch (error) {
                console.error('Error during submission:', error);
                setSaveStatus('fail');
                toast.error('An error occurred while updating the brand.');
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.');
        }
    };

    useEffect(() => {
        setFormData(selectedData);
    }, [selectedData]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Brand Name is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Edit Brand</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <span className='details'>Brand Name</span>
                            <input type='text' placeholder='Brand Name*'
                                   name='name' className='form-input'
                                   value={formData?.name}
                                   onChange={handleChange}/>
                            {errors?.name && <span className="error-message">{errors.name} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Slug Name</span>
                            <input type='text' placeholder='Slug' name='slug' className='form-input'  value={formData?.slug}
                                   onChange={handleChange}/>
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'  disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Brand Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};


export default EditBrands;
