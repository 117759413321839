import React, { useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookOpenReader, faBroom, faImages, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PreLoader from "../../loader/PreLoader";
import swal from "sweetalert";
import useAuth from "../../../hooks/useAuth";
import {
     uploadFileOnAws
} from "../../../store/action/inventoryActions";
import {callDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import {actionToGetCurriculumListApiCall} from "../../../store/action/curriculumAction";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import CurriculumFile from "./CurriculumFile";
import EditCurriculum from "./EditCurriculum";
import AddGradeSubjectTopicCurriculum from "./AddGradeSubjectTopicCurriculum";
import Tippy from "@tippyjs/react";

export const CurriculumTable = () => {
    const [isEditCurriculumOpen, setIsEditCurriculumOpen] = useState(false);
    const [isProductCurriculumImagesOpen, setIsProductCurriculumImagesOpen] = useState(false);
    const dispatch = useDispatch();
    const curriculumListData = useSelector((state) =>  state.curriculum?.curriculumListData);
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const hiddenFileInput = useRef(null);
    const {auth} = useAuth();
    useEffectOnce(() => {
        dispatch(actionToGetCurriculumListApiCall());
    }, []);
    const deleteCurriculum = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this curriculum "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'product_curriculum',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetCurriculumListApiCall());
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(curriculumListData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === curriculumListData.length);
    };
    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED

    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        let data = curriculumListData[selectedIndex];
        let name = data?.name.replace(/\s+/g, '-').toLowerCase();
        let filename = `products/${name}-${data.id}.png`;
        let deletedFileName =  data.photo?.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/','');
        let url = await dispatch(uploadFileOnAws({filename:filename,deletedFilename:deletedFileName,photo:fileUploaded,type:'image/png'}));
        dispatch(callUpdateDataWithLogFunction({tableName:'product_curriculum',data: {photo:url+'?v='+Math.floor(1000 + Math.random() * 9000)},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetCurriculumListApiCall());
        })
    }
    return(
        <>
            <div className='table-container'>
                <div className='flex justify-end'>
                    <Tippy content='Clean Cache'>
                        <button  className='submit-button mb-2'>
                            <FontAwesomeIcon icon={faBroom} />
                        </button>
                    </Tippy></div>
             {
                    curriculumListData === 'loading' ?
                        <PreLoader/>
                        :

                        <div className="table">
                            <div className="curriculum-row-header">
                                <div className="six-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectAll}
                                           onChange={handleSelectAll}/>
                                    Curriculum
                                </div>
                                <div className="six-column-cell">Slug</div>
                                <div className="six-column-cell">Focus</div>
                                <div className="six-column-cell">Description</div>
                                <div className="six-column-cell">Image</div>
                                <div className="six-column-cell">Action</div>
                            </div>
                            <Virtuoso style={{ height: '600px' }} totalCount={curriculumListData.length} itemContent={index =>
                                <div className="table-row">
                                    <div className="six-column-cell">
                                        <input className='checkbox' type="checkbox"
                                               checked={selectedRows.includes(curriculumListData[index]?.id)}
                                               onChange={() => handleSelectRow(curriculumListData[index]?.id)}/>
                                        {curriculumListData[index]?.name}
                                    </div>
                                    <div className="six-column-cell">{curriculumListData[index]?.slug}</div>
                                    <div className="six-column-cell">{curriculumListData[index]?.focus}</div>
                                    <div className="six-column-cell">{curriculumListData[index]?.description}</div>
                                    <div className="six-column-cell" onClick={()=>handlePhotoClick(index)}>
                                        <img src={curriculumListData[index]?.photo} height="35" width="35" alt={curriculumListData[index]?.name}/></div>
                                    <div className="six-column-cell">
                                        <div className='action-components'>
                                            <div className='action-buttons'>
                                                <button className='action-button' onClick={() => {setIsEditCurriculumOpen('edit_curriculum'); setSelectedIndex(index);}} >
                                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                                </button>
                                            </div>
                                            <div className='action-buttons'>
                                                <button className='action-button' onClick={() => {setIsEditCurriculumOpen('add_grade_subject_topic'); setSelectedIndex(index);}} >
                                                    <FontAwesomeIcon className='action-icon' icon={faBookOpenReader} />
                                                </button>
                                            </div>
                                            <div className='action-buttons'>
                                                <button className='action-button' onClick={() => {setIsProductCurriculumImagesOpen(true);  setSelectedIndex(index);}}>
                                                    <FontAwesomeIcon icon={faImages}/>
                                                </button>
                                            </div>
                                            <div className='action-buttons'>
                                                <button onClick={()=>deleteCurriculum(curriculumListData[index])} className='action-button'>
                                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            } />
                        </div>
                }
                <input
                    type="file"
                    onChange={handleMainPhotoUpload}
                    ref={hiddenFileInput}
                    style={{display: 'none'}} />
                <CurriculumFile isOpen={isProductCurriculumImagesOpen}   selectedCurriculum={curriculumListData[selectedIndex]}   onRequestClose={() => {setIsProductCurriculumImagesOpen(false); setSelectedIndex(false);}} />
                <EditCurriculum isOpen={isEditCurriculumOpen === 'edit_curriculum'} selectedCurriculum={curriculumListData[selectedIndex]} onRequestClose={() => {setIsEditCurriculumOpen(false); setSelectedIndex(false);}} />
                <AddGradeSubjectTopicCurriculum isOpen={isEditCurriculumOpen === 'add_grade_subject_topic'} selectedData={curriculumListData[selectedIndex]} onRequestClose={() => {setIsEditCurriculumOpen(false); setSelectedIndex(false);}} />
            </div>
        </>
    )
}
