import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetBrandsApiCall} from "../../../store/action/inventoryActions";
import useAuth from "../../../hooks/useAuth";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import {toast} from "react-toastify";

const AddBrand = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const dispatch = useDispatch();
    const {auth} = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());

        // Validate form data
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // Add created_by to the data
            obj['created_by'] = auth?.id;

            try {
                const res = await dispatch(callInsertDataWithLogFunction({
                    tableName: 'brand',
                    data: obj,
                    userId: auth?.id,
                    eventTypeId
                }));

                // Fetch the updated brands list
                dispatch(actionToGetBrandsApiCall());

                // Determine the status based on the response
                const status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                // Show appropriate feedback to the user
                if (status === 'success') {
                    toast.success('Brand created successfully!');
                    resetForm();
                } else {
                    toast.error('Failed to create brand. Please try again.');
                }
            } catch (error) {
                console.error('Error during submission:', error);
                setSaveStatus('fail');
                toast.error('An error occurred while submitting the form.');
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.');
        }
    };

    const resetForm = () => {
        document.getElementById('brand-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Brand is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Brand</div>
                <form onSubmit={handleSubmit} id='brand-form'>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <span className='details'>Brand Name</span>
                            <input type='text' placeholder='Brand Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Slug</span>
                            <input type='text' placeholder='Slug' name='slug' className='form-input'/>
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Brand Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddBrand;
