import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import CurriculumComponent from "../Components/Curriculum/CurriculumComponent";
export default function CurriculumPage() {
    return (
        <>
            <Routes>
                <Route path="" exact element={<Navigate to="product" replace />} />
                <Route path="/:tabName" element={<CurriculumComponent />} />
            </Routes>
        </>
    )
}