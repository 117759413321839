// Reusable Input Component
import React from "react";
const BreadCrumb = ({first_crumb, second_crumb, third_crumb}) => (

        <ul className="breadcrumb">
            {first_crumb &&<li className="crumb first-crumb font-semibold">{first_crumb}</li>}
            {second_crumb &&<li className="crumb middle-crumb font-semibold">{second_crumb}</li>}
            {third_crumb &&<li className="crumb last-crumb font-semibold">{third_crumb}</li>}
        </ul>

);
export default BreadCrumb;
