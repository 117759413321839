// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import InputField from '../../../Layout/Form/InputField';
import {useCommonForm} from "../../../../hooks/useCommonForm";
import { useSelector} from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import BreadCrumb from "../../../Layout/Breadcrumb/BreadCrumb";
import TinyMceEditor from "../../../Layout/Form/TinyMceEditor";

const BlogPostForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const validationRules = {
        title: { required: true, minLength: 3, maxLength: 255 },
        content: { required: true }
    };
    const onSuccess = (res)=>{
        if(isEditMode) handleExternalFetch();
        else handleExternalFetch(res);
        onRequestClose();
    }

    const { formData, errors, handleChange, handleSubmit, isFormChanged } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );
    console.log(formData,'formData')
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container max-h-[80vh] overflow-y-auto p-5 bg-white rounded-lg w-[90%]">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Post' : 'Add Post'}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'blog_posts',{created_by:auth?.id,content:formData.content},[])}>
                    <div className='vendor-details'>
                    <InputField
                        type="text"
                        name="title"
                        value={formData.title || ''}
                        onChange={handleChange}
                        error={errors.title} placeholder={'Post Title'} label={'Post Title'}
                    />
                    <InputField
                        type="text"
                        name="slug"
                        value={formData.slug || ''}
                        onChange={handleChange}
                        error={errors.slug} placeholder={'Slug'} label={'Post Slug'}
                    />
                    <TinyMceEditor label={"Content"} name="content" value={isEditMode? selectedData.content :formData.content} onChange={handleChange} error={errors.content} />
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default BlogPostForm;
