// components/TableActions.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import {useSelector} from "react-redux";
import AuthorizedComponent from "../../auth/AuthorizedComponent";

const TableEditActions = ({ row, onEdit,tooltip,auth_key }) => {
    const formAuthRule = useSelector((state) => state.common.formAuthRule && state.common.formAuthRule[auth_key]);
    return (
        <>
            {formAuthRule ?  <AuthorizedComponent allowedRoles={ formAuthRule.split(",")}
                                                  AuthComponent={<>
                                                      <Tippy content={tooltip}>
                    <button className='action-button' onClick={() => onEdit(row)}>
                        <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                    </button>
                </Tippy> </>}
                                                  Component={''}/> :
                <Tippy content={tooltip}>
                    <button className='action-button' onClick={() => onEdit(row)}>
                        <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                    </button>
                </Tippy>
            }
        </>
    );
};

export default TableEditActions;
