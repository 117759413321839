// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import SchoolClientLeadInteractionTable from "./SchoolClientLeadInteractionTable";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";

const SchoolClientLeadTableInteractionModal = ({ isOpen, onRequestClose,selectedLead }) => {

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='medium-table-modal'>
            <div className='m-5'>
                <SchoolClientLeadInteractionTable selectedLead={selectedLead} />
            </div>
            <div className='flex justify-center'>
                <button className='submit-button' onClick={onRequestClose}>Close</button>
            </div>
        </Modal>
    );
};

export default SchoolClientLeadTableInteractionModal;
