// components/TableActions.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import swal from 'sweetalert';
import AuthorizedComponent from "../../auth/AuthorizedComponent";
import {useSelector} from "react-redux";

const TableDeleteActions = ({ row, onDelete,auth_key,tooltip }) => {
    const formAuthRule = useSelector((state) => state.common.formAuthRule && state.common.formAuthRule[auth_key]);

    const handleDelete = () => {
        swal({
            title: "Are you sure?",
            text: `You want to delete ${row.name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) onDelete(row);
        });
    };

    return (
        <>
            {formAuthRule ? <AuthorizedComponent allowedRoles={ formAuthRule.split(",")}
                                                 AuthComponent={<>
                                                     <Tippy content={tooltip}>
                                                         <button className='action-button' onClick={handleDelete}>
                                                             <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                         </button>
                                                     </Tippy>
                                                 </>}
                                                 Component={''}/> :
                <Tippy content={tooltip}>
                    <button className='action-button' onClick={handleDelete}>
                        <FontAwesomeIcon className='action-icon' icon={faTrash} />
                    </button>
                </Tippy>}

        </>
    );
};

export default TableDeleteActions;
