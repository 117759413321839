import { Route, Routes} from "react-router-dom";
import React from "react";
import GalleryComponent from "../Components/gallery/GalleryComponent";
import '../assets/fileTree.css'
export default function GalleryPage() {
    return (
        <>
            <Routes>
                <Route path="" exact element={<GalleryComponent replace />} />
                <Route path="/:folderName" element={<GalleryComponent />} />
            </Routes>
        </>
    )
}