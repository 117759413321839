// hooks/useSchoolForm.js
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {callInsertDataWithLogFunction, callUpdateDataWithLogFunction} from "../store/action/commonActions";
import {validateForm} from "../store/utility/validation";
import {toast} from "react-toastify";
import {actionToAddSchool, actionToUpdateSchool} from "../store/action/salesAction";
import {
    actionToAddVendorTransporter,
    actionToUpdateVendorTransporter,
    uploadFileOnAws
} from "../store/action/inventoryActions";

export const useCommonForm = (isEditMode, selectedData, onSuccess, eventTypeId, userId) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isFormChanged, setIsFormChanged] = useState(false);

    useEffect(() => {
        if (selectedData) setFormData(selectedData);
        else setFormData({});
        setErrors({});
    }, [selectedData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setIsFormChanged(true);
    };

    const updatePinCode = (val)=>{
        if( val.city_id){
            setFormData({
                ...formData,
                ['pincode']:val.pincode,['city_id']:val.city_id,['state_id']:val.state_id
            });
        }else{
            setFormData({
                ...formData,
                ['pincode']:val.pincode
            });
        }
    }
    const getActionSubmit = (tableName) =>{
        let actionSubmit;
        switch (tableName){
            case 'schools':
            case 'school_contact_person':
                actionSubmit = isEditMode ? actionToUpdateSchool : actionToAddSchool
                break;
            case 'transporter':
            case 'vendor':
                actionSubmit = isEditMode ? actionToUpdateVendorTransporter : actionToAddVendorTransporter
                break;
            default:
                actionSubmit =  isEditMode ? callUpdateDataWithLogFunction : callInsertDataWithLogFunction
                break;
        }
        return actionSubmit;
    }
    const handleSubmit = (e, validationRules, tableName, extraFieldArray, removeFieldArray) => {
        e.preventDefault();
        setIsFormChanged(false);
        const formDataValue = new FormData(e.target);
        const obj = Object.fromEntries(formDataValue.entries());
        const sendObj = Object.fromEntries(formDataValue.entries());
        let errorList = validateForm(formData, validationRules);
        setErrors(errorList);

        if (Object.keys(errorList).length === 0) {
            const actionSubmit = getActionSubmit(tableName);
            removeFieldArray?.map(val => delete obj[val]);
            const formObjectData = { ...obj, ...extraFieldArray };
            Object.keys(formObjectData).map(formKey=>{
                let formValue = formObjectData[formKey];
                if(formValue.type){
                    const fileExtension = formValue.name.split('.').pop();
                    const filename = `${tableName}/content-${Date.now()}.${fileExtension}`;
                    // Upload the file to AWS
                    formObjectData[formKey] ="https://shikshak-solutions.s3.eu-north-1.amazonaws.com/"+filename
                    dispatch(uploadFileOnAws({
                        filename,
                        photo:formValue,
                        showOnWebsite: 'shikshak',
                        type: formValue.type
                    }));
                }
            })
            dispatch(actionSubmit({ tableName, data: formObjectData, id: selectedData?.id, userId, eventTypeId }))
                .then((res) => {
                    if (res?.success === 1) {
                        toast.success("Submission successful!");
                        onSuccess(res,sendObj);
                    } else {
                        setErrors({ form: res?.message ?? 'Submission failed' });
                        toast.error(res?.message ?? "Submission failed");
                    }
                })
                .catch((e) => {
                    toast.error("An error occurred during submission");
                });
        }
    };

    return { formData, errors, handleChange, handleSubmit, isFormChanged,updatePinCode };
};
