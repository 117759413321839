import React, {useMemo, useState} from 'react';
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useFetchData} from "../../../hooks/useFetchData";
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import {faFileArrowDown} from "@fortawesome/free-solid-svg-icons";
import {indianDateTimeFormat} from "../../../store/utility/DatatableUtility";
import Modal from "react-modal";

const LeadRelatedDataTable = ({ isOpen, onRequestClose,selectedLead }) => {
    const [advanceFilter, setAdvanceFilter] = useState({'l.lead_id': selectedLead?.id});

    const { data: orders, fetchData, settings } = useFetchData('sales/get-lead-related-table-data', [],advanceFilter);

    // Memoize the columns for performance
    const columns = useMemo(() => {
        const baseColumns = [
            { name: 'id', selector: row => row.id, selector_field:'id',
                cell: row => (
                    <>
                        {row.id}
                    </>
                ), sortable: true },
            { name: 'Document Type', selector: row => row.document_type, selector_field:'document_type',sortable: true },
            { name: 'Created Date', selector: row => row.created_at && indianDateTimeFormat(row.created_at), selector_field:'created_at',sortable: true },
            {
                name: 'Document',
                selector: row => row.order_document,
                sortable: false,
                width: '90px',
                cell: row => (
                    <>{row.order_document ?  <Tippy content={row.document_type}>
                        <a href={row.order_document} download target="_blank" rel="noopener noreferrer">
                            <button className='action-button'>
                                <FontAwesomeIcon className='action-icon' icon={faFileArrowDown} />
                            </button>
                        </a></Tippy> : ''}
                    </>
                )
            },
            { name: 'User Name', selector: row => row.user_name, selector_field:'user_name',sortable: true },
            {
                name: 'Actions',
                cell: row => (
                    <>

                    </>
                ),
            },
        ];
        if(advanceFilter['o.company_id'] !== 2){
            baseColumns.splice(1, 0, { name: 'School', selector: row => row.school_name, sortable: true, width: '200px' });
            baseColumns.splice(1, 0,   { name: 'Order Name', selector: row => row.lead_name, sortable: true, width: '150px' },);
         }

        return baseColumns;
    }, [orders]);

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="Modal">
            <DataTableLayout keyField="id" columns={columns} data={orders.data} dataTableSetting={settings} fetchData={fetchData} totalRows={orders?.total} />
            <div className="buttons">
                <button className="close-button" type="button" onClick={onRequestClose}>
                    Close
                </button>
            </div>
        </Modal>
    );
};

export default LeadRelatedDataTable;
