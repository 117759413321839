import React from "react";
import FileNode from "./FileNode";

const FileTree = ({ treeData }) => {
    if(treeData.length >0)
        return (
            <div className="file-tree">
                {treeData.map((node) => (
                    <FileNode key={node.name} node={node} />
                ))}
            </div>
        );
};
export default FileTree;