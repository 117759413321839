export const COMPANY_LIST = "COMPANY_LIST";
export const ROLE_LIST = "ROLE_LIST";
export const VENDOR_LIST = "VENDOR_LIST";
export const CUSTOMER_LIST = "CUSTOMER_LIST";
export const UPDATE_VENDOR_FORM_DATA = "UPDATE_VENDOR_FORM_DATA";
export const UPDATE_CUSTOMER_FORM_DATA = "UPDATE_CUSTOMER_FORM_DATA";
export const PRODUCT_DETAILS_DATA_CP = "PRODUCT_DETAILS_DATA_CP";
export const STATE_LIST = "STATE_LIST";
export const CITY_LIST = "CITY_LIST";
export const ZIP_CODE_LIST = "ZIP_CODE_LIST";
export const FORM_AUTH_RULE = "FORM_AUTH_RULE";
export const DESIGNATION_LIST = "DESIGNATION_LIST";