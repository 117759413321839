import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faBookOpenReader,
    faCircleInfo,
    faCommentDots,
    faImages,
    faPenToSquare,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import EditProduct from "./EditProduct";
import swal from "sweetalert";
import {faFilePen} from "@fortawesome/free-solid-svg-icons/faFilePen";
import EditProductComponents from "./EditProductComponents";
import UploadSliderImage from "./UploadSliderImage";
import AddBriefDescription from "./AddBriefDescription";
import {
    actionToGetProductComponentListApiCall,
    actionToGetProductTableListApiCall,
    actionToUploadMainImageApiCall
} from "../../../store/action/inventoryActions";
import {actionToGetCompanyListApiCall, callSoftDeleteDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import ProductDetailUpdate from "./ProductDetailUpdate";
import {ProductCurriculumTable} from "./product_curriculum/ProductCurriculumTable";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import AuthorizedComponent from "../../auth/AuthorizedComponent";
import AddCurriculum from "../../Curriculum/curriculum/AddCurriculum";
import CompanyPipeline from "../../WebSetting/website_settings/company/CompanyPipeline";
import {truncateHTMLText} from "../../../store/utility/DatatableUtility";
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";


export const ProductDataTable = ({}) => {
    const [isEditProductOpen, setIsEditProductOpen] = useState(false);
    const [selectedProductRow,setSelectedProductRow] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const dispatch = useDispatch();
    const hiddenFileInput = useRef(null); // ADDED
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const productTableData = useSelector((state) =>  state.inventory?.productTableData);
    const ROLES = useSelector((state) =>  state.webSetting.roleList);

    const [dataTableSetting, setDataTableSetting] = useState({
        "search": "",
        "sortField": "id",
        "sortDirection": "desc",
        "page": 1,
        "pageSize": 10
    });
    const allowedRoles=[ROLES.SuperAdmin,ROLES.Admin,ROLES.WebsiteEditor,ROLES.InventoryManager];
    const actionComponentClassName = allowedRoles?.includes(auth?.role) ?'action-components' : 'action-small-components';
    const [productsData, setProductsData] = useState([]);
    const [advanceFilter,setAdvanceFilter] =useState({source:auth?.source});
    const columns = [
        { name: 'Id', selector: row => row.id, selector_field:'id',
            cell: row => (
                <>
                    {row.id}
                </>
            ), sortable: true,width:'60px'  },
        { name: 'Name', selector: row => row.name, selector_field:'name',
            cell: row => (
                <>
                    {row.name}
                </>
            ), sortable: true ,width:'150px' },
        { name: 'Category', selector: row => row.category_name, selector_field:'category_name',sortable: true,width:'105px'  },
        { name: 'Sub Category', selector: row => row.sub_category_name, selector_field:'sub_category_name',sortable: true,width:'120px'  },
        { name: 'Slug', selector: row => row.slug, selector_field:'slug',sortable: true,width:'150px' },
        { name: 'Long Description', selector: row =>  <div className="truncate-text" title={row.description}>
                {truncateHTMLText(row.long_description, 50)} {/* Adjust maxLength as needed */}
            </div>,
            selector_field:'long_description',sortable: true ,width:'200px'},
        { name: 'Brand', selector: row => row.brand_name, selector_field:'brand_name',sortable: true,width:'100px' },
        { name: 'Quantity', selector: row => row.in_stock_quantity, selector_field:'in_stock_quantity',sortable: true,width:'100px' },
        { name: 'Purchase Price', selector: row => row.purchase_price, selector_field:'purchase_price',sortable: true,width:'100px'},
        { name: 'Sale Price', selector: row => row.sale_price, selector_field:'sale_price',sortable: true,width:'100px' },
        { name: 'Image', selector: row => row.photo, selector_field:'sale_price',sortable: false, cell: row => (
                <>
                    <div className={'test'} onClick={()=>{allowedRoles?.includes(auth?.role) && handlePhotoClick(row)}}>
                        <img src={row?.photo} height="35" width="35" alt={row?.name}/></div>
                </>
            ) },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <div className=''>
                        <AuthorizedComponent allowedRoles={allowedRoles}
                                             AuthComponent={<>

                                                 <div className={actionComponentClassName}>
                                                     {row?.in_website_use ==='1'&& <>
                                                         <div className='action-buttons'>
                                                             <Tippy content='Add Slider Images'>
                                                                 <button className='action-button' onClick={() => {setIsEditProductOpen('add-slider');  setSelectedProductRow(row);}}>
                                                                     <FontAwesomeIcon icon={faImages}/>
                                                                 </button>
                                                             </Tippy>
                                                         </div>

                                                         <div className='action-buttons'>
                                                             <Tippy content='Add Brief Description'>
                                                                 <button className='action-button' onClick={() => {setIsEditProductOpen('add-brief-description'); setSelectedProductRow(row);}}>
                                                                     <FontAwesomeIcon icon={faCommentDots}/>
                                                                 </button></Tippy>
                                                         </div>
                                                         <div className='action-buttons'>
                                                             <Tippy content='Edit Product Detail'>
                                                                 <button className='action-button' onClick={() => {setIsEditProductOpen('edit-product-detail'); setSelectedProductRow(row);}} >
                                                                     <FontAwesomeIcon className='action-icon' icon={faCircleInfo} />
                                                                 </button></Tippy>
                                                         </div></>}

                                                     <div className='action-buttons'>
                                                         <Tippy content='Edit Product'>
                                                             <button className='action-button' onClick={() => {setIsEditProductOpen('edit-product'); setSelectedProductRow(row);}} >
                                                                 <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                                             </button></Tippy>
                                                     </div>
                                                 </div>
                                             </>}
                                             Component={''}/>
                        <div className={actionComponentClassName}>
                            <div className='action-buttons'>
                                <Tippy content='Edit Product Components'>
                                    <button className='action-button' onClick={() => {setIsEditProductOpen('edit-product-component'); setSelectedProductRow(row); dispatch(actionToGetProductComponentListApiCall({product_id:row.id}))}} >
                                        <FontAwesomeIcon className='action-icon' icon={faFilePen} />
                                    </button></Tippy>
                            </div>
                            {row?.in_curriculum_use ==='1' && <>
                                <div className='action-buttons'>
                                    <Tippy content='Product Curriculum Table'>
                                        <button className='action-button' onClick={() => {setIsEditProductOpen('product-curriculum'); setSelectedProductRow(row);}} >
                                            <FontAwesomeIcon className='action-icon' icon={faBookOpenReader} />
                                        </button></Tippy>
                                </div>
                                <div className='action-buttons'>
                                    <Tippy content='Add Product Curriculum'>
                                        <button className='action-button' onClick={() => {setIsEditProductOpen('add-product-curriculum'); setSelectedProductRow(row);}} >
                                            <FontAwesomeIcon className='action-icon' icon={faBookOpen} />
                                        </button></Tippy>
                                </div> </>}
                            <AuthorizedComponent allowedRoles={allowedRoles}
                                                 AuthComponent={<><div className='action-buttons'>
                                                     <Tippy content='Delete Product'>
                                                         <button onClick={()=>deleteProduct(row)} className='action-button'>
                                                             <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                         </button></Tippy>
                                                 </div>
                                                 </>}
                                                 Component={''}/>
                        </div>
                    </div>
                </>
            ),
        },
    ];
    const changeCompany = (val)=>{
        let source = advanceFilter.source;
        if(val.id != source){
            let param = dataTableSetting;
            param['advanceFilter']['source']=val.id;
            setAdvanceFilter(param['advanceFilter']);
            dispatch(actionToGetProductTableListApiCall(param));
        }
    }

    useEffectOnce(()=>{
        let param=dataTableSetting;
        param['advanceFilter']=advanceFilter;
        dispatch(actionToGetProductTableListApiCall(param));
    })
    const handlePhotoClick = (row) => {
        setSelectedProductRow(row);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = event => {
        const fileUploaded = event.target.files[0];
        dispatch(actionToUploadMainImageApiCall({photo:fileUploaded,id:selectedProductRow.id,showOnWebsite:selectedProductRow.company_name,folder:'products',name:selectedProductRow.name,auth_id:auth?.id,eventTypeId:eventTypeId}));
    };

    const deleteProduct = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Product "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'products',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        fetchData();
                    })
                }
            });

    };
    const fetchData = (payload)=>{
        let param =payload ?? dataTableSetting;
        param['advanceFilter']=advanceFilter;
        if(payload){
            payload && setDataTableSetting(payload);
        }
        dispatch(actionToGetProductTableListApiCall(param));
    }
    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(productsData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === productsData.length);
    };
    useEffectOnce(()=>{
        dispatch(actionToGetCompanyListApiCall({in_inventory_use:1}));
    })
    return(
        <>
            <div>
            {
                    <>
                    <CompanyPipeline source={advanceFilter.source} changeCompany={changeCompany}/>
                    <DataTableLayout columns={columns} data={productTableData?.data} fetchData={fetchData} dataTableSetting={dataTableSetting} totalRows={productTableData.total}/>
                    </>
            }
            <input
                type="file"
                onChange={handleMainPhotoUpload}
                ref={hiddenFileInput}
                style={{display: 'none'}} />
        <EditProduct fetchData={fetchData} isOpen={isEditProductOpen ==='edit-product'} selectedProduct={selectedProductRow} onRequestClose={() => {setIsEditProductOpen(false); setSelectedProductRow(false);}} />
        <EditProductComponents fetchData={fetchData} isOpen={isEditProductOpen === 'edit-product-component'} selectedProduct={selectedProductRow} onRequestClose={() => {setIsEditProductOpen(false); setSelectedProductRow(false);}} />
        <AddBriefDescription fetchData={fetchData} isOpen={isEditProductOpen === 'add-brief-description'} selectedProduct={selectedProductRow}  onRequestClose={() =>{ setIsEditProductOpen(false); setSelectedProductRow(false);}}/>
        <ProductDetailUpdate fetchData={fetchData} isOpen={isEditProductOpen === 'edit-product-detail'} selectedProduct={selectedProductRow}  onRequestClose={() =>{ setIsEditProductOpen(false); setSelectedProductRow(false);}}/>
        <ProductCurriculumTable isOpen={isEditProductOpen ==='product-curriculum'} selectedProduct={selectedProductRow}  onRequestClose={() =>{ setIsEditProductOpen(false); setSelectedProductRow(false);}}/>
        <AddCurriculum isOpen={isEditProductOpen === 'add-product-curriculum'} selectedProduct={selectedProductRow}  onRequestClose={() =>{ setIsEditProductOpen(false); setSelectedProductRow(false);}}/>
        <UploadSliderImage isOpen={isEditProductOpen ==='add-slider'}  selectedProduct={selectedProductRow}  onRequestClose={() => {setIsEditProductOpen(false); setSelectedProductRow(false);}} />
         </div>
            </>
    )
}
