import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImages, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import EditProductCurriculum from "./EditProductCurriculum";
import PreLoader from "../../../loader/PreLoader";
import swal from "sweetalert";
import useAuth from "../../../../hooks/useAuth";
import {
    actionToGetProductCurriculumApiCall, uploadFileOnAws
} from "../../../../store/action/inventoryActions";
import {
    callDeleteDataWithLogFunction,
    callInsertDataWithLogFunction,
    callUpdateDataWithLogFunction
} from "../../../../store/action/commonActions";
import ProductCurriculumImage from "./ProductCurriculumImage.js";
import Modal from "react-modal";
import SelectCurriculumDropdown from "../../../Curriculum/curriculum/SelectCurriculumDropdown";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
import {actionToGetCurriculumListApiCall} from "../../../../store/action/curriculumAction";
import BreadCrumb from "../../../Layout/Breadcrumb/BreadCrumb";
import {toast} from "react-toastify";

export const ProductCurriculumTable = ({isOpen,selectedProduct,onRequestClose}) => {
    const [isEditCurriculumOpen, setIsEditCurriculumOpen] = useState(false);
    const [isProductCurriculumImagesOpen, setIsProductCurriculumImagesOpen] = useState(false);
    const dispatch = useDispatch();
    const productCurriculumData = useSelector((state) =>  state.inventory?.productCurriculumData);
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const hiddenFileInput = useRef(null);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const {auth} = useAuth();
    useEffect(() => {
        (selectedProduct?.id && isOpen) &&
        dispatch(actionToGetProductCurriculumApiCall({productId:selectedProduct.id}));
    }, [selectedProduct]);
    const deleteCurriculum = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this curriculum "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'product_curriculum',id:row.product_curriculum_id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetProductCurriculumApiCall({productId:selectedProduct.id}));
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(productCurriculumData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === productCurriculumData.length);
    };
    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED

    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        let data = productCurriculumData[selectedIndex];
        let name = data?.name.replace(/\s+/g, '-').toLowerCase();
        let filename = `products/${name}-${data.id}.png`;
        let deletedFileName =  data.photo?.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/','');
        let url = await dispatch(uploadFileOnAws({filename:filename,deletedFilename:deletedFileName,photo:fileUploaded,showOnWebsite:selectedProduct.company_name,type:'image/png'}));
        dispatch(callUpdateDataWithLogFunction({tableName:'curriculum',data: {photo:url+'?v='+Math.floor(1000 + Math.random() * 9000)},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetProductCurriculumApiCall({productId:selectedProduct.id}));
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = await validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(callInsertDataWithLogFunction({
                tableName: 'product_curriculum',
                data: { curriculum_id: obj['curriculum_id'], product_id: selectedProduct.id },
                userId: auth?.id,
                eventTypeId: eventTypeId
            })).then(res => {
                let status = res?.success === 1 ? 'success' : 'fail';

                if (status === 'success') {
                    dispatch(actionToGetProductCurriculumApiCall({ productId: selectedProduct.id }));
                    resetForm();
                    toast.success('Product curriculum added successfully!'); // Show success toast
                } else {
                    toast.error('Failed to add product curriculum.'); // Show error toast
                }
            });
        } else {
            toast.error('Form submission failed due to validation errors.'); // Show validation error toast
        }
    };
    const resetForm = () => {
        document.getElementById('curriculum-file')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.curriculum_id.trim()) {
            errors.curriculum_id = 'Curriculum is required';
        }

        return errors;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    useEffect(() => {
        setErrors({})
        setFormData({})
    }, [isOpen]);
    useEffectOnce(()=>{
        dispatch(actionToGetCurriculumListApiCall());
    })
    return(
        <>
            <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='description-modal'>
            {
                productCurriculumData === 'loading' ?
                    <PreLoader/>
                    :
                    <>
                    <div className='container'>
                        <BreadCrumb first_crumb={'Product Curriculum Table'} second_crumb={selectedProduct?.name}/>
                        <form onSubmit={handleSubmit} id={'curriculum-file'}>

                        <div className='grade-container'  >
                            <SelectCurriculumDropdown errors={errors} name={'curriculum_id'} curriculum_id={formData.curriculum_id} handleChange={handleChange}/>
                        </div>
                        <div className='buttons'>
                            <div className='button'>
                                <button className='add-curriculum-button' type={"submit"}>Add Curriculum</button>
                            </div>
                            <div className='button'>
                                <button className='close-button' onClick={onRequestClose}>Close</button>
                            </div>
                        </div>
                        </form>
                    </div>
                        <div className="table">
                            <div className="curriculum-row-header">
                                <div className="six-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectAll}
                                           onChange={handleSelectAll}/>
                                    Curriculum
                                </div>
                                <div className="six-column-cell">Slug</div>
                                <div className="six-column-cell">Focus</div>
                                <div className="six-column-cell">Description</div>
                                <div className="six-column-cell">Image</div>
                                <div className="six-column-cell">Action</div>
                            </div>
                            <Virtuoso style={{ height: '600px' }} totalCount={productCurriculumData.length} itemContent={index =>
                                <div className="table-row">
                                    <div className="six-column-cell">
                                        <input className='checkbox' type="checkbox"
                                               checked={selectedRows.includes(productCurriculumData[index]?.id)}
                                               onChange={() => handleSelectRow(productCurriculumData[index]?.id)}/>
                                        {productCurriculumData[index]?.name}
                                    </div>
                                    <div className="six-column-cell">{productCurriculumData[index]?.slug}</div>
                                    <div className="six-column-cell">{productCurriculumData[index]?.focus}</div>
                                    <div className="six-column-cell">{productCurriculumData[index]?.description}</div>
                                    <div className="six-column-cell" onClick={()=>handlePhotoClick(index)}>
                                        <img src={productCurriculumData[index]?.photo} height="35" width="35" alt={productCurriculumData[index]?.name}/></div>
                                    <div className="six-column-cell">
                                        <div className='action-components'>
                                            <div className='action-buttons'>
                                                <button className='action-button' onClick={() => {setIsEditCurriculumOpen(true); setSelectedIndex(index);}} >
                                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                                </button>
                                            </div>
                                            <div className='action-buttons'>
                                                <button className='action-button' onClick={() => {setIsProductCurriculumImagesOpen(true);  setSelectedIndex(index);}}>
                                                    <FontAwesomeIcon icon={faImages}/>
                                                </button>
                                            </div>
                                            <div className='action-buttons'>
                                                <button onClick={()=>deleteCurriculum(productCurriculumData[index])} className='action-button'>
                                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            } />
                        </div>
                    </>

            }
            <input
                type="file"
                onChange={handleMainPhotoUpload}
                ref={hiddenFileInput}
                style={{display: 'none'}} />
            <ProductCurriculumImage isOpen={isProductCurriculumImagesOpen}  selectedProduct={selectedProduct} selectedProductCurriculum={productCurriculumData[selectedIndex]}   onRequestClose={() => {setIsProductCurriculumImagesOpen(false); setSelectedIndex(false);}} />
            <EditProductCurriculum isOpen={isEditCurriculumOpen} selectedProductCurriculum={productCurriculumData[selectedIndex]} onRequestClose={() => {setIsEditCurriculumOpen(false); setSelectedIndex(false);}} />
            </Modal>
            </>
    )
}
