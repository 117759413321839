import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';  // Import Toastify
import 'react-toastify/dist/ReactToastify.css';  // Import Toastify CSS
import { callDeleteDataWithLogFunction, callInsertDataWithLogFunction } from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import { actionToGetRegionsApiCall } from "../../../store/action/salesAction";

const AddRegionState = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { auth } = useAuth();
    const [errors, setErrors] = useState({});
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);
    const stateListData = useSelector((state) => state.common.stateList);
    const [stateDropdown, setStateDropdown] = useState([]);
    const selectedData = props.selectedData;
    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = await validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(callInsertDataWithLogFunction({
                tableName: 'state_region',
                data: { region_id: selectedData.id, state_id: obj['state_id'] },
                userId: auth?.id,
                eventTypeId: eventTypeId
            })).then(() => {
                dispatch(actionToGetRegionsApiCall());
                toast.success("State added successfully!");
                resetForm();
            }).catch((error) => {
                toast.error("Failed to add state. Please try again.");
            });
        } else {
            toast.error("Please fill all required fields.");
        }
    };

    const resetForm = () => {
        document.getElementById('region-state')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.state_id.trim()) {
            errors.state_id = 'State is required';
        }
        return errors;
    };

    const deleteRegionState = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this subject topic ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (success) => {
            if (success) {
                dispatch(callDeleteDataWithLogFunction({ tableName: 'state_region', id: id, userId: auth?.id, eventTypeId }))
                    .then(() => {
                        dispatch(actionToGetRegionsApiCall());
                        toast.success("State deleted successfully!");  // Success notification for delete
                    })
                    .catch(() => {
                        toast.error("Failed to delete state.");  // Error notification for delete
                    });
            }
        });
    };

    useEffect(() => {
        let stateArray = [];
        setLoading(true);
        Object.values(stateListData)?.map(val => {
            stateArray.push({ value: val.id, label: val.name });
        });
        setStateDropdown(stateArray);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [stateListData, selectedData]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: '50px',
            width: '95%',
            borderRadius: '5px',
            border: state.isFocused ? '1px solid #efdda8' : '1px solid #ccc',
            paddingLeft: '5px',
            fontSize: '13px',
            boxShadow: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
                borderColor: '#efdda8'
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            width: '95%',
            marginTop: '2px',
            zIndex: 2,
            overflowY: 'auto',
            maxHeight: '150px',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#ccc',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#999'
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
            backgroundColor: state.isFocused ? '#efdda8' : '#fff',
            color: '#000',
            '&:hover': {
                backgroundColor: '#efdda8',
                color: '#000'
            }
        })
    };

    return (
        <div>

            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='region-state-modal'>
                {loading ? <div className='flex justify-center text-center m-40'>Loading ...</div> :
                    <div className='container'>
                        <div className='title'>Add State to Region</div>
                        <form id={'region-state'} onSubmit={handleSubmit}>
                            <div className='input-box m-4'>
                                <Select name={"state_id"}
                                        defaultValue={formData?.state_id}
                                        value={formData?.state_id}
                                        onChange={(val) => handleChange({ target: { name: 'state_id', value: val } })}
                                        options={stateDropdown} styles={customStyles} placeholder='Select State'
                                />
                                {errors.state_id && <span className="error-message"> {errors.state_id}</span>}
                            </div>
                            <div className='flex justify-center m-4'>
                                <button className='submit-button' type={"submit"}>Add State</button>
                                <button className='submit-button' onClick={props.onRequestClose}>Close</button>
                            </div>
                        </form>
                        <table className="image-table">
                            <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>State Name</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedData?.state_list?.map((state, index) => (
                                <tr key={state.id}>
                                    <td>{index + 1}</td>
                                    <td>{state.state_name}</td>
                                    <td><FontAwesomeIcon className='action-icon' icon={faTrash}
                                                         onClick={() => deleteRegionState(state.id)} /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                }
            </Modal>
        </div>
    );
};

export default AddRegionState;
