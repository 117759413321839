// Function to strip HTML tags and apply truncation
export const truncateHTMLText = (htmlString, maxLength) => {
    if(htmlString){
        const strippedString = htmlString.replace(/<\/?[^>]+(>|$)/g, ""); // Strip HTML tags
        if (strippedString.length > maxLength) {
            return strippedString.substring(0, maxLength) + '...';
        }
        return strippedString;
    }else{
        return '';
    }
};
export const indianDateFormat = (date) => {
    return new Intl.DateTimeFormat('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).format(new Date(date));
};
export const indianDateTimeFormat = (date) => {
    return new Intl.DateTimeFormat('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    }).format(new Date(date));
};
export const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
};
