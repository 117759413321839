// components/SchoolForm.js
import React, {useEffect} from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import DateField from "../../Layout/Form/DateField";
import SelectFollowUpTypeDropdown from "./SelectFollowUpTypeDropdown";
import SelectContactPersonDropdown from "../contact-person/SelectContactPersonDropdown";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import {actionToGetClientLeadApiCall, actionToGetContactPersonApiCall} from "../../../store/action/salesAction";

const SchoolClientLeadInteractionForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const validationRules = {
        notes: { required: true, minLength: 3 },
        current_follow_up_type_id: { required: true,field_name:'Current Followup Type' },
    };
    const onSuccess = (res)=>{
        handleExternalFetch?.(res,['lead-interaction']);
        onRequestClose();
    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );
    useEffect(() => {
        if(formData.school_id){
            dispatch(actionToGetContactPersonApiCall({school_id:formData.school_id}));
            dispatch(actionToGetClientLeadApiCall({school_id:formData.school_id}));
        }
    }, [formData.school_id]);

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='region-state-modal'>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Client Conversation' : 'Add Client Conversation'} second_crumb={formData.name}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'school_client_lead_interaction',{created_by:auth?.id},[])}>
                    {!isEditMode &&<input type={"hidden"} name={"school_client_lead_id"} value={selectedData?.id} />}
                    <div className='vendor-details'>
                    <SelectContactPersonDropdown placeholder='Select/Add Contacted Person' school_contact_person_id={formData.school_contact_person_id} name={'school_contact_person_id'} school_id={selectedData?.school_id} handleChange={handleChange} />
                    <InputField
                        label="Detailed Remark*"
                        type="text"
                        name="notes"
                        value={formData.notes || ''}
                        onChange={handleChange}
                        error={errors.notes} placeholder='Enter Remarks'
                    />
                    <SelectFollowUpTypeDropdown placeholder='Select Current Follow Up Type' label={'Select Current Follow Type'} errors={errors} name="current_follow_up_type_id" follow_up_type_id={formData.current_follow_up_type_id} handleChange={handleChange}  />
                        <SelectFollowUpTypeDropdown placeholder='Select Next Follow Up Type' label={'Select Next Follow Type'} errors={errors} name="next_follow_up_type_id" follow_up_type_id={formData.next_follow_up_type_id} handleChange={handleChange}  />
                        <DateField label="Interaction Date"
                                   name={'interaction_date'}
                                   value={formData.interaction_date || ''}
                                   onChange={handleChange}
                                   error={errors.interaction_date} placeholder='Interaction Date' />
                    <DateField label="Next Follow Up date"
                               name={'next_follow_up_date'}
                               minDate={new Date()}
                               value={formData.next_follow_up_date || ''}
                               onChange={handleChange}
                               error={errors.next_follow_up_date} placeholder='Next Follow up Date' className={'custom-datepicker'} />
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default SchoolClientLeadInteractionForm;
