import React from 'react';
import Modal from 'react-modal';
import GemOrderTable from "../../inventory/offline-order/GemOrderTable";

const GemTableModal = ({ isOpen, onRequestClose,selectedClientLead }) => {

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='medium-table-modal'>
            <div className='m-5'>
                <GemOrderTable selectedClientLead={selectedClientLead} />
            </div>
            <div className='flex justify-center'>
                <button className='submit-button' onClick={onRequestClose}>Close</button>
            </div>
        </Modal>
    );
};

export default GemTableModal;
