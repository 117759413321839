import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFolderPlus, faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {Link, useParams} from "react-router-dom";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useDispatch, useSelector} from "react-redux";
import {subDomainUrl} from "../../hooks/config";
import {actionToGetGalleryListApiCall} from "../../store/action/galleryActions";
import AddFolders from "./folder/AddFolders";
import FileTree from "./folder/FileTree";
import useAuth from "../../hooks/useAuth";



const GalleryComponent = React.memo(function () {
    const [addModalName, setAddModalName] = useState(false);
    const [folderTree, setFolderTree] = useState(false);
    const dispatch = useDispatch();
    const galleryListData = useSelector((state) =>  state.gallery?.galleryListData);
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const {auth} = useAuth();
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const arrayToTree =(items) => {
        const rootItems = [];
        const lookup = {};

        for (const item of items) {

            const newItem = { ...item, children: [] };

            // Initialize the lookup item if it doesn't exist
            if (!lookup[item.id]) {
                newItem['level']=1;
                lookup[item.id] = newItem;
            }

            if (item.parent_id) {
                newItem['level']=lookup[item.parent_id]['level']+1;
                lookup[item.parent_id].children.push(newItem);
            } else {
                // If it has no parent, it's a root item
                rootItems.push(lookup[item.id]);
            }
        }
        setFolderTree(rootItems);
        return rootItems;
    }



    useEffectOnce(()=>{
       let payload = !allowedRoles?.includes(auth?.role) ? {user_id:auth?.id}:{};
        dispatch(actionToGetGalleryListApiCall(payload));
    })
    useEffect(()=>{
         arrayToTree(galleryListData);
    },[galleryListData])
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add Folder'>
                                <button  className='common-button' onClick={() => setAddModalName('add_folder')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faFolderPlus} />
                                </button>
                            </Tippy>

                            <AddFolders isOpen={addModalName === 'add_folder'} onRequestClose={() => setAddModalName(false)} />
                        </div>

                    </div>
                </div>
                <FileTree treeData={folderTree} />
            </section>
        </>
    )
});

export default GalleryComponent;
