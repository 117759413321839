import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelectField from "../../Layout/Form/CreatableSelectField";
import {makeApiCall} from "../../../hooks/api/makeApiCall";
import TransporterForm from "./TransporterForm";
import {TRANSPORTER_DROPDOWN_LIST_DATA} from "../../../store/constant/inventoryConstant";

const SelectTransporterDropdown = ({transporter_id,name,handleChange,placeholder,error}) =>{
    const dispatch=useDispatch();
    const transporterDropdownListData = useSelector((state) =>  state.inventory?.transporterDropdownListData);
    const [isEditTransporterOpen, setIsEditTransporterOpen] = useState(false);
    const [selectedTransporter, setSelectedTransporter] = useState(null);
    const [dataDropdown, setDataDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState(null);

    useEffect(() => {
        let componentArray = [];
        let selectedData = null;
        transporterDropdownListData !== 'loading'  && transporterDropdownListData?.map(val=>{
                componentArray.push({value:val.id,label:val.name});
                if(transporter_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setDataDropdown([...componentArray]);
    }, [transporterDropdownListData,transporter_id]);
    const handleCreate = (val) => {
        setSelectedTransporter({name:val});
        setIsEditTransporterOpen('add_transporter');
    };
    const handleFetchData = (res)=>{
        makeApiCall(`inventory/get-transporter-dropdown`,TRANSPORTER_DROPDOWN_LIST_DATA,dispatch).then(response=>{
            handleChange({target:{name:name,value:res.id}});
            }
        );
    }
    const customStyles = {controlHeight:'50px', menuListMaxHeight:'150px'}


    return(<>
        <CreatableSelectField name={name} label={placeholder} customStyles={customStyles} placeholder={placeholder} error={error} dataDropdown={dataDropdown}
                                  handleCreate={handleCreate} selectedDropDown={selectedDropDown} onChange={handleChange}/>
        {isEditTransporterOpen === 'add_transporter' && (
            <TransporterForm
                handleExternalFetch={handleFetchData}
                isOpen={isEditTransporterOpen==='add_transporter'}
                onRequestClose={() => setIsEditTransporterOpen(false)}
                selectedData={selectedTransporter}
                isEditMode={false}
                type={"transporter"}
            />
        )}
    </>
    )
}
export default SelectTransporterDropdown;
