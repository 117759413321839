// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import SelectDesignationDropdown from "./SelectDesignationDropdown";
import TextArea from "../../Layout/Form/TextArea";
import {actionToGetContactPersonApiCall} from "../../../store/action/salesAction";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import SelectBusinessTypeDropdown from "./SelectBusinessTypeDropdown";
import SelectSchoolDropdown from "../school/SelectSchoolDropdown";
import {PinCodeText} from "../region/PinCodeText";
import SelectStateDropdown from "../region/SelectStateDropdown";
import SelectCityDropdown from "../region/SelectCityDropdown";

const SchoolContactPersonForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const validationRules = {
        name: { required: true, minLength: 3, maxLength: 50 },
        phone: { required: true, type: 'phone' },
    };
    const onSuccess = (res)=>{
        const schoolId = !isEditMode ? selectedData?.id :formData?.school_id;
        if(schoolId){
            dispatch(actionToGetContactPersonApiCall({school_id:schoolId}));
        }
        onRequestClose();
        handleExternalFetch?.(res,['contact-person']);

    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged,updatePinCode } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );

    const getBusinessTypeHtml = (business_type_id)=>{
        let html = '';
        if(!isEditMode){
            if(selectedData?.school_id){
                html = <>
                    <input type={"hidden"} name={"school_id"} value={selectedData?.school_id}/>
                    <input type={"hidden"} name={"business_type_id"} value={1}/>
                </>;
            }else if(business_type_id === 1){
                html = <><SelectBusinessTypeDropdown errors={errors} name="business_type_id" business_type_id={formData.business_type_id} handleChange={handleChange} />
                    <SelectSchoolDropdown placeholder='Select School' name={'school_id'} school_id={formData?.school_id} handleChange={handleChange} /></>
            }else{
                html = <>
                    <SelectBusinessTypeDropdown errors={errors} name="business_type_id" business_type_id={formData.business_type_id} handleChange={handleChange} />
                </>
            }
        }
        return html;
    }
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit School Contact Person' : 'Add School Contact Person'} second_crumb={selectedData?.school_name}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'school_contact_person',{created_by:auth?.id},[])}>
                    <div className='vendor-details'>
                        {getBusinessTypeHtml(formData.business_type_id)}
                        {isEditMode && <input type={"hidden"} value={selectedData?.address_id} name={"address_id"} />}
                    <InputField
                        label="Contact Person Name"
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleChange}
                        error={errors.name} placeholder='Enter Contact Person Name'
                    />
                    <SelectDesignationDropdown errors={errors} name="designation_id" designation_id={formData.designation_id} handleChange={handleChange}  />
                    <InputField
                        label="Phone"
                        type="text"
                        name="phone"
                        value={formData.phone || ''}
                        onChange={handleChange}
                        error={errors.phone} placeholder='Enter Phone Number'
                    />
                    <InputField
                        label="Phone2"
                        type="text"
                        name="phone2"
                        value={formData.phone2 || ''}
                        onChange={handleChange}
                        error={errors.phone2} placeholder='Enter Second Phone Number'
                    />
                        {(!selectedData?.school_id && formData?.business_type_id !== 1 && !isEditMode)&&<>
                            <InputField
                                label="Email"
                                type="email"
                                name="email"
                                value={formData.email || ''}
                                onChange={handleChange}
                                error={errors.email} placeholder='Enter Email'
                            />
                            <TextArea
                                className='text-area'
                                name="address"
                                label="Address"
                                value={formData.address || ''}
                                onChange={handleChange} placeholder='Enter Address'
                            />
                            <PinCodeText errors={errors} name="pincode" pin_code={formData.pincode} updatePinCode={updatePinCode} />
                            <SelectStateDropdown errors={errors} name="state_id" state_id={formData.state_id} handleChange={handleChange} />
                            <SelectCityDropdown errors={errors} name="city_id" state_id={formData.state_id} city_id={formData.city_id} handleChange={handleChange} />
                        </>

                        }

                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default SchoolContactPersonForm;
