// components/SchoolTable.js
import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from '../../../../hooks/useFetchData';
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from '../../../../store/action/commonActions';
import DataTableLayout from "../../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../../Layout/DataTable/TableEditActions";
import {truncateHTMLText} from "../../../../store/utility/DatatableUtility";
import useAuth from "../../../../hooks/useAuth";
import TableDeleteActions from "../../../Layout/DataTable/TableDeleteActions";
import BlogPostForm from "./BlogPostForm";
import {uploadFileOnAws} from "../../../../store/action/inventoryActions";
const BlogPostTable = ({setFetchDataTable,fetchDataTable}) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const ROLES = useSelector((state) => state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);
    const hiddenFileInput = useRef(null);
    const { data:schoolData, fetchData,settings } = useFetchData(`web-setting/get-blog-list`, []);
    const [isSchoolModalOpen, setIsSchoolModalOpen] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);

    const columns = [
        { name: 'Sr No', selector: row => row.id, selector_field:'id',
            cell: (row,index) => (
                <>
                    {index+1}
                </>
            ),width:'60px'  },
        { name: 'Title', selector: row => row.title, selector_field:'title',
            cell: row => (
                <>
                    {row.title}
                </>
            ), sortable: true ,width:'150px' },
        { name: 'Slug', selector: row => row.slug, selector_field:'slug',sortable: true,width:'250px'  },
        { name: 'Content', selector: row =>  <div className="truncate-text" title={row.content}>
                {truncateHTMLText(row.content, 50)} {/* Adjust maxLength as needed */}
            </div>,
            selector_field:'content',sortable: true ,width:'200px'},
        { name: 'Creator', selector: row => row.creator, selector_field:'creator',sortable: true,width:'100px' },
        { name: 'Image', selector: row => row.photo, selector_field:'sale_price',sortable: false, width:'150px',cell: row => (
                <>
                    <div className={'test'} onClick={()=>{allowedRoles?.includes(auth?.role) && handlePhotoClick(row)}}>
                        <img src={row?.photo} height="35" width="35" alt={row?.name}/></div>
                </>
            ) },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    <TableEditActions
                        tooltip={'Edit School'}
                        row={row}
                        onEdit={() => { setSelectedSchool(row); setIsSchoolModalOpen('edit'); }}
                    />
                    <TableDeleteActions
                        auth_key="school_delete"
                        tooltip="Delete School"
                        row={row}
                        onDelete={(row) => dispatch(callSoftDeleteDataWithLogFunction({ tableName: 'schools', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                </div>
            ), width: '150px' }
    ];
    const handlePhotoClick = (row) => {
        setSelectedSchool(row);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        let name = selectedSchool?.title.replace(/\s+/g, '-').toLowerCase();
        let filename = `blogpost/${name}-${selectedSchool.id}.png`;
        let deletedFileName =  selectedSchool.photo?.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','');
        let url = await dispatch(uploadFileOnAws({filename:filename,deletedFilename:deletedFileName,photo:fileUploaded,type:'image/png',showOnWebsite:'stemcity'}));
        dispatch(callUpdateDataWithLogFunction({tableName:'blog_posts',data: {photo:url+'?v='+Math.floor(1000 + Math.random() * 9000)},id:selectedSchool?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            fetchData()
        })
    }

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);
    return (
        <div className='table-container'>
            <DataTableLayout columns={columns} data={schoolData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={schoolData?.total} />
            <input
                type="file"
                onChange={handleMainPhotoUpload}
                ref={hiddenFileInput}
                style={{display: 'none'}} />
            {isSchoolModalOpen === 'edit' && (
                <BlogPostForm
                    handleExternalFetch={fetchData}
                    isOpen={isSchoolModalOpen=== 'edit'}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                    selectedData={selectedSchool}
                    isEditMode={true}
                />
            )}
        </div>
    );
};

export default BlogPostTable;
