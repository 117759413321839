import Modal from 'react-modal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextArea from "../../Layout/Form/TextArea";
import OrderComponentCustomization from "./OrderComponentCustomization";


const OrderItemPrintList = ({ formData, handleChange, disabled}) => {
    return (
            <div className="container max-h-[90vh] overflow-y-auto rounded-lg w-[90%]" id='printable-form'>
                <div className='vendor-details'>
                    <div>
                        {formData.description}
                    </div>
                    <table className='products-table'>
                        <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {formData?.packing_list.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    {index + 1}
                                </td>
                                <td>{item.product_name}</td>

                                <td className='w-28'>
                                    {item.quantity}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
    );
};
export default OrderItemPrintList;
