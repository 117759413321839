import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {
    actionToGetDiscountCouponForProductApiCall
} from "../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import SelectUserDropdown from "../../users/users/SelectUserDropdown";
import DatePicker from "react-datepicker";


const EditDiscount= ({ isOpen,selectedData, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const minDate = useState(new Date());
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [formData, setFormData] = useState({
        name: ''
    });

    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedData));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());

        // Validate form data
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const res = await dispatch(callUpdateDataWithLogFunction({
                    tableName: 'discount_coupon',
                    data: obj,
                    id: selectedData?.id,
                    userId: auth?.id,
                    eventTypeId
                }));

                const status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                // Notify user based on status
                if (status === 'success') {
                    toast.success('Discount coupon updated successfully!');
                    dispatch(actionToGetDiscountCouponForProductApiCall());
                    onRequestClose();
                } else {
                    toast.error('Failed to update discount coupon. Please try again.');
                }
            } catch (error) {
                console.error('Error during coupon update:', error);
                setSaveStatus('fail');
                toast.error('An error occurred while updating the discount coupon.');
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.');
        }
    };

    useEffect(() => {
        setFormData(selectedData);
    }, [selectedData]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Discount Name is required';
        }
        if (!data.type.trim()) {
            errors.type = 'Type is required';
        }
        if (!data.amount_type.trim()) {
            errors.amount_type = 'Amount Type is required';
        }
        if (data.amount_type=== 'percentage' && !data.discount_percentage.trim()) {
            errors.discount_percentage = 'Discount Percentage is required';
        }
        if (!data.maximum_discount.trim()) {
            errors.maximum_discount = 'Maximum Discount is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Edit Discount Coupon</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <span className='details'>Discount Name</span>
                            <input type='text' placeholder='Discount Name*'
                                   name='name' className='form-input'
                                   value={formData?.name}
                                   onChange={handleChange}/>
                            {errors?.name && <span className="error-message">{errors.name} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Type</span>
                            <select className='select' name='type' value={formData?.type}>
                                <option value="coupon" >Coupon</option>
                                <option value="discount">Discount</option>
                            </select>
                            {errors.type && <span className="error-message"> {errors.type}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Amount Type</span>
                            <select className='select' name='amount_type' value={formData?.amount_type}>
                                <option value="percentage" >Percentage</option>
                                <option value="amount">Amount</option>
                            </select>
                            {errors.amount_type && <span className="error-message"> {errors.amount_type}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Discount Percentage</span>
                            <input type='number'  name='discount_percentage' value={formData?.discount_percentage}/>
                            {errors.discount_percentage && <span className="error-message"> {errors.discount_percentage}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Maximum Discount</span>
                            <input type='number'  name='maximum_discount'  value={formData?.maximum_discount}/>
                            {errors.maximum_discount && <span className="error-message"> {errors.maximum_discount}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Message</span>
                            <textarea className='text-area' name='message' value={formData?.message}/>
                            {errors.message && <span className="error-message">{errors.message} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Associated Users</span>
                            <SelectUserDropdown name={'associated_with'}  errors={errors} user_id={formData?.associated_with} handleChange={handleChange}/>

                            {errors?.associated_with && <span className="error-message">{errors.associated_with} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Expired On</span>
                            <DatePicker
                                showIcon
                                name={'expired_on'}
                                minDate={minDate}
                                dateFormat="yyyy-MM-dd"
                                selected={formData?.expired_on}
                                onChange={(date) => handleChange({target:{name:'expired_on',value:date}})}
                            />
                            {errors.expired_on && <span className="error-message">{errors.expired_on} </span> }
                        </div>

                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'  disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Discount Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};


export default EditDiscount;
