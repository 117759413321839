import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {
    actionToDeleteAwsObject,
    actionToDeleteStemcityAwsObject,
    callDeleteDataWithLogFunction
} from "../../../store/action/commonActions";
import {
    actionToGetProductImagesApiCall,
    actionToUploadImageSliderApiCall
} from "../../../store/action/inventoryActions";
import useAuth from "../../../hooks/useAuth";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";

const UploadSliderImage = (props) => {
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true);
    const productImageData = useSelector((state) =>  state.inventory?.productImageData);
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const selectedProduct = props.selectedProduct;

    const handleImageUpload = (event) => {
        const files = event.target.files;

        let payload = {productId: selectedProduct.id,
        name:selectedProduct.name, auth_id:auth?.id,eventTypeId,
            showOnWebsite:selectedProduct.company_name,
            newImages:Array.from(files)};
        dispatch(actionToUploadImageSliderApiCall(payload))
    };

    const deleteProductPhoto = (id,imgUrl) =>{
        swal({
            title: "Are you sure?",
            text: "You want to delete this product photo ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'product_photos',id:id,userId:auth?.id,eventTypeId})).then(() => {
                        if(selectedProduct.company_name.toLowerCase() === 'stemcity'){
                            let imageUrl = imgUrl.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','');
                            dispatch(actionToDeleteStemcityAwsObject({key:imageUrl}))
                        }else{
                            let imageUrl = imgUrl.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/','');
                            dispatch(actionToDeleteAwsObject({key:imageUrl}))
                        }

                        dispatch(actionToGetProductImagesApiCall({productId:selectedProduct.id}))
                    })
                }
            });

    }
    useEffect(() => {
        setLoading(true);
       (selectedProduct?.id && props.isOpen) &&
        dispatch(actionToGetProductImagesApiCall({productId:selectedProduct.id})).then(()=>{
            setLoading(false);
        });
    }, [selectedProduct]);
    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='Modal'>
                {loading ? <>Loading ...</> :
                <div className='container'>
                    <BreadCrumb first_crumb={'Upload Slider Images'} second_crumb={selectedProduct.name}/>
                    <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="upload-input"
                    />
                    <table className="image-table">
                        <thead>
                        <tr>
                            <th>Sr No</th>
                            <th>Image</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {productImageData?.map((image,index) => (
                            <tr key={image.id}>
                                <td>{index+1}</td>
                                <td><img src={image.url} alt={`Uploaded ${selectedProduct?.name} ${index}`} className="uploaded-image" /></td>
                                <td>
                                    <div className='action-components'>
                                        <div className='action-button'>
                                            <FontAwesomeIcon className='action-icon' icon={faTrash} onClick={()=> deleteProductPhoto(image.id,image.url)} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='close-button' onClick={props.onRequestClose}>Close</button>
                        </div>
                    </div>
                </div>}
            </Modal>
        </div>
    );
};

export default UploadSliderImage;
