import React from 'react';
import Modal from 'react-modal';
import { useSelector } from "react-redux";


const OrderItemModal = ({ isOpen, onRequestClose }) => {
    const orderItemData = useSelector((state) => state.inventory?.orderDetailData && state.inventory?.orderDetailData[0]?.orderItemData);
    const handlePrint = () => {
        const printContent = document.getElementById('printable-table').innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='description-modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Ordered Items List</div>
            </div>
            <div id="printable-table" className='m-10'>
                <table className='products-table'>
                    <thead>
                    <tr>
                        <th className='w-20'>Sr. No</th>
                        <th className='w-96'>Item Name</th>
                        <th className='w-96'>Item Photo</th>
                        <th className='w-20'>Quantity</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderItemData?.map((items, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{items.product_name}</td>
                            <td><img alt="item" src={items.product_photo} height="40px" className="uploaded-image" /></td>
                            <td>{items.quantity}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className='flex justify-around'>
                <button className='submit-button' onClick={handlePrint}>Print</button>
                <button className='submit-button' onClick={onRequestClose}>Close</button>
            </div>
        </Modal>
    );
};

export default OrderItemModal;
