import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import SalesComponent from "../Components/Sales/SalesComponent";
export default function SalesPage() {
    return (
        <>
            <Routes>
                <Route path="" exact element={<Navigate to="lead-interaction" replace />} />
                <Route path="/:tabName" element={<SalesComponent />} />
            </Routes>
        </>
    )
}