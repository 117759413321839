import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {useDispatch, useSelector} from "react-redux";
import {actionToGetCategoriesApiCall, actionToGetSubCategoriesApiCall} from "../../../store/action/inventoryActions";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import CategoryDropdown from "../category/CategoryDropdown";
import SelectCompanyDropdown from "../../auth/SelectCompanyDropdown";
import {toast} from "react-toastify";


const AddSubCategory = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const dispatch = useDispatch();
    const [saveStatus, setSaveStatus] = useState({});
    const {auth} = useAuth();
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({ source:auth?.source });
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            obj['created_by'] = auth?.id;
            delete obj['source'];

            dispatch(callInsertDataWithLogFunction({
                tableName: 'sub_categories',
                data: obj,
                userId: auth?.id,
                eventTypeId
            })).then(res => {
                let status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                if (status === 'success') {
                    dispatch(actionToGetSubCategoriesApiCall());
                    resetForm();
                    toast.success('Sub-category added successfully!'); // Success toast
                } else {
                    toast.error('Failed to add sub-category.'); // Error toast
                }
            });
        } else {
            setSaveStatus('fail');
            toast.error('Form submission failed due to validation errors.'); // Validation error toast
        }
    };
    const resetForm = () => {
        document.getElementById('sub-category-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Sub Category is required';
        }

         if (!data.slug.trim()) {
             errors.slug = 'Slug is required';
         }

         if (!data.category_id.trim()) {
             errors.category_id = 'Main Category is required';
         }

        return errors;

    };
    useEffectOnce(()=>{
        dispatch(actionToGetCategoriesApiCall());
    })
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Sub-Category</div>
                <form action='#' onSubmit={handleSubmit} id='sub-category-form' >
                    <div className='vendor-details'>
                        <SelectCompanyDropdown allowedRoles={allowedRoles} source={auth.source} />
                        <div className='input-box'>
                            <span className='details'>Sub-Category Name</span>
                            <input type='text' placeholder='Sub-Category Name*'
                                   name='name' className='form-input' onChange={handleChange}/>
                            {errors.name &&<span className="error-message">{errors.name} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Slug</span>
                            <input type='text' name='slug' className='form-input'
                                   placeholder='Slug' readOnly={true} value={formData?.name?.toLowerCase()?.split(' ').join('-')}/>
                            {errors.slug &&<span className="error-message">{errors.slug}</span>}
                        </div>

                        <CategoryDropdown errors={errors} name={'category_id'} source={formData?.source} category={formData?.category_id} handleChange={handleChange}/>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Sub Category Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};

export default AddSubCategory;
