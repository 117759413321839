import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faCode, faSignsPost} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {Link, Outlet, useParams} from "react-router-dom";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useDispatch} from "react-redux";
import {faSearchengin} from "@fortawesome/free-brands-svg-icons";
import AddSeoSetting from "./website_settings/seo_setting/AddSeoSetting";
import {subDomainUrl} from "../../hooks/config";
import {
    actionToGetComponentListApiCall,
    actionToGetMenuListApiCall,
    actionToGetWebSettingSeoReferenceHtmlApiCall
} from "../../store/action/webSettingActions";
import AddMenu from "./website_settings/menu/AddMenu";
import AddWebsiteSection from "./website_settings/section_content/AddWebsiteSection";
import {actionToGetCompanyListApiCall} from "../../store/action/commonActions";
import {actionToGetCurriculumListApiCall} from "../../store/action/curriculumAction";
import BlogPostForm from "./website_settings/posts/BlogPostForm";
import {actionToGetFormAuthRuleApiCall} from "../../store/action";



const SettingsComponent = React.memo(function ({handleExternalFetch}) {
    let param = useParams();
    const [addModalName, setAddModalName] = useState(false);
    const [activeTab, setActiveTab] = useState('section');
    const dispatch = useDispatch();
    useEffect(() => {
        let tabName =param['*']
        setActiveTab(tabName);
    }, [param]);

    useEffectOnce(()=>{
        dispatch(actionToGetCompanyListApiCall({in_website_use:1}));
        dispatch(actionToGetMenuListApiCall());
        dispatch(actionToGetComponentListApiCall());
        dispatch(actionToGetCurriculumListApiCall());
        dispatch(actionToGetWebSettingSeoReferenceHtmlApiCall());
        dispatch(actionToGetFormAuthRuleApiCall({eventTypeId:3}));
    })
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                                <Link to={subDomainUrl+'/website-setting/menu'} className='tab'><button
                                    className={`tab ${activeTab === 'menu' ? 'active' : ''}`}>
                                    Menu
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/website-setting/section'} className='tab'><button
                                    className={`tab ${activeTab === 'section' ? 'active' : ''}`}>
                                    Sections
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/website-setting/seo-meta'} className='tab'><button
                                    className={`tab ${activeTab === 'seo-meta' ? 'active' : ''}`}>
                                    SEO Meta
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/website-setting/company'} className='tab'><button
                                    className={`tab ${activeTab === 'company' ? 'active' : ''}`}>
                                    Company
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/website-setting/blog-posts'} className='tab'><button
                                    className={`tab ${activeTab === 'company' ? 'active' : ''}`}>
                                    Blogs
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add Section'>
                                <button  className='common-button' onClick={() => setAddModalName('add_section')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faCode} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Seo Meta'>
                                <button  className='common-button' onClick={() => setAddModalName('add_seo_meta')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faSearchengin} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Menu'>
                                <button  className='common-button' onClick={() => setAddModalName('add_menu')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faBars} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Blog Post'>
                                <button  className='common-button' onClick={() => setAddModalName('add_blog_post')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faSignsPost} />
                                </button>
                            </Tippy>
                            <AddWebsiteSection isOpen={addModalName === 'add_section'} onRequestClose={() => setAddModalName(false)} />
                            <AddSeoSetting isOpen={addModalName === 'add_seo_meta'} onRequestClose={() => setAddModalName(false)} />
                            <AddMenu isOpen={addModalName === 'add_menu'} onRequestClose={() => setAddModalName(false)} />
                            <BlogPostForm handleExternalFetch={handleExternalFetch} isOpen={addModalName === 'add_blog_post'} onRequestClose={() => setAddModalName(false)}
                                isEditMode={false} selectedData={{}}
                            />
                        </div>

                    </div>
                </div>
                <div className="tab-content">
                    <Outlet />
                </div>
            </section>
        </>
    )
});

export default SettingsComponent;
