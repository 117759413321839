import {GALLERY_TABLE_DATA} from "../constant/galleryConstant";


const initialState = {
    galleryListData:[],
    galleryEventTypeId:6
}
export  const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GALLERY_TABLE_DATA:
            return { ...state, galleryListData: action.payload };
        default:
            return state
    }
}
