import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import { useCommonForm } from "../../../hooks/useCommonForm";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import { makeApiCall } from "../../../hooks/api/makeApiCall";
import {LEAD_ADDRESS_DATA, SCHOOL_LIST} from "../../../store/constant/salesConstant";
import SelectCompanyDropdown from "../../auth/SelectCompanyDropdown";
import TextArea from "../../Layout/Form/TextArea";
import SelectSalesPersonDropdown from "../contact-person/SelectSalesPersonDropdown";
import SelectAddressDropdown from "./SelectAddressDropdown";
import SelectField from "../../Layout/Form/SelectField";
import DateField from "../../Layout/Form/DateField";
import {callInsertDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../store/action/commonActions";

const LeadToOrderFormModal = ({ isOpen, onRequestClose, selectedData, isEditMode, handleExternalFetch }) => {
    const ROLES = useSelector((state) => state.webSetting.roleList);
    const { auth } = useAuth();
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const [isBillingAddressDifferent, setIsBillingAddressDifferent] = useState(false);

    const validationRules = {
        relevant_document: { required: true },
    };

    const onSuccess = (res,obj) => {
        if(selectedData?.gem_bid_id){
            dispatch(callUpdateDataWithLogFunction({
                tableName: 'gem_bid',
                data: {contract_number:obj.contract_number,lead_order_id:res?.id},
                id:selectedData?.gem_bid_id,
                userId: auth?.id,
                eventTypeId
            }))
        }else if(obj.document_type === 'GEM Contract Copy'){
            dispatch(callInsertDataWithLogFunction({
                tableName: 'gem_bid',
                data: { contract_number:obj.contract_number,lead_order_id:res?.id,client_lead_id:obj.client_lead_id },
                userId: auth?.id,
                eventTypeId
            }));
        }
        handleExternalFetch?.(res, ['client-lead']);
        onRequestClose();
    };

    const { formData, errors, handleChange, handleSubmit, isFormChanged } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );

    const handleCheckboxChange = (e) => {
        setIsBillingAddressDifferent(e.target.checked);
    };
    useEffect(() => {
        makeApiCall(`sales/get-school-address`, LEAD_ADDRESS_DATA, dispatch,{school_id:selectedData.school_id});
    }, [isOpen]);
    useEffect(() => {
        if(selectedData?.gem_bid_id){
            handleChange({target:{name:'document_type',value:'GEM Contract Copy'}})
        }
    }, [selectedData]);

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container max-h-[80vh] overflow-y-auto p-5 bg-white rounded-lg w-[90%]">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Order' : 'Create Order'} />
                <form onSubmit={(e) => handleSubmit(e, validationRules, 'lead_order', { created_by: auth?.id },['contract_number'])}>
                    <div className='vendor-details'>
                        <SelectCompanyDropdown name={"company_id"} allowedRoles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.WebsiteEditor,ROLES.SalesTeam]} source={formData?.company_id} handleChange={handleChange} />
                        <SelectField label={"Document Type"}
                                     name={'document_type'}
                                     value={formData.document_type || ''}
                                     onChange={handleChange}
                                     valueArray={[ { value: 'Order Copy', label: 'Order Copy'},
                                         { value: 'GEM Contract Copy', label: 'GEM Contract Copy' },
                                         { value: 'Quotation', label: 'Quotation' }]}
                                     error={errors.document_type} className='select'/>
                        {formData.document_type === 'GEM Contract Copy' && <>
                            <InputField
                                type="text"
                                name="contract_number"
                                value={formData.contract_number || ''}
                                onChange={handleChange}
                                error={errors.contract_number} placeholder={'Contract Number'} label={'Contract Number'}
                            />
                        </> }
                        {!isEditMode && <InputField
                            type="file"
                            name="relevant_document"
                            value={formData.relevant_document || ''}
                            onChange={handleChange}
                            error={errors.relevant_document} label={'Relevant Document'}
                        />}
                        <DateField label="Order Date"
                                   name={'order_date'}
                                   value={formData.order_date || ''}
                                   onChange={handleChange}
                                   error={errors.order_date} placeholder='Order Date' />

                        <input type={'hidden'} name={'client_lead_id'} value={selectedData.client_lead_id}/>
                        {auth?.role === ROLES.SalesTeam ? <input type={'hidden'} name={'sales_person_id'} value={auth.id}/> :
                            <SelectSalesPersonDropdown name="sales_person_id" sales_person_id={formData.sales_person_id} handleChange={handleChange} />
                        }
                        <SelectAddressDropdown placeholder={"Shipping Address"} error={errors.shipping_address_id} name="shipping_address_id" address_id={formData.shipping_address_id} handleChange={handleChange}  />
                        <div className="checkbox-container">
                            <label className='label'>
                                <input type="checkbox" className='mr-2' onChange={handleCheckboxChange} />
                                Billing address is different
                            </label>
                        </div>
                        {isBillingAddressDifferent ? (
                            <SelectAddressDropdown placeholder={"Billing Address"} error={errors.billing_address_id} name="billing_address_id" address_id={formData.billing_address_id} handleChange={handleChange}  />
                        ) :  <input type={'hidden'} name={'billing_address_id'} value={formData.shipping_address_id}/>}
                        <TextArea
                            className='text-area'
                            name="description"
                            value={formData.description || ''}
                            onChange={handleChange}
                            placeholder='Description' className='text-area'
                            error={errors.description} label={'Add Description'}
                        />
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default LeadToOrderFormModal;
