import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelectField from "../../Layout/Form/CreatableSelectField";
import SchoolContactPersonForm from "./SchoolContactPersonForm";
import {actionToGetContactPersonApiCall} from "../../../store/action/salesAction";

const SelectContactPersonDropdown = ({school_contact_person_id,name,handleChange,school_id,placeholder,error}) =>{
    const dispatch=useDispatch();
    const contactPersonData = useSelector((state) =>  state.sales?.contactPersonData);
    const [isEditSchoolContactPersonOpen, setIsEditSchoolContactPersonOpen] = useState(false);
    const [selectedSchoolContactPerson, setSelectedSchoolContactPerson] = useState(null);
    const [contactPersonDataDropdown, setContactPersonDataDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState(null);

    useEffect(() => {
        let componentArray = [];
        let selectedData = null;
        contactPersonData !== 'loading'  && contactPersonData?.map(val=>{
                componentArray.push({value:val.id,label:val.name});
                if(school_contact_person_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setContactPersonDataDropdown([...componentArray]);
    }, [contactPersonData,school_contact_person_id]);
    const handleCreate = (val) => {
        setSelectedSchoolContactPerson({name:val,school_id:school_id});
        setIsEditSchoolContactPersonOpen('add_contact_person');
    };
    const handleFetchData = (res)=>{
        dispatch(actionToGetContactPersonApiCall({school_id:school_id})).then(response=>{
            handleChange({target:{name:name,value:res.id}});
            }
        );
    }
    const customStyles = {controlHeight:'50px', menuListMaxHeight:'150px'}


    return(<>
        <CreatableSelectField name={name} label={placeholder} customStyles={customStyles} placeholder={placeholder} error={error} dataDropdown={contactPersonDataDropdown}
                                  handleCreate={handleCreate} selectedDropDown={selectedDropDown} onChange={handleChange}/>
        {isEditSchoolContactPersonOpen === 'add_contact_person' && (
            <SchoolContactPersonForm
                handleExternalFetch={handleFetchData}
                isOpen={isEditSchoolContactPersonOpen==='add_contact_person'}
                onRequestClose={() => setIsEditSchoolContactPersonOpen(false)}
                selectedData={selectedSchoolContactPerson}
                isEditMode={false}
            />
        )}
    </>
    )
}
export default SelectContactPersonDropdown;
