import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextArea from "../../Layout/Form/TextArea";
import OrderComponentCustomization from "./OrderComponentCustomization";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";

const OrderPackingForm = ({ formData, handleChange, disabled }) => {
    const orderItemData = useSelector((state) => state.inventory?.orderProcessingDetailListData?.[0]?.orderItemData);
    const packingData = useSelector((state) => state.inventory?.orderProcessingDetailListData?.[0]?.packingData);
    const packingMaterialsData = useSelector((state) => state.inventory?.packagingItemDropdownListData || []);

    // Initialize state for editable quantities and packing materials
    const [items, setItems] = useState(orderItemData || []);
    const [packingMaterials, setPackingMaterials] = useState(
        packingMaterialsData.map(material => ({ ...material, selectedQuantity: 0 }))
    );
    const [allPacked, setAllPacked] = useState(false);// State to manage form visibility
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customizationIndex, setCustomizationIndex] = useState({});

    // Calculate remaining quantities
    const calculateRemainingItems = () => {
        const packedQuantities = {};
        packingData?.forEach(packing => {
            if (packing.packing_items) {
                packing.packing_items.forEach(item => {
                    const { item_id, quantity } = item;
                    console.log(item_id, quantity)
                    packedQuantities[item_id] = (packedQuantities[item_id] || 0) + quantity;
                });
            }
        });

        const remainingItems = orderItemData?.map(orderItem => {
            const packedQuantity = packedQuantities[orderItem.product_id] || 0;
            const remainingQuantity = orderItem.quantity - packedQuantity;
            return {
                ...orderItem,
                remaining_quantity: remainingQuantity > 0 ? remainingQuantity : 0 // Avoid negative quantities
            };
        });

        const itemsWithRemaining = remainingItems?.filter(item => item.remaining_quantity > 0);
        setAllPacked(!(itemsWithRemaining?.length > 0));
        return itemsWithRemaining;
    };

    // Initialize items with remaining quantities on component load
    useEffect(() => {
        const remainingItems = calculateRemainingItems();
        setItems(remainingItems?.map(item => ({ ...item, quantity: item.remaining_quantity })));
    }, [orderItemData, packingData]);

    // Update formData with order items and packing materials when they change
    useEffect(() => {
        const filteredItems = items?.filter(item => item.quantity > 0); // Only items with quantity > 0
        handleChange({ target: { name: 'packing_list', value: filteredItems } });
        handleChange({ target: { name: 'packing_materials', value: packingMaterials.filter(material => material.selectedQuantity > 0) } });
    }, [items, packingMaterials]);

    useEffect(() => {
        !formData?.description && handleChange({ target: { name: 'description', value: 'Packing List' } });
    }, [formData]);

    // Handle quantity change for order items
    const handleQuantityChange = (index, event) => {
        const updatedItems = [...items];
        updatedItems[index].quantity = event.target.value;
        setItems(updatedItems);
    };

    // Handle quantity change for packing materials
    const handlePackingMaterialChange = (index, event) => {
        const updatedMaterials = [...packingMaterials];
        updatedMaterials[index].selectedQuantity = event.target.value;
        setPackingMaterials(updatedMaterials);
    };

    const openCustomizationModal = (index) => {
        setCustomizationIndex(index);
        setIsModalOpen(true);
    };

    if (allPacked) {
        return <div className="packed-message">All items are fully packed.</div>;
    }

    return (
        <div className="container max-h-[60vh] overflow-y-auto rounded-lg w-[90%]">
            <div className='vendor-details'>
                <div>
                    <TextArea
                        className='order-text-area'
                        name="description"
                        label="Description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder='Enter Description' disabled={disabled}
                    />
                </div>

                <div className='title mt-4'>Edit Products Qty.</div>
                <table className='products-table'>
                    <thead>
                    <tr>
                        <th>Sr. No</th>
                        <th>Item Name</th>
                        <th>Item Photo</th>
                        <th>Quantity</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td >
                                {index + 1}
                            </td>
                            <td>{item.product_name}</td>
                            <td>
                                <img alt="item" src={item.product_photo} height="40px" />
                            </td>
                            <td className='w-28'>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    min="0"
                                    onChange={(event) => handleQuantityChange(index, event)}
                                    className="quantity-input" disabled={disabled}
                                />
                            </td>
                            <td className='w-20'>
                                {item.product_component?.length > 0 && (
                                    <div className='action-buttons'>
                                        <button type={"button"} onClick={() => openCustomizationModal(index)} className='action-button'>
                                            <FontAwesomeIcon className='action-icon' icon={faPenToSquare}/>
                                        </button>
                                    </div>

                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                {isModalOpen && (
                   <OrderComponentCustomization isOpen={isModalOpen} customizationIndex={customizationIndex} items={items} handleChange={handleChange} onRequestClose={()=>{setIsModalOpen(false); setCustomizationIndex(false)}} />
                )}

                <div className='title mt-4'>Edit Packing Materials</div>
                <table className='products-table'>
                    <thead>
                    <tr>
                        <th className='w-28'>Sr. No.</th>
                        <th className='w-64'>Material Name</th>
                        <th className='w-28'>Quantity</th>
                    </tr>
                    </thead>
                    <tbody>
                    {packingMaterials.map((material, index) => (
                        <tr key={material.id}>
                            <td>{index + 1}</td>
                            <td>{material.name}</td>
                            <td>
                                <input
                                    type="number"
                                    value={material.selectedQuantity}
                                    min="0"
                                    onChange={(event) => handlePackingMaterialChange(index, event)}
                                    className="quantity-input" disabled={disabled}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderPackingForm;
