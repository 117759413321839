import React from "react";
import CreatableSelect from 'react-select/creatable';

const CreatableSelectField = ({handleCreate,name,selectedDropDown,label,onChange,placeholder,error,dataDropdown, customStyles,smallText= ''}) => (
        <div className='input-box'>
            <span className='details'>{label} </span><span className='text-sm'>{smallText && `(${smallText})`}</span>
            <CreatableSelect name={name}
                             value={selectedDropDown}
                             onCreateOption={handleCreate}
                             onChange={(e)=>{onChange({target:{name:name,value:e.value}})}}
                             options={dataDropdown} styles={{
                control: (provided, state) => ({
                ...provided,
                height: customStyles.controlHeight,
                width: '95%',
                borderRadius: '5px',
                border: state.isFocused ? '1px solid #efdda8' : '1px solid #ccc',
                paddingLeft: '5px',
                fontSize: '13px',
                boxShadow: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                borderColor: '#efdda8'
            },

            }),
                menu: (provided) => ({
                ...provided,
                borderRadius: '5px',
                width: '95%',
                marginTop: '2px',
                zIndex: 2,
                overflowY: 'auto',
                maxHeight: '150px',

            }),
                menuList: (provided) => ({
                ...provided,
                maxHeight: customStyles.menuListMaxHeight,
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                width: '6px',
            },
                '&::-webkit-scrollbar-thumb': {
                background: '#ccc',
                borderRadius: '10px',
            },
                '&::-webkit-scrollbar-thumb:hover': {
                background: '#999'
            }
            }),
                placeholder: (provided) => ({
                ...provided,
                fontSize: '14px',
            }),
                option: (provided, state) => ({
                ...provided,
                fontSize: '13px',
                backgroundColor: state.isFocused ? '#efdda8' : '#fff',
                color: '#000',
                '&:hover': {
                backgroundColor: '#efdda8',
                color: '#000'
            }
            })
            }} placeholder={placeholder}
            />
            {error && <span className="error-message">{error}</span>}
        </div>
    )
export default CreatableSelectField;
