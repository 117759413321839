import React from 'react';
import Modal from 'react-modal';
const ShowPreviewofDescription = ({ isOpen, onRequestClose,description }) => {

    return (
        <div>
            <Modal isOpen={isOpen}  maxWidth="xl" onRequestClose={onRequestClose} className='description-modal lg'>
                <div className='description-container'>
                    <div className='title'>Preview</div>
                    <div className={"row"}>
                        <div className={"col-lg-12"}>
                            <div dangerouslySetInnerHTML={{__html:description}}></div>
                        </div>
                    </div>
                </div>
                <div className='buttons'>
                    <div className='button'>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ShowPreviewofDescription;
