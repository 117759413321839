import React, {useEffect, useState} from "react";
import { useSelector} from "react-redux";
import Select from "react-select";

const SelectProductDropdown = ({name,handleChange,product_id,placeholder,error,index}) =>{
    const productDropdownListData = useSelector((state) =>  state.inventory?.productDropdownListData);
    const [dataDropdown, setDataDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState(null);

    useEffect(() => {
        let componentArray = [];
        let selectedData = null;
        productDropdownListData !== 'loading'  && productDropdownListData.length > 0 && productDropdownListData?.map(val=>{
            let labelValue = val.name;
            componentArray.push({value:val.id,label:labelValue,price:val.sale_price});
            if(product_id == val.id) selectedData={value:val.id,label:labelValue,price:val.sale_price};
        })
        setSelectedDropDown(selectedData);
        setDataDropdown([...componentArray]);
    }, [productDropdownListData,product_id]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: '50px',
            width: '100%',
            borderRadius: '5px',
            border: state.isFocused ? '1px solid #efdda8' : '1px solid #ccc',
            paddingLeft: '5px',
            fontSize: '13px',
            boxShadow: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
                borderColor: '#efdda8'
            },

        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            width: '100%',
            marginTop: '2px',
            zIndex: 2,
            overflowY: 'auto',
            maxHeight: '150px',

        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#ccc',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#999'
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
            backgroundColor: state.isFocused ? '#efdda8' : '#fff',
            color: '#000',
            '&:hover': {
                backgroundColor: '#efdda8',
                color: '#000'
            }
        })
    };

    return(
        <div className='input-box'>
            {/*<span className='details'>{placeholder}</span>*/}
            <Select name={name}
                    value={selectedDropDown}
                    onChange={(e)=>handleChange(index,{target:{name:name,value:e.value,price:e.price}})}
                    options={dataDropdown} styles={customStyles} placeholder={placeholder}
            />
            {error && <span className="error-message"> {error}</span> }
        </div>
    )
}
export default SelectProductDropdown;
