import {Container,Nav,Navbar,NavDropdown} from 'react-bootstrap';
import {BsBell, BsEnvelope, BsGlobe, BsPerson, BsSearch} from "react-icons/bs";
import React from "react";
import {Link} from "react-router-dom";
import {subDomainUrl} from "../../hooks/config";
function InventoryHeader() {
    return (
       <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark" fixed="top">
            <Container>

                <Navbar.Brand to={subDomainUrl+"/inventory"}>
                   TechBotz Solutions</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Link to={"/inventory"} className={"nav-link"}>Inventory</Link>
                        <Link to={"/products"} className={"nav-link"}>Products</Link>
                        <Link to={"/purchase"} className={"nav-link"}>Purchase</Link>
                        <Link to={"/sales"} className={"nav-link"}>Sales</Link>
                        <Link to={"/damaged"} className={"nav-link"}>Damaged</Link>
                    </Nav>
                    <Nav>
                        <a href="">
                            <BsSearch className='nav-icon'/>
                        </a>
                        <a href="">
                            <BsGlobe className='nav-icon'/>
                        </a>
                        <a href="">
                            <BsEnvelope className='nav-icon'/>
                        </a>
                        <a href="">
                            <BsBell className='nav-icon'/>
                        </a>
                    </Nav>
                    <Nav className="me-auto">
                        <BsPerson className="nav-icon"/>
                        <NavDropdown title="My Account" id="collapsible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Inbox</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Analytics
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>


    );
}

export default InventoryHeader;