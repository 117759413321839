// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import SelectStateDropdown from '../region/SelectStateDropdown';
import SelectCityDropdown from '../region/SelectCityDropdown';
import { PinCodeText } from '../region/PinCodeText';
import SelectSchoolTypeDropdown from './SelectSchoolTypeDropdown';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import TextArea from "../../Layout/Form/TextArea";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import {makeApiCall} from "../../../hooks/api/makeApiCall";
import {SCHOOL_LIST} from "../../../store/constant/salesConstant";
import SelectDesignationDropdown from "../contact-person/SelectDesignationDropdown";

const SchoolForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const validationRules = {
        name: { required: true, minLength: 3, maxLength: 50 },
        pincode: { required: true, type: 'pincode' },
        school_type_id: { required: true, field_name: ' School Type'},
    };
    const onSuccess = (res)=>{
        makeApiCall(`sales/get-school-list-dropdown`,SCHOOL_LIST,dispatch);
        handleExternalFetch?.(res,['school']);
        onRequestClose();
    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged,updatePinCode } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container max-h-[80vh] overflow-y-auto rounded-lg w-[90%]">
                <BreadCrumb first_crumb={isEditMode ? 'Edit School' : 'Add School'}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'schools')}>
                    <div className='vendor-details'>
                    <InputField
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleChange}
                        error={errors.name} placeholder={'School Name'} label={'School Name'}
                    />
                    <InputField
                        type="text"
                        name="phone"
                        value={formData.phone || ''}
                        onChange={handleChange}
                        error={errors.phone} placeholder={'Phone'} label={'School Landline Number'}
                    />
                    <InputField
                        type="email"
                        name="email"
                        value={formData.email || ''}
                        onChange={handleChange}
                        error={errors.email} placeholder={'Email of the School'} label={'School Email'}
                    />
                        {!isEditMode ?<> <InputField
                            label="Contact Person Name"
                            type="text"
                            name="contact_person_name"
                            value={formData.contact_person_name || ''}
                            onChange={handleChange}
                            error={errors.contact_person_name} placeholder='Enter Contact Person Name'
                        />
                        <SelectDesignationDropdown errors={errors} name="designation_id" designation_id={formData.designation_id} handleChange={handleChange}  />
                        <InputField
                            label="Contact Person Phone"
                            type="text"
                            name="contact_person_phone"
                            value={formData.contact_person_phone || ''}
                            onChange={handleChange}
                            error={errors.contact_person_phone} placeholder='Enter Phone Number'
                        />
                        <InputField
                            label="Contact Person Phone2"
                            type="text"
                            name="contact_person_phone2"
                            value={formData.contact_person_phone2 || ''}
                            onChange={handleChange}
                            error={errors.contact_person_phone2} placeholder='Enter Second Phone Number'
                        /></> : <input type={"hidden"} value={selectedData?.address_id} name={"address_id"} />}
                    {/*<InputField
                        type="text"
                        name="number_of_student"
                        value={formData.number_of_student || ''}
                        onChange={handleChange}
                        error={errors.number_of_student} placeholder={'Number of Students'} label={'Number of Students'}
                    />*/}

                    <PinCodeText errors={errors} name="pincode" pin_code={formData.pincode} updatePinCode={updatePinCode} />
                    <SelectStateDropdown errors={errors} name="state_id" state_id={formData.state_id} handleChange={handleChange} />
                    <SelectCityDropdown errors={errors} name="city_id" state_id={formData.state_id} city_id={formData.city_id} handleChange={handleChange} />
                    <SelectSchoolTypeDropdown errors={errors} name="school_type_id" school_type_id={formData.school_type_id} handleChange={handleChange} />
                        <TextArea
                            className='text-area'
                            name="address"
                            placeholder="Address"
                            value={formData.address || ''}
                            onChange={handleChange} label={'Address of the School'}
                        />
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default SchoolForm;
