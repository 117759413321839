import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBroom, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {actionToDeleteCacheApiCall, actionToGetCompanyListApiCall} from "../../../../store/action/webSettingActions";
import EditCompany from "./EditCompany";
import useAuth from "../../../../hooks/useAuth";
import {uploadFileOnAws} from "../../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
import Tippy from "@tippyjs/react";

export const CompanyTable = () => {
    const [isEditCompanyOpen, setIsEditCompanyOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const companyData = useSelector((state) =>  state.webSetting.companyDataList);
    const {auth} = useAuth();
    const hiddenFileInput = useRef();
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(companyData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };
    const handleLogoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainLogoUpload = async event => {
        const fileUploaded = event.target.files[0];
        const fileExtension = fileUploaded.name.split('.').pop();
        let data =companyData[selectedIndex];
        let payload = {filename:'logo/'+data.name?.toLowerCase()+'.'+fileExtension,showOnWebsite:data.name,photo:fileUploaded,type:fileUploaded.type}
        let url = await dispatch(uploadFileOnAws(payload));
        dispatch(callUpdateDataWithLogFunction({tableName:'company',data: {logo:url+'?v='+Math.floor(1000 + Math.random() * 9000)},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToDeleteCacheApiCall({key:'website-company-data-'+data.id}));
            dispatch(actionToGetCompanyListApiCall());
        })
    };
    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === companyData.length);
    };
    useEffectOnce(()=>{
        dispatch(actionToGetCompanyListApiCall());
    })
    return(
        <>
            <div className='table-container'>
                <div className='flex justify-end'>
                    <Tippy content='Clean Cache'>
                        <button  className='submit-button mb-2'>
                            <FontAwesomeIcon icon={faBroom} />
                        </button>
                    </Tippy></div>
            {
                companyData === 'loading' ?
                    <PreLoader/>
                    :
        <div className="table">
            <div className="row-header">
                <div className="seven-column-cell">
                    <input className='checkbox' type="checkbox"
                           checked={selectAll}
                           onChange={()=>handleSelectAll()}/>
                    Company Name
                </div>
                <div className="seven-column-cell">Logo</div>
                <div className="seven-column-cell">Address</div>
                <div className="seven-column-cell">Phone</div>
                <div className="seven-column-cell">Url</div>
                <div className="seven-column-cell">Email</div>
                <div className="seven-column-cell">Action</div>
            </div>
            <Virtuoso style={{ height: '600px' }} totalCount={companyData.length} itemContent={index =>
                <div className="table-row">
                    <div className="seven-column-cell">
                        <input className='checkbox' type="checkbox"
                               checked={selectedRows.includes(companyData[index]?.id)}
                               onChange={() => handleSelectRow(companyData[index]?.id)}/>
                        {companyData[index]?.name}
                    </div>
                    <div className="seven-column-cell" onClick={()=>handleLogoClick(index)}>
                            <img src={companyData[index]?.logo} height="35" alt={companyData[index]?.name} />
                    </div>
                    <div className="seven-column-cell">
                            {companyData[index]?.address}
                    </div>
                    <div className="seven-column-cell">
                            {companyData[index]?.phone}
                    </div>
                    <div className="seven-column-cell">
                            {companyData[index]?.url}
                    </div>
                    <div className="seven-column-cell">
                            {companyData[index]?.email}
                    </div>
                    <div className="seven-column-cell">
                        <div className='action-components'>
                            <div className='action-buttons'>
                                <button className='action-button' onClick={() => {setIsEditCompanyOpen('edit_company'); setSelectedIndex(index);}} >
                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        </div>
            }

            <input type="file" onChange={handleMainLogoUpload} ref={hiddenFileInput} style={{display: 'none'}} />
            <EditCompany isOpen={isEditCompanyOpen ==='edit_company'} selectedData={companyData[selectedIndex]} onRequestClose={() => {setIsEditCompanyOpen(false); setSelectedIndex(false);}} />
            </div>
            </>
    )
}
