import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import {callInsertDataWithLogFunction} from "../../../../store/action/commonActions";
import {
    actionToDeleteCacheApiCall,
    actionToGetWebSettingSectionApiCall
} from "../../../../store/action/webSettingActions";
import {Editor} from "@tinymce/tinymce-react";
import SelectCompanyDropdown from "../../../auth/SelectCompanyDropdown";
import SelectMenuDropdown from "../menu/SelectMenuDropdown";
import SelectComponentDropdown from "../menu/SelectComponentDropdown";
import {toast} from "react-toastify";

const AddWebsiteSection = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const menuDataList = useSelector((state) =>  state.webSetting.menuDataList);
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
   // const [objectType, setObjectType] = useState('text');
   // const [objectValue, setObjectValue] = useState('');
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [formData, setFormData] = useState({name:'',source:auth.source});

    const parseEditorData = (content, editor) => {
        //content is the current value of the text editor
        // editor is an object that holds the html element that in this case is the text area where the name prop will be stored.
        const { targetElm } = editor;
        // This name value references the prop that you pass as textAreaName (content in your case)
        const { name } = targetElm;

        // This function returns an object that your handle change can parse correctly
        return {
            target: {
                name,
                value: content
            }
        };
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());

        // Validate the form data
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            let param = { ordering: obj['ordering'], menu_id: obj['menu_id'] };
            obj['created_by'] = auth?.id;

            // Clean up the object
            delete obj['source'];
            delete obj['ordering'];
            delete obj['menu_id'];

            try {
                // First dispatch to insert into website_section
                const res = await dispatch(callInsertDataWithLogFunction({
                    tableName: 'website_section',
                    data: obj,
                    userId: auth?.id,
                    eventTypeId
                }));

                // Invalidate cache for each menu item
                menuDataList?.forEach(menu =>
                    dispatch(actionToDeleteCacheApiCall({ key: 'shikshak-website-section-content' + menu?.url + menu.source }))
                );

                if (res?.success === 1) {
                    param['website_section_id'] = res.id;

                    // Second dispatch to insert into menu_website_section
                    await dispatch(callInsertDataWithLogFunction({
                        tableName: 'menu_website_section',
                        data: param,
                        userId: auth?.id,
                        eventTypeId
                    }));

                    dispatch(actionToGetWebSettingSectionApiCall());
                    setSaveStatus('success');
                    toast.success('Website section and menu section added successfully!'); // Success notification
                    resetForm();
                } else {
                    setSaveStatus('fail');
                    toast.error('Failed to add website section.'); // Failure notification
                }
            } catch (error) {
                setSaveStatus('fail');
                toast.error('An error occurred while adding the section.'); // Error handling
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.'); // Validation error notification
        }
    };
    const resetForm = () => {
        document.getElementById('website-content-form')?.reset();
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!data.component_type.trim()) {
            errors.component_type = 'Component is required';
        }
        if (!data.ordering.trim()) {
            errors.ordering = 'Position is in page is required';
        }
        if (!data.menu_id.trim()) {
            errors.menu_id = 'Menu is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
       // setObjectType('text')
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container max-h-[80vh] overflow-y-auto p-5 bg-white rounded-lg w-[90%]">
                <div className='title'>Add Website Section</div>
                <form onSubmit={handleSubmit} id='website-content-form'>
                    <div className='vendor-details'>
                        <SelectCompanyDropdown allowedRoles={allowedRoles} source={formData.source} handleChange={handleChange}/>
                        <SelectMenuDropdown name={'menu_id'} source={formData.source} errors={errors} menu_id={formData.menu_id} handleChange={handleChange}/>
                        <div className='input-box'>
                            <span className='details'>Select Component Type</span>
                            <select className='select' name='component_type' placeholder='Component Type' onChange={(e)=>handleChange(e)}>
                                <option value="" >Select Component Type </option>
                                <option value="banner" selected={formData.component_type === 'banner'}>Banner </option>
                                <option value="section"  selected={formData.component_type === 'section'}>Section</option>
                                <option value="category_card"  selected={formData.component_type === 'category_card'}>Category Card</option>
                                <option value="grade_card"  selected={formData.component_type === 'grade_card'}>Grade Card</option>
                                <option value="product_card"  selected={formData.component_type === 'product_card'}>Product Card</option>
                                <option value="curriculum_card"  selected={formData.component_type === 'curriculum_card'}>Curriculum Card</option>
                                <option value="component"  selected={formData.component_type === 'component'}>Pure Component</option>
                                <option value="page_content"  selected={formData.component_type === 'page_content'}>Page Content</option>
                                <option value="others"  selected={formData.component_type === 'others'}>Others</option>
                            </select>
                            {errors.component_type && <span className="error-message"> {errors.component_type}</span> }
                        </div>
                        <SelectComponentDropdown name={'component_id'}  errors={errors} component_id={formData.component_id} component_type={formData.component_type} handleChange={handleChange}/>
                        <div className='input-box'>
                            <span className='details'>Section Name</span>
                            <input type='text' placeholder='Section Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>

                        <div className='input-box'>
                            <span className='details'>Object Heading</span>
                            <input type='text' placeholder='Website Heading' name='object_heading' className='form-input'/>
                            {errors.object_heading &&  <span className="error-message"> {errors.object_heading} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Object Sub Heading</span>
                            <input type='text' placeholder='Website Sub Heading' name='object_subheading' className='form-input'/>
                            {errors.object_subheading &&  <span className="error-message"> {errors.object_subheading} </span> }
                        </div>

                        <div className='input-box'>
                            <span className='details'>Object Class</span>
                            <input type='text' placeholder='Call to Action Name' name='object_class' className='form-input'/>
                            {errors.object_class &&  <span className="error-message"> {errors.object_class} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Call to action Link</span>
                            <input type='text' name='call_to_action_link' className='form-input' placeholder={'Redirecting Link'}/>
                            {errors.call_to_action_link &&  <span className="error-message"> {errors.call_to_action_link} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Position Of Section</span>
                            <input type='number' name='ordering' className='form-input' placeholder={'Position'}/>
                            {errors.ordering &&  <span className="error-message"> {errors.ordering} </span> }
                        </div>
                        <Editor
                            apiKey="nchrb4md98keu69dxd2sjsc25q52blhw3z0ryes84hw6wscr"
                            init={{
                                height: 200,
                                plugins: [
                                    'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                                    'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                                    'table', 'emoticons', 'help'
                                ],
                                toolbar: 'undo redo | print preview code media fullscreen | styles bold italic | alignleft aligncenter alignright alignjustify |codeformat | ' +
                                    'bullist numlist outdent indent | link image | ' +
                                    'forecolor backcolor emoticons | help',
                                menu: {
                                    favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                                },
                                menubar: 'favs file edit view insert format tools table help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                            }}
                            onEditorChange={(content, editor) =>
                                handleChange(parseEditorData(content, editor))
                            }
                            value={formData?.object_content}
                            textareaName="object_content"
                        />
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Website Content Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddWebsiteSection;
