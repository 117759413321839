import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth.js";
import {subDomainUrl} from "../../hooks/config";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        (allowedRoles?.includes(auth?.role))
            ? <Outlet />
            : auth?.id
                ? <Navigate to={subDomainUrl+"/unauthorized"} state={{ from: location }} replace /> :
                (!localStorage.getItem('accessToken')) ? <Navigate to={subDomainUrl+"/login"} state={{ from: location }} replace /> :''
    );
}

export default RequireAuth;
