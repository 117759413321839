import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
import {TableVirtuoso, Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PreLoader from "../../../loader/PreLoader";
import swal from "sweetalert";
import {callSoftDeleteDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import {
    actionToGetWebSettingSeoMetaApiCall} from "../../../../store/action/webSettingActions";
import EditSeoSetting from "./EditSeoSetting";
import {useParams} from "react-router-dom";

export const SeoSettingTable = (props) => {
    const [isEditContentOpen, setIsEditContentOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const seoMetaData = useSelector((state) =>  state.webSetting?.seoMetaData);
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const {menu_id} = useParams();
    const [tableData, setTableData] = useState([]);

    useEffectOnce(()=>{
        dispatch(actionToGetWebSettingSeoMetaApiCall());
    })
    const seoSettingFilterFunction = (menu_id) =>{
        let tableList=[];
        if(menu_id && props.seoData){
            let idArray=[];
            props.seoData?.map(seo=>{
               !idArray.includes(seo.id) && idArray.push(seo.id);
            })
            seoMetaData?.map(dataArray=>{
                if(idArray.includes(dataArray.id)){
                    tableList.push(dataArray);
                }
            })
        }else{
            tableList =seoMetaData;
        }
        setTableData(tableList);
    }
    useEffect(() => {
        seoMetaData !=='loading' && seoMetaData.length > 0 && seoSettingFilterFunction(menu_id);
    }, [menu_id,seoMetaData,props.seoData]);
    const deleteContent = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Seo Content "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'seo_reference',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetWebSettingSeoMetaApiCall())
                    })
                }
            });

    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(tableData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === tableData.length);
    };
    return(
        <>
            <div className='table-container'>
            {
                seoMetaData === 'loading' ?
                    <PreLoader/>
                    :
                    <div className="website-table">
                        <div className="website-row-header">
                            <div className="seven-column-cell">
                                <input className='checkbox' type="checkbox"
                                       checked={selectAll}
                                       onChange={()=>handleSelectAll()}/>
                                 Name
                            </div>

                            <div className="seven-column-cell">Website Name</div>
                            <div className="seven-column-cell">Menu Name</div>
                            <div className="seven-column-cell">Content</div>
                            <div className="seven-column-cell">Charset</div>
                            <div className="seven-column-cell">Html Type</div>
                            <div className="seven-column-cell">Action</div>
                        </div>
                        <Virtuoso style={{ height: '600px' }} totalCount={tableData.length} itemContent={index =>
                            <div className="table-row">
                                <div className="seven-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectedRows.includes(tableData[index]?.id)}
                                           onChange={() => handleSelectRow(tableData[index]?.id)}/>
                                    {tableData[index]?.name}
                                </div>
                                <div className="seven-column-cell"> {tableData[index]?.company_name}</div>
                                <div className="seven-column-cell"> {tableData[index]?.menu_name}</div>
                                <div className="seven-column-cell"> {tableData[index]?.content}</div>
                                <div className="seven-column-cell"> {tableData[index]?.charset}</div>
                                <div className="seven-column-cell"> {tableData[index]?.html_type_name}</div>
                                <div className="seven-column-cell">
                                    <div className='action-components'>
                                        <div className='action-buttons'>
                                            <button className='action-button' onClick={() => {setIsEditContentOpen(true); setSelectedIndex(index);}} >
                                                <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                            </button>
                                        </div>
                                        <div className='action-buttons'>
                                            <button onClick={()=>deleteContent(tableData[index])} className='action-button'>
                                                <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } />
                    </div>
            }
            <EditSeoSetting isOpen={isEditContentOpen} selectedData={tableData[selectedIndex]} onRequestClose={() => {setIsEditContentOpen(false); setSelectedIndex(false);}} />
            </div>
            </>
    )
}
