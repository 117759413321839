import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLaptopFile, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import EditSubjects from "./EditSubjects";
import swal from "sweetalert";
import {actionToGetSubjectsApiCall} from "../../../store/action/curriculumAction";
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import useAuth from "../../../hooks/useAuth";
import { uploadFileOnAws} from "../../../store/action/inventoryActions";
import Tippy from "@tippyjs/react";
import AddSubjectTopic from "./AddSubjectTopic";

export const SubjectsTable = () => {
    const [isEditSubjectsOpen, setIsEditSubjectsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const subjectData = useSelector((state) =>  state.curriculum.subjectsListData);
    const {auth} = useAuth();
    const hiddenFileInput = useRef();
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);

    useEffectOnce(()=>{
        dispatch(actionToGetSubjectsApiCall());
    })

    const deleteSubject = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Subject "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'subjects',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetSubjectsApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(subjectData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };
    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        const fileExtension = fileUploaded.name.split('.').pop();
        let data =subjectData[selectedIndex];
        let payload = {filename:'subject/'+data.name?.toLowerCase()+'.'+fileExtension,showOnWebsite:'stemcity',photo:fileUploaded,type:fileUploaded.type}
        data.photo ? payload['deletedFileName'] =  data.photo?.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','') :'';
        let url = await dispatch(uploadFileOnAws(payload));
        dispatch(callUpdateDataWithLogFunction({tableName:'subjects',data: {photo:url+'?v='+Math.floor(1000 + Math.random() * 9000)},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetSubjectsApiCall());
        })
    };
    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === subjectData.length);
    };
    return(
        <>
            <div className='table-container'>
            {
                subjectData === 'loading' ?
                    <PreLoader/>
                    :
        <div className="table">
            <div className="row-header">
                <div className="three-column-cell">
                    <input className='checkbox' type="checkbox"
                           checked={selectAll}
                           onChange={()=>handleSelectAll()}/>
                    Subject Name
                </div>
                <div className="three-column-cell">Subject Icon</div>
                <div className="three-column-cell">Action</div>
            </div>
            <Virtuoso style={{ height: '600px' }} totalCount={subjectData.length} itemContent={index =>
                <div className="table-row">
                    <div className="three-column-cell">
                        <input className='checkbox' type="checkbox"
                               checked={selectedRows.includes(subjectData[index]?.id)}
                               onChange={() => handleSelectRow(subjectData[index]?.id)}/>
                        {subjectData[index]?.name}
                    </div>
                    <div className="three-column-cell" onClick={()=>handlePhotoClick(index)}>
                            <img src={subjectData[index]?.photo} height="35" alt={subjectData[index]?.name} />
                    </div>
                    <div className="three-column-cell">
                        <div className='action-components'>
                            <div className='action-buttons'>
                                <button className='action-button' onClick={() => {setIsEditSubjectsOpen('edit_subject'); setSelectedIndex(index);}} >
                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                </button>
                            </div>
                            <div className='action-buttons'>
                                <button onClick={()=>deleteSubject(subjectData[index])} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                </button>
                            </div>

                           {/* <div className='action-buttons'>
                                <Tippy content='Add Topic'>
                                    <button className='action-button' onClick={() => {setIsEditSubjectsOpen('grade_topic'); setSelectedIndex(index);}} >
                                        <FontAwesomeIcon className='action-icon' icon={faLaptopFile} />
                                    </button>
                                </Tippy>
                            </div>*/}
                        </div>
                    </div>
                </div>
            } />
        </div>
            }

            <input type="file" onChange={handleMainPhotoUpload} ref={hiddenFileInput} style={{display: 'none'}} />
            <EditSubjects isOpen={isEditSubjectsOpen ==='edit_subject'} selectedData={subjectData[selectedIndex]} onRequestClose={() => {setIsEditSubjectsOpen(false); setSelectedIndex(false);}} />
            {/*<AddSubjectTopic isOpen={isEditSubjectsOpen ==='grade_topic'} selectedData={subjectData[selectedIndex]} onRequestClose={() => {setIsEditSubjectsOpen(false); setSelectedIndex(false);}} />*/}
            </div>
            </>
    )
}
