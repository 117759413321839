import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import InputField from '../../Layout/Form/InputField';
import DateField from "../../Layout/Form/DateField";
import SelectTransporterDropdown from "../transporter/SelectTransporterDropdown";

const OrderTrackingForm = ({ formData, handleChange, errors }) => {
    const packingData = useSelector(state => state.inventory?.orderProcessingDetailListData?.[0]?.packingData);

    // Memoized function to find missing packing items across all packing entries
    const missingPackingItems = useMemo(() => {
        if (!packingData || packingData.length === 0) return [];

        // Extract all tracking packing_item_ids from each packing entry
        const trackingIds = new Set(
            packingData.flatMap(entry => entry.tracking || []).map(item => item.packing_item_id)
        );

        // Extract all packing items and filter out those already in tracking
        return packingData.filter(item => !trackingIds.has(item.packing_id));
    }, [packingData]);

    return (
        <div className="container">
            <div className="title">Tracking Details</div>
            <div className="vendor-details">

                {/* Render missing packing items as radio buttons */}
                {missingPackingItems.length > 0 && (
                    <div className="missing-items">
                        <label>Select Missing Packing Item:</label>
                        {missingPackingItems.map((item) => (
                            <div key={item.packing_id} className="radio-option">
                                <input
                                    type="radio"
                                    id={`packing-item-${item.packing_id}`}
                                    name="order_packing_id"
                                    value={item.packing_id}
                                    onChange={handleChange} className='mr-2'
                                />
                                <label htmlFor={`packing-item-${item.packing_id}`}>
                                    Packing ID: {item.packing_id}
                                </label>
                            </div>
                        ))}
                    </div>
                )}

                <SelectTransporterDropdown
                    placeholder="Transporter Name"
                    errors={errors}
                    name="transporter_id"
                    transporter_id={formData?.transporter_id}
                    handleChange={handleChange}
                />

                <InputField
                    label="Tracking Number"
                    type="text"
                    name="tracking_number"
                    value={formData.tracking_number || ''}
                    onChange={handleChange}
                    error={errors.tracking_number}
                    placeholder="Tracking Number"
                />

                <DateField
                    label="Expected Date of delivery"
                    name="expected_date_of_delivery"
                    value={formData.expected_date_of_delivery || ''}
                    onChange={handleChange}
                    error={errors.expected_date_of_delivery}
                    placeholder="Expected Date of delivery"
                />

                <InputField
                    label="Shipping Cost"
                    type="number"
                    name="shipping_amount"
                    value={formData.shipping_amount || ''}
                    onChange={handleChange}
                    error={errors.shipping_amount}
                    placeholder="Amount"
                    min="0"
                    step="0.01"
                />
            </div>
        </div>
    );
};

export default OrderTrackingForm;
