import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

const OrderComponentCustomization = ({ isOpen, onRequestClose, customizationIndex, items, handleChange }) => {
    const [customizationData, setCustomizationData] = useState({});

    useEffect(() => {
        if (isOpen) {
            // Create a deep copy to avoid mutating the original `items`
            const dataCopy = JSON.parse(JSON.stringify(items[customizationIndex]));
            dataCopy.product_component.forEach((component, index) => {
                component.totalQuantity = Math.round(component.quantity * dataCopy.quantity);
            });
            setCustomizationData(dataCopy);
        }
    }, [customizationIndex, items, isOpen]);

    const handleComponentQuantityChange = (index, event) => {
        const updatedComponents = [...customizationData.product_component];
        updatedComponents[index].quantity = Number(event.target.value);
        updatedComponents[index].totalQuantity = Math.round(updatedComponents[index].quantity * customizationData.quantity);
        setCustomizationData({
            ...customizationData,
            product_component: updatedComponents
        });
    };

    const handleComponentTotalQuantityChange = (index, event) => {
        const updatedComponents = [...customizationData.product_component];
        updatedComponents[index].totalQuantity = Number(event.target.value);
        setCustomizationData({
            ...customizationData,
            product_component: updatedComponents
        });
    };

    const submitCustomization = () => {
        const customizedComponents = customizationData.product_component.map(component => ({
            product_id: customizationData.product_id,
            id: component.id,
            quantity: component.quantity,
            totalQuantity: component.totalQuantity,
        }));

        const filteredItems = customizedComponents.filter(item => item.totalQuantity > 0);
        const updatedItems = [...items];
        updatedItems[customizationIndex] = {
            ...customizationData,
            product_component: filteredItems,
        };
        const filteredUpdatedItems = updatedItems?.filter(item => item.quantity > 0); // Only items with quantity > 0
        handleChange({ target: { name: 'packing_list', value: filteredUpdatedItems } });
        onRequestClose();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <h3 className='title'>Customize Components</h3>
                <table className='products-table'>
                    <thead>
                    <tr>
                        <th className='w-20'>Sr. No.</th>
                        <th className='w-48'>Component Name</th>
                        <th className='w-28'>Component Quantity</th>
                        <th className='w-32'>Total Quantity (Component * Product)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {customizationData?.product_component?.map((component, index) => (
                        <tr key={component.id}>
                            <td>{index+1}</td>
                            <td>{component.name}</td>
                            <td>
                                <input
                                    type="number"
                                    value={component.quantity}
                                    min="0"
                                    onChange={(event) => handleComponentQuantityChange(index, event)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={component.totalQuantity}
                                    min="0"
                                    onChange={(event) => handleComponentTotalQuantityChange(index, event)}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className='flex justify-around mt-5'>
                    <button type="button" className='submit-button' onClick={()=>submitCustomization()}>OK</button>
                    <button type="button" className='submit-button' onClick={()=>onRequestClose()}>Cancel</button>
                </div>
            </div>
        </Modal>
    );
};

export default OrderComponentCustomization;
