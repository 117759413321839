import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';


function DefectiveForm() {

    return (
        <div className="inventory-form">
            <div className={"inventory-heading"}><h4>Defective/Damaged Products</h4></div>
                <form>
                    <div className="row text-center mt-5 fw-bold">
                        <div>
                            <label className={"form-label"}>Product ID: </label>
                            <input
                                className={"form-control"}
                                autoComplete="off"
                                name="title"
                                placeholder="Input Product ID"
                            />
                            <span name="title" component="span" className={"form-text"}/>
                        </div>
                    </div>
                    <div className="row text-center mt-5 fw-bold">
                        <div>
                            <label className={"form-label"}>Product Name </label>
                            <input
                                className={"form-control"}
                                autoComplete="off"
                                name="title"
                                placeholder="Input Product Name"
                            />
                            <span name="title" component="span" className={"form-text"}/>
                        </div>
                    </div>
                    <div className="row text-center mt-5 fw-bold">
                        <div>
                            <label className={"form-label"}>Reason/Description: </label>
                            <textarea className='text-area' rows="4" cols="100" required className="form-control" name="Desc" />
                        </div>
                    </div>
                    <div className="row text-center mt-5 fw-bold">
                            <div>
                                <label className={"form-label"}>No. of Pieces Damaged : </label>
                                <input
                                    className={"form-control"}
                                    autoComplete="off"
                                    name="title"
                                    placeholder="Input Qty."
                                />
                                <span name="title" component="span" className={"form-text"}/>
                            </div>
                    </div>
                    <div className="row text-center mt-5 fw-bold">
                        <div className={"col-md-12"}>
                            <button type="submit" className={"btn btn-primary"}>Submit</button>
                        </div>
                    </div>
                </form>
        </div>
    );
}

export default DefectiveForm;
