import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {
    actionToGetDiscountCouponForProductApiCall
} from "../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import Select from "react-select";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import {toast} from "react-toastify";
const ProductDetailUpdate = ({ isOpen,fetchData, onRequestClose,selectedProduct }) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const discountCouponListData = useSelector((state) =>  state.inventory.discountCouponListData);
    const [formData, setFormData] = useState({slug:'' });
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [discountCouponDropdown, setDiscountCouponDropdown] = useState([]);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedProduct));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            if (!obj['discount_id']) delete obj['discount_id'];
            if (!obj['min_age']) delete obj['min_age'];
            if (!obj['max_age']) delete obj['max_age'];

            dispatch(callUpdateDataWithLogFunction({
                tableName: 'product_details',
                data: obj,
                id: selectedProduct?.product_detail_id,
                userId: auth?.id,
                eventTypeId,
            })).then(res => {
                let status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                if (status === 'success') {
                    toast.success('Product details updated successfully!'); // Show success toast
                    fetchData();
                    onRequestClose();
                } else {
                    toast.error('Failed to update product details.'); // Show error toast
                }
            });
        } else {
            setSaveStatus('fail');
            toast.error('Form submission failed due to validation errors.'); // Show validation error toast
        }
    };
    useEffect(() => {
        if(selectedProduct?.id){
            setFormData(selectedProduct);
        }
    }, [selectedProduct]);

    const validateForm = (data) => {
        const errors = {};
        if (!data.slug.trim()) {
            errors.slug = 'Product Slug is required';
        }

        return errors;
    };
    useEffect(() => {
        let discountArray = [];
        discountCouponListData?.map(val=>{
            discountArray.push({value:val.id,label:val.name})
        })
        setDiscountCouponDropdown(discountArray);
    }, [discountCouponListData]);
    useEffectOnce(()=>{
        dispatch(actionToGetDiscountCouponForProductApiCall({type:'discount'}));
    })

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: '50px',
            width: '95%',
            borderRadius: '5px',
            border: state.isFocused ? '1px solid #efdda8' : '1px solid #ccc',
            paddingLeft: '5px',
            fontSize: '13px',
            boxShadow: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
                borderColor: '#efdda8'
            },

        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            width: '95%',
            marginTop: '2px',
            zIndex: 2,
            overflowY: 'auto',
            maxHeight: '150px',

        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#ccc',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#999'
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
            backgroundColor: state.isFocused ? '#efdda8' : '#fff',
            color: '#000',
            '&:hover': {
                backgroundColor: '#efdda8',
                color: '#000'
            }
        })
    };

    return (
        <div>
            <Modal isOpen={isOpen}  maxWidth="xl" onRequestClose={onRequestClose} className='Modal lg'>
                <div className='container'>
                    <BreadCrumb first_crumb={'Product Details'} second_crumb={selectedProduct?.name}/>
                    <form action='#' onSubmit={(e) => handleSubmit(e)}>
                        {selectedProduct?.id ?
                        <div className='vendor-details'>
                            <div className='input-box'>
                                <span className='details'>Product Slug</span>
                                <input type='text'
                                       placeholder='Enter Name of the Product Slug'
                                       name='slug'
                                       value={formData?.slug}
                                       onChange={handleChange}
                                       required/>
                                {errors.slug &&  <span className="error-message">{errors.slug} </span>}
                            </div>
                            <div className='input-box'>
                                <span className='details'>Minimum Age</span>
                                <input type='number'
                                       placeholder='Minimum Age Eligible'
                                       name='min_age'
                                       value={formData?.min_age}
                                       onChange={handleChange}
                                       />
                                {errors.min_age &&  <span className="error-message">{errors.min_age} </span>}
                            </div>
                            <div className='input-box'>
                                <span className='details'>Maximum Age</span>
                                <input type='number'
                                       placeholder='Maximum Age'
                                       name='max_age'
                                       value={formData?.max_age}
                                       onChange={handleChange}
                                       />
                                {errors.max_age &&  <span className="error-message">{errors.max_age} </span>}
                            </div>
                            <div className='input-box'>
                                <span className='details'>Select Discount</span>
                                <Select name={"discount_id"}
                                    defaultValue={formData?.discount_id}
                                    value={formData?.discount_id}
                                    onChange={(val)=>handleChange({target:{name:'discount_id',value:val}})}
                                    options={discountCouponDropdown} styles={customStyles}
                                />
                                {errors.discount_id &&  <span className="error-message">{errors.discount_id} </span>}
                            </div>
                        </div> : ''}
                        {saveStatus==='success' ? <div className="success-message">Product Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }


                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit' disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    </form>
                </div>

            </Modal>
        </div>
    );
};

export default ProductDetailUpdate;
