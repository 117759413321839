import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import {actionToGetDiscountCouponForProductApiCall} from "../../../store/action/inventoryActions";

import "react-datepicker/dist/react-datepicker.css";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {actionToGetUsersListApiCall} from "../../../store/action/usersActions";
import SelectCompanyDropdown from "../../auth/SelectCompanyDropdown";
import SelectUserDropdown from "../../users/users/SelectUserDropdown";
import DateField from "../../Layout/Form/DateField";
import {toast} from "react-toastify";

const AddDiscount = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const minDate = useState(new Date());
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [formData, setFormData] = useState({ source:auth?.source,expired_on:new Date()});
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };


    useEffectOnce(()=>{
        dispatch(actionToGetUsersListApiCall({not_customer: true}));
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());

        // Validate form data
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            if (!obj['discount_percentage']?.trim()) {
                delete obj['discount_percentage'];
            }
            obj['created_by'] = auth?.id;

            try {
                const res = await dispatch(callInsertDataWithLogFunction({
                    tableName: 'discount_coupon',
                    data: obj,
                    userId: auth?.id,
                    eventTypeId
                }));

                const status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                // Notify user based on status
                if (status === 'success') {
                    toast.success('Discount coupon added successfully!');
                    dispatch(actionToGetDiscountCouponForProductApiCall());
                    resetForm();
                } else {
                    toast.error('Failed to add discount coupon. Please try again.');
                }
            } catch (error) {
                console.error('Error during coupon submission:', error);
                setSaveStatus('fail');
                toast.error('An error occurred while adding the discount coupon.');
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.');
        }
    };

    const resetForm = () => {
        document.getElementById('discount-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Discount name is required';
        }
        if (!data.type.trim()) {
            errors.type = 'Discount Type is required';
        }
        if (!data.amount_type.trim()) {
            errors.amount_type = 'Amount Type is required';
        }
        if (data.amount_type=== 'percentage' && !data.discount_percentage.trim()) {
            errors.discount_percentage = 'Discount Percentage is required';
        }
        if (!data.maximum_discount.trim()) {
            errors.maximum_discount = 'Maximum Discount is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Discount Coupon</div>
                <form onSubmit={handleSubmit} id='discount-form'>
                    <div className='vendor-details'>
                        <SelectCompanyDropdown allowedRoles={allowedRoles} source={formData.source} handleChange={handleChange} />
                        <div className='input-box'>
                            <span className='details'>Discount Name*</span>
                            <input type='text' placeholder='Discount Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Type</span>
                            <select className='select' name='type' >
                                <option value="coupon" >Coupon</option>
                                <option value="discount">Discount</option>
                            </select>
                            {errors.type && <span className="error-message"> {errors.type}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Amount Type</span>
                            <select className='select' name='amount_type' >
                                <option value="percentage" >Percentage</option>
                                <option value="amount">Amount</option>
                            </select>
                            {errors.amount_type && <span className="error-message"> {errors.amount_type}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Discount Percentage</span>
                            <input type='number'  name='discount_percentage' placeholder='Discount Percentage'/>
                            {errors.discount_percentage && <span className="error-message"> {errors.discount_percentage}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Maximum Discount</span>
                            <input type='number'  name='maximum_discount' placeholder='Maximum Discount'/>
                            {errors.maximum_discount && <span className="error-message"> {errors.maximum_discount}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Message</span>
                            <textarea className='text-area' name='message' placeholder='Message'/>
                            {errors.message && <span className="error-message">{errors.message} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Associated Users</span>
                            <SelectUserDropdown name={'associated_with'}  errors={errors} user_id={formData.associated_with} handleChange={handleChange}/>
                            {errors.associated_with && <span className="error-message">{errors.associated_with} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Expired On</span>
                            <DateField
                                showIcon
                                name={'expired_on'}
                                minDate={minDate}
                                dateFormat="yyyy-MM-dd"
                                selected={formData?.expired_on}
                                placeholder='Expired on'
                                onChange={(date) => handleChange({target:{name:'expired_on',value:date}})}
                            />
                            {errors.expired_on && <span className="error-message">{errors.expired_on} </span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Discount Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddDiscount;
