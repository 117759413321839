import React, {Suspense} from "react";
import "./assets/main.css";
import './assets/fileTree.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./Pages/Login.js";
import AdminLayout from "./Components/Layout/AdminLayout.js";
import Inventory from "./Pages/Inventory.js";
import RequireAuth from "./Components/auth/RequireAuth.js";
import useAuth from "./hooks/useAuth.js";
import {useEffectOnce} from "./hooks/useEffectOnce.js";
import PurchaseManagement from "./Components/inventory/purchase/PurchaseManagement.js";
import DefectiveProducts from "./Components/inventory/defective/DefectiveProducts.js";
import Home from "./Pages/Home.js";
import UsersPage from "./Pages/UsersPage";
import {parseJwt} from "./store/utility/jwtUtils";
import SettingsPage from "./Pages/SettingsPage";
import CurriculumPage from "./Pages/CurriculumPage";
import {actionToGetUserRoleApiCall} from "./store/action/usersActions";
import {useDispatch, useSelector} from "react-redux";
import SalesPage from "./Pages/SalesPage";
import GalleryPage from "./Pages/GalleryPage";
import Unauthorized from "./Components/auth/Unauthorized";
import {setHeaderForAuthorization} from "./hooks/api/ApiConfig";
// if you want to show the loader when React loads data again
const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)
function App() {
  const { setAuth } = useAuth();
  const dispatch = useDispatch();
  const ROLES = useSelector((state) =>  state.webSetting.roleList);
  const authorized = async()=>{
    if(localStorage.getItem('accessToken')){
      const data =localStorage.getItem('accessToken');
      if (data ) {
        setHeaderForAuthorization(data);
        let user =  await parseJwt(data);
        setAuth({...user});
      }
    }
  }
  useEffectOnce(()=>{
    dispatch(actionToGetUserRoleApiCall());
    authorized();
  })
  return (
      <>
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="">
                <Route path="" exact element={<Navigate to="home" replace />} />
                <Route exact path="login" name="Login Page" element={<Login />} />
                <Route exact path="unauthorized" name="Unauthrize Page" element={<Unauthorized />} />
                <Route exact path="purchase" element={<PurchaseManagement />}/>
                <Route exact path="damaged" element={<DefectiveProducts />}/>

                <Route path="" element={<AdminLayout />}>
                  <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin,ROLES.Admin,ROLES.WebsiteEditor,ROLES.InventoryManager,ROLES.SalesTeam,ROLES.Trainer]} />}>
                    <Route exact path="home" name="Home Page" element={<Home />} />
                    <Route exact path="gallery" name="Home Page" element={<GalleryPage />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin,ROLES.Admin,ROLES.WebsiteEditor,ROLES.InventoryManager]} />}>
                    <Route exact path="inventory/*" name="Inventory Page" element={<Inventory />} />
                    <Route exact path="website-setting/*" name="Settings Page" element={<SettingsPage />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin,ROLES.Admin,ROLES.Trainer]} />}>
                    <Route exact path="curriculum/*" name="Curriculum Page" element={<CurriculumPage />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin,ROLES.Admin]} />}>
                    <Route exact path="users/*" name="User Page" element={<UsersPage />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin,ROLES.Admin,ROLES.SalesTeam]} />}>
                    <Route exact path="sales/*" name="User Page" element={<SalesPage />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </>
  );
}

export default App;
