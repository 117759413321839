import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';


function PurchaseForm() {

    return (
        <div className="inventory-form">
            <div className={"inventory-heading"}><h4>Purchase Management</h4></div>
                <form>
                    <div className="row text-center mt-5 fw-bold">
                        <div>
                            <label className={"form-label"}>Item ID: </label>
                            <input
                                className={"form-control"}
                                autoComplete="off"
                                name="title"
                                placeholder="Input Item ID"
                            />
                            <span name="title" component="span" className={"form-text"}/>
                        </div>
                    </div>
                    <div className="row text-center mt-5 fw-bold">
                        <div>
                            <label className={"form-label"}>Purchase Invoice ID: </label>
                            <input
                                className={"form-control"}
                                autoComplete="off"
                                name="title"
                                placeholder="Input Purchase Invoice ID"
                            />
                            <span name="title" component="span" className={"form-text"}/>
                        </div>
                    </div>
                    <div className="row text-center mt-5 fw-bold">
                        <div>
                            <label className={"form-label"}>Quantity Received </label>
                            <input
                                className={"form-control"}
                                autoComplete="off"
                                name="title"
                                placeholder="Input Qty. Received"
                            />
                        </div>
                    </div>
                    <div className="row text-center mt-5 fw-bold">
                            <div>
                                <label className={"form-label"}>Purchase Price : </label>
                                <input
                                    className={"form-control"}
                                    autoComplete="off"
                                    name="title"
                                    placeholder="Input Purchase Price"
                                />
                                <span name="title" component="span" className={"form-text"}/>
                            </div>
                    </div>
                    <div className="row text-center mt-5 fw-bold">
                        <div className={"col-md-12"}>
                            <button type="submit" className={"btn btn-primary"}>Submit</button>
                        </div>
                    </div>
                </form>
        </div>
    );
}

export default PurchaseForm;
