import React, { useState, useEffect } from 'react';
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import Modal from "react-modal";
import SelectProductDropdown from "../product/SelectProductDropdown";
import { makeApiCall } from "../../../hooks/api/makeApiCall";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

function EditProductComponents({ isOpen, onRequestClose, isEditMode, selectedProduct, handleExternalFetch }) {
    const [products, setProducts] = useState([{ product_id: '', quantity: 1 }]);
    const [isChanged, setIsChanged] = useState(false);  // Track changes
    const productComponentData = useSelector((state) => state.inventory?.productComponentData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (productComponentData) {
            const initialProducts = productComponentData.map(product => ({
                product_id: product.product_component_id,
                quantity: product.quantity
            }));
            setProducts(initialProducts);
        }
    }, [productComponentData]);

    const handleProductChange = (index, event) => {
        const { name, value } = event.target;
        const updatedProducts = [...products];
        updatedProducts[index][name] = name === 'quantity' ? parseFloat(value) : value;
        setProducts(updatedProducts);
        setIsChanged(true);  // Mark as changed
    };

    const addProduct = () => {
        const hasDuplicate = products.some((product, index, self) =>
            self.findIndex(p => p.product_id === product.product_id) !== index
        );

        if (!hasDuplicate) {
            setProducts([...products, { product_id: '', quantity: 1 }]);
            setIsChanged(true);
        }
    };

    const removeProduct = (index) => {
        const updatedProducts = products.filter((_, i) => i !== index);
        setProducts(updatedProducts);
        setIsChanged(true);  // Mark as changed
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const validProducts = products.filter(product => product.product_id);

        const unchangedProducts = [];
        const productsToUpdate = [];
        const productsToDelete = [];
        const filteredValidProducts = [];  // Only new products to be inserted

        // Find unchanged, update, and new products
        validProducts.forEach(validProduct => {
            const matchingProduct = productComponentData.find(
                p => p.product_component_id === validProduct.product_id
            );

            if (matchingProduct) {
                if (matchingProduct.quantity === validProduct.quantity) {
                    unchangedProducts.push(validProduct);
                } else {
                    productsToUpdate.push(validProduct);  // Quantity changed, flag for update
                }
            } else {
                // New product not found in existing data
                filteredValidProducts.push(validProduct);
            }
        });

        // Find products that need to be deleted
        productComponentData.forEach(existingProduct => {
            const isInValidProducts = validProducts.some(
                p => p.product_id === existingProduct.product_component_id
            );
            if (!isInValidProducts) {
                productsToDelete.push(existingProduct);
            }
        });

        if (filteredValidProducts.length > 0 || productsToUpdate.length > 0 || productsToDelete.length > 0) {
            const payload = {
                productComponent: filteredValidProducts,
                product_id: selectedProduct?.id,
                productsToUpdate,
                productsToDelete
            };
            makeApiCall('/products/add-product-component', null, dispatch, payload).then(response => {
                handleExternalFetch?.();
                onRequestClose();
            });
        }
    };


    const isDuplicateEntry = () => {
        const ids = products.map(product => product.product_id);
        return new Set(ids).size !== ids.length;
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='create-order-modal'>
            <form onSubmit={handleSubmit}>
                <div className="description-container max-h-[80vh] overflow-y-auto p-5 bg-white rounded-lg w-[90%]">
                    <BreadCrumb first_crumb={isEditMode ? 'Edit Order' : 'Create Order'} />

                    <table className='products-table'>
                        <thead>
                        <tr>
                            <th className='w-88'>Component Name</th>
                            <th className='w-28'>Quantity</th>
                            <th className='w-12'>Action</th>
                        </tr>
                        </thead>
                        {products.map((product, index) => (
                            <tbody key={index}>
                            <tr>
                                <td>
                                    <SelectProductDropdown
                                        name="product_id"
                                        placeholder="Product Name"
                                        product_id={product.product_id}
                                        index={index}
                                        handleChange={handleProductChange}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        name="quantity"
                                        value={product.quantity}
                                        min="1"
                                        onChange={(event) => handleProductChange(index, event)}
                                        required
                                    />
                                </td>
                                <td>
                                    <button className='action-button' onClick={() => removeProduct(index)} type="button">
                                        <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                    <button className='product-submit-button rounded-xl p-2 m-2' onClick={addProduct} type="button">
                        <FontAwesomeIcon className='action-icon' icon={faPlus} /> Products
                    </button>
                    {isDuplicateEntry() && <div className="error-message">Duplicate products are not allowed.</div>}
                </div>
                <div className='buttons'>
                    <div className='button'>
                        <button
                            type="submit"
                            className='submit-button'
                            disabled={!isChanged || isDuplicateEntry()}  // Disable if no change or duplicate
                        >
                            {isEditMode ? 'Update' : 'Add'}
                        </button>
                    </div>
                    <div className='button'>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}

export default EditProductComponents;
