import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PreLoader from "../../loader/PreLoader";
import EditDiscount from "./EditDiscount";
import swal from "sweetalert";
import {callSoftDeleteDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {actionToGetDiscountCouponForProductApiCall} from "../../../store/action/inventoryActions";
export const DiscountTable = () => {
    const [isEditDiscountOpen, setIsEditDiscountOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const discountCouponListData = useSelector((state) =>  state.inventory.discountCouponListData);
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);

    useEffectOnce(()=>{
        dispatch(actionToGetDiscountCouponForProductApiCall());
    })

    const deleteDiscount = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Discount "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'discount_coupon',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetDiscountCouponForProductApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(discountCouponListData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === discountCouponListData.length);
    };
    return(
        <>
            {
                discountCouponListData === 'loading' ?
                    <PreLoader/>
                    :
        <div className="table">
            <div className="row-header">
                <div className="seven-column-cell">
                    <input className='checkbox' type="checkbox"
                           checked={selectAll}
                           onChange={()=>handleSelectAll()}/>
                    Discount Name
                </div>
                <div className="seven-column-cell"> Type</div>
                <div className="seven-column-cell"> Amount Type</div>
                <div className="seven-column-cell"> Discount Percentage</div>
                <div className="seven-column-cell"> Maximum Discount</div>
                <div className="seven-column-cell"> Associated with user</div>
                <div className="seven-column-cell"> Action</div>
            </div>
            <Virtuoso style={{ height: '600px' }} totalCount={discountCouponListData.length} itemContent={index =>
                <div className="table-row">
                    <div className="seven-column-cell">
                        <input className='checkbox' type="checkbox"
                               checked={selectedRows.includes(discountCouponListData[index]?.id)}
                               onChange={() => handleSelectRow(discountCouponListData[index]?.id)}/>
                        {discountCouponListData[index]?.name}
                    </div>
                    <div className="seven-column-cell"> {discountCouponListData[index]?.type} </div>
                    <div className="seven-column-cell"> {discountCouponListData[index]?.amount_type} </div>
                    <div className="seven-column-cell"> {discountCouponListData[index]?.discount_percentage} </div>
                    <div className="seven-column-cell"> {discountCouponListData[index]?.maximum_discount} </div>
                    <div className="seven-column-cell"> {discountCouponListData[index]?.associated_with_user} </div>
                    <div className="seven-column-cell">
                        <div className='action-components'>
                            <div className='action-buttons'>
                                <Tippy content='Edit'>
                                <button className='action-button' onClick={() => {setIsEditDiscountOpen(true); setSelectedIndex(index);}} >
                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                </button></Tippy>
                            </div>
                            <div className='action-buttons'>
                                <Tippy content='Delete'>
                                <button onClick={()=>deleteDiscount(discountCouponListData[index])} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                </button></Tippy>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        </div>
            }
            <EditDiscount isOpen={isEditDiscountOpen} selectedData={discountCouponListData[selectedIndex]} onRequestClose={() => {setIsEditDiscountOpen(false); setSelectedIndex(false);}} />
        </>
    )
}
