import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import OrderPackingForm from "./OrderPackingForm";
import OrderTrackingForm from "./OrderTrackingForm";
import OrderDeliveryForm from "./OrderDeliveryForm";
import { makeApiCall } from "../../../hooks/api/makeApiCall";
import OrderItemPrintList from "./OrderItemPrintList";

const OrderStatusUpdate = (props) => {
    const selectedOrder = props.selectedOrder;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [initialData, setInitialData] = useState({});
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const [showPackingModal, setShowPackingModal] = useState(false);
    const [file, setFile] = useState(null);  // New state for file


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm(formData);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            try {
                if (formData.status === 'Delivered' || (formData.status === 'Partial Dispatch' && formData.dispatch_type === 'Delivered')){
                    // Initialize FormData object
                    const formPayload = new FormData();

                // Append all formData fields to FormData
                for (const key in formData) {
                    formPayload.append(key, formData[key]);
                }

                // Append file and folderName to FormData if file exists
                if (file) {
                    let folderName = selectedOrder?.school_name ? 'Schools/'+selectedOrder?.school_name : 'Users/'+selectedOrder?.user_name;
                    formPayload.append('folderName', folderName);
                    formPayload.append('receiving_receipt_file', file);
                }
                // Make the API call with FormData
                await makeApiCall('/products/update-order-status', null, dispatch, formPayload, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }else{
                // Make the API call with FormData
               await makeApiCall('/products/update-order-status', null, dispatch, {payload:formData});
            }

                setSaveStatus('success');
                toast.success('Status Updated successfully!!');
                if(formData.status === 'Processing' || (formData.status === 'Partial Dispatch' && formData.dispatch_type==='Packing' ))
                setShowPackingModal(true); // Open the OrderPackingForm modal
            } catch (error) {
                setSaveStatus('fail');
                toast.error('Something went wrong. Please try again.');
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.');
        }
    };
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    useEffect(() => {
        setFormData(selectedOrder);
        setInitialData(selectedOrder); // Save initial form data for comparison
    }, [selectedOrder]);

    const validateForm = (data) => {
        const errors = {};
        if (!data.status?.trim()) {
            errors.status = 'Order status is required';
        }
        if (data.status === 'Processing' && !data.packing_list) {
            errors.packing_list = 'Packing details are required for processing status';
        }
        if (data.status === 'Processing' && !data.description) {
            errors.description = 'Packing description is required for processing status';
        }
        if (data.status === 'Partial Dispatch' && !data.dispatch_type) {
            errors.dispatch_type = 'Dispatch type is required for partial dispatch status';
        }
        if (data.status === 'Partial Dispatch' && data.dispatch_type==='Packing' && !data.packing_list) {
            errors.packing_list = 'Packing details are required for processing status';
        }
        if (data.status === 'Partial Dispatch' && data.dispatch_type==='Shipped' && !data.tracking_number) {
            errors.tracking_number = 'Tracking number is required for shipped status';
        }
       if (data.status === 'Partial Dispatch' && data.dispatch_type==='Delivered' && !data.delivered_date) {
           errors.delivered_date = 'Delivery date is required for delivered status';
       }
        if (data.status === 'Shipped' && !data.tracking_number) {
            errors.tracking_number = 'Tracking number is required for shipped status';
        }
        if (data.status === 'Delivered' && !data.delivered_date) {
            errors.delivered_date = 'Delivery date is required for delivered status';
        }
        return errors;
    };

    const hasFormChanged = () => {
        return JSON.stringify(formData) !== JSON.stringify(initialData);
    };

    const getOrderStatusUpdateForm = (type) => {
        switch (type) {
            case 'Processing':
                return <OrderPackingForm formData={formData} handleChange={handleChange} errors={errors} />;
            case 'Partial Dispatch':
                return (
                    <div className='input-box'>
                        <span className='details'>Partial Dispatch Type</span>
                        <select className='select mt-2' name='dispatch_type' onChange={handleChange}>
                            <option>Please select Dispatch Status</option>
                            <option value="Packing" selected={formData?.dispatch_type === "Packing"}>Packing</option>
                            <option value="Shipped" selected={formData?.dispatch_type === "Shipped"}>Shipped</option>
                            <option value="Delivered" selected={formData?.dispatch_type === "Delivered"}>Delivered</option>
                        </select>
                        {errors.dispatch_type && <span className="error-message">{errors.dispatch_type}</span>}
                    </div>
                );
            case 'Shipped':
                return <OrderTrackingForm formData={formData} handleChange={handleChange} errors={errors} />;
            case 'Delivered':
                return <OrderDeliveryForm formData={formData} handleChange={handleChange} handleFileChange={handleFileChange} errors={errors} />;
            default:
                return null;
        }
    };

    const getPartialDispatchForm = (type) => {
        switch (type) {
            case 'Packing':
                return <OrderPackingForm formData={formData} handleChange={handleChange} errors={errors} />;
            case 'Shipped':
                return <OrderTrackingForm formData={formData} handleChange={handleChange} errors={errors} />;
            case 'Delivered':
                return <OrderDeliveryForm formData={formData} handleChange={handleChange} handleFileChange={handleFileChange} errors={errors} />;
            default:
                return null;
        }
    };
    const handlePrint = () => {
        const printContent = document.getElementById('printable-form').innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload();
    };

    return (
        <>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='Modal'>
                <div className="container max-h-[90vh] overflow-y-auto rounded-lg w-[90%]">
                    <div className='title'>Update Order Status</div>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className='mt-3'>
                            <div className='input-box'>
                                <span className='details'>Order Status</span>
                                <select className='select mt-2' name='status' onChange={handleChange} value={formData.status || ''}>
                                    <option>Please select Order Status</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Partial Dispatch">Partial Dispatch</option>
                                    <option value="Shipped">Shipped</option>
                                    <option value="Delivered">Delivered</option>
                                </select>
                                {errors.status && <span className="error-message">{errors.status}</span>}
                            </div>
                            {getOrderStatusUpdateForm(formData?.status)}
                            {formData?.status === 'Partial Dispatch' && getPartialDispatchForm(formData.dispatch_type)}
                        </div>
                        <div className='buttons'>
                            <button className='submit-button' type='submit' disabled={!hasFormChanged()}>Update</button>
                            <button className='close-button' type='button' onClick={props.onRequestClose}>Close</button>
                        </div>
                        {saveStatus === 'success' ? (
                            <div className="success-message">Status Updated successfully!!</div>
                        ) : saveStatus === 'fail' && (
                            <div className='failure-message'>Something went Wrong</div>
                        )}
                    </form>
                </div>
            </Modal>

            {/* Print Modal */}
            <Modal isOpen={showPackingModal} onRequestClose={() => setShowPackingModal(false)} className='print-modal'>
                <div className="container max-h-[90vh] overflow-y-auto rounded-lg w-[90%]" >
                    <div id='printable-form'>
                        <OrderItemPrintList formData={formData} handleChange={handleChange} errors={errors} disabled={true}/></div>
                    <div className='buttons'>
                        <button className='submit-button' onClick={handlePrint}>Print</button>
                        <button className='close-button' type='button' onClick={() => setShowPackingModal(false)}>Close</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default OrderStatusUpdate;
