import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import UsersComponent from "../Components/users/UsersComponent";
export default function UsersPage() {
    return (
        <>
            <Routes>
                <Route path="" exact element={<Navigate to="users" replace />} />
                <Route path="/:tabName" element={<UsersComponent />} />
            </Routes>
        </>
    )
}