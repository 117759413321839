import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeApiCall } from './api/makeApiCall';

export const useFetchData = (apiUrl, initialData = {}, advanceFilter = {}) => {
    const [data, setData] = useState(initialData);
    const [settings, setSettings] = useState({
        search: '',
        sortField: 'id',
        sortDirection: 'desc',
        page: 1,
        pageSize: 10,
    });
    const dispatch = useDispatch();

    const fetchData = (payload = {}) => {
        const updatedSettings = { ...settings, ...payload }; // Merge with current settings
        setSettings(updatedSettings); // Update settings state
        const params = { ...updatedSettings, advanceFilter };

        // Make API call
        makeApiCall(apiUrl, null, null, params).then((response) => setData(response));
    };

    return { data, settings, setSettings, fetchData };
};
