import React from 'react'


//import DataTable from "react-data-table-component";

function DefectiveTable() {

    return (
        <div className='inventory-form'>
            {/*<DataTable*/}
            {/*    columns={columns}*/}
            {/*    data={data}*/}
            {/*    fixedHeader*/}
            {/*    pagination*/}
            {/*></DataTable>*/}
        </div>
    )
}

export default DefectiveTable;