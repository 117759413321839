import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelect from 'react-select/creatable';
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import {actionToGetCategoriesApiCall} from "../../../store/action/inventoryActions";
import useAuth from "../../../hooks/useAuth";

const CategoryDropdown=(props) =>{
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const errors =props.errors;
    const source =props.source;
    const listOfCategories = useSelector((state) => { return state.inventory?.categoryData});
    const [selectedDropDown, setSelectedDropDown] = useState(null);
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const [categoryDropdown,setCategoryDropdown]= useState([]);
    useEffect(() => {
        let categoryArray = [];
        listOfCategories !== 'loading' && listOfCategories?.map(val=>{
            if(!source || (source && val.source == source)){
                categoryArray.push({value:val.id,label:val.name});

                if(props?.category == val.id) setSelectedDropDown({value:val.id,label:val.name});
            }
        })
        setCategoryDropdown(categoryArray);
    }, [listOfCategories,source,props.category]);
    const handleCreate = (val) => {
        if (val) {
            let obj ={name:val,created_by:auth?.id,source:source,slug:val.toLowerCase()?.split(' ').join('-')};
            dispatch(callInsertDataWithLogFunction({tableName:'categories',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                if(res?.id){
                    props.handleChange({target:{name:props?.name,value: res.id}});
                    dispatch(actionToGetCategoriesApiCall());
                }
            })
        }
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: '50px',
            width: '95%',
            borderRadius: '5px',
            border: state.isFocused ? '1px solid #efdda8' : '1px solid #ccc',
            paddingLeft: '5px',
            fontSize: '13px',
            boxShadow: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
                borderColor: '#efdda8'
            },

        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            width: '95%',
            marginTop: '2px',
            zIndex: 2,
            overflowY: 'auto',
            maxHeight: '150px',

        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#ccc',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#999'
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
            backgroundColor: state.isFocused ? '#efdda8' : '#fff',
            color: '#000',
            '&:hover': {
                backgroundColor: '#efdda8',
                color: '#000'
            }
        })
    };
    return(
        <div className='input-box'>
            <span className='details'>Select Category</span>
            <CreatableSelect name={props.name}
                    value={selectedDropDown}
                    onCreateOption={handleCreate}
                    onChange={(e)=>props.handleChange({target:{name:props.name,value:e.value}})}
                    options={categoryDropdown} styles={customStyles} placeholder='Select Category'
            />
            {errors[props.name] && <span className="error-message">{errors[props.name]} </span> }
        </div>
    )
}
export default CategoryDropdown;
