import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelectField from "../../Layout/Form/CreatableSelectField";
import {actionToGetClientLeadApiCall} from "../../../store/action/salesAction";
import SchoolClientLeadForm from "./SchoolClientLeadForm";

const SelectSchoolClientLeadDropdown = ({name,handleChange,school_client_lead_id,school_id,placeholder,error,smallText}) =>{
    const dispatch=useDispatch();
    const clientLeadList = useSelector((state) =>  state.sales?.clientLeadList);
    const [addModalName, setAddModalName] = useState(false);
    const [selectedSchoolClientLead, setSelectedSchoolClientLead] = useState(false);
    const [dataDropdown, setDataDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState(null);

    useEffect(() => {
        let componentArray = [];
        let selectedData = null;
        clientLeadList !== 'loading'  && clientLeadList && clientLeadList?.map(val=>{
                componentArray.push({value:val.id,label:val.name});
                if(school_client_lead_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setDataDropdown([...componentArray]);
    }, [clientLeadList,school_client_lead_id]);
    const handleCreate = (val) => {
        setSelectedSchoolClientLead({name:val,school_id:school_id});
        setAddModalName('add_client_lead');
    };
    const handleFetchData = (res)=>{
        dispatch(actionToGetClientLeadApiCall({school_id:school_id})).then(response=>{
            handleChange({target:{name:name,value:res.id}});
            }
        );
    }
    const customStyles = {controlHeight:'50px', menuListMaxHeight:'150px'}

    return(<>
        <CreatableSelectField name={name} smallText={smallText} label={placeholder} customStyles={customStyles} placeholder={placeholder} error={error} dataDropdown={dataDropdown}
                                  handleCreate={handleCreate} selectedDropDown={selectedDropDown} onChange={handleChange}/>
        {addModalName === 'add_client_lead' && (
            <SchoolClientLeadForm
                handleExternalFetch={handleFetchData}
                isOpen={addModalName=== 'add_client_lead'}
                onRequestClose={() => setAddModalName(false)}
                selectedData={selectedSchoolClientLead}
                isEditMode={false}
            />
        )}
    </>
    )
}
export default SelectSchoolClientLeadDropdown;
