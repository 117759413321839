import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import SelectGradeDropdown from "../grade/SelectGradeDropdown";
import SelectSubjectDropdown from "../subject/SelectSubjectDropdown";
import SelectGradeSubjectTopicDropdown from "../topic/SelectGradeSubjectTopicDropdown";
import {
    actionToGetCurriculumListApiCall, actionToGetGradesApiCall,
    actionToGetGradeSubjectTopicApiCall, actionToGetSubjectsApiCall
} from "../../../store/action/curriculumAction";
import {Editor} from "@tinymce/tinymce-react";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import {toast} from "react-toastify";

const AddCurriculum = ({ isOpen, onRequestClose,selectedProduct }) => {
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const [formData, setFormData] = useState({  });
    const {auth} = useAuth();
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const formDetail = new FormData(e.target);
        const obj = Object.fromEntries(formDetail.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            obj['description'] = formData['description'];
            let payload = { grade_subject_topic_id: obj['grade_subject_topic_id'] };
            delete obj['grade_subject_topic_id'];
            delete obj['grade_id'];
            delete obj['subject_id'];

            dispatch(callInsertDataWithLogFunction({
                tableName: 'curriculum',
                data: obj,
                userId: auth?.id,
                eventTypeId
            })).then((res) => {
                if (res?.id) {
                    payload['curriculum_id'] = res?.id;

                    if (selectedProduct?.id) {
                        dispatch(callInsertDataWithLogFunction({
                            tableName: 'product_curriculum',
                            data: { curriculum_id: res.id, product_id: selectedProduct['id'] },
                            userId: auth?.id,
                            eventTypeId
                        }));
                    }

                    dispatch(callInsertDataWithLogFunction({
                        tableName: 'curriculum_grade_subject_topic',
                        data: payload,
                        userId: auth?.id,
                        eventTypeId
                    })).then(() => {
                        dispatch(actionToGetCurriculumListApiCall());
                    });
                }

                let status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                if (status === 'success') {
                    toast.success('Curriculum inserted successfully!');  // Success notification
                    resetForm();
                } else {
                    toast.error('Failed to insert curriculum.');  // Failure notification
                }
            }).catch(() => {
                toast.error('An error occurred. Please try again.');  // Failure notification for exceptions
            });
        } else {
            setSaveStatus('fail');
            toast.error('Please fill all required fields.');  // Validation error notification
        }
    };


    const resetForm = () => {
        document.getElementById('curriculum-form')?.reset();
        setErrors({})
        setFormData({});
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Curriculum Name is required';
        }

        if (!data.slug.trim()) {
            errors.slug = 'Slug is required';
        }

        return errors;
    };
    useEffect(() => {
        if(isOpen){
            resetForm();
            setSaveStatus({});
            dispatch(actionToGetGradeSubjectTopicApiCall());
            dispatch(actionToGetGradesApiCall());
            dispatch(actionToGetSubjectsApiCall());
        }
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={'Add Product Curriculum'} second_crumb={selectedProduct?.name}/>
                {/*<div className='title'>Add {selectedProduct?.id ? 'Product' : '' } Curriculum</div>*/}
                <form onSubmit={handleSubmit} id='curriculum-form'>
                    <div className='vendor-details'>
                        <SelectGradeDropdown errors={errors} name={'grade_id'} grade_id={formData.grade_id} handleChange={handleChange}/>
                        <SelectSubjectDropdown errors={errors} name={'subject_id'} subject_id={formData.subject_id} handleChange={handleChange}/>
                        {formData.grade_id &&formData.subject_id && <SelectGradeSubjectTopicDropdown errors={errors} name={'grade_subject_topic_id'} subject_id={formData.subject_id} grade_id={formData.grade_id} grade_subject_topic_id={formData.grade_subject_topic_id} handleChange={handleChange}/>}

                        <div className='input-box'>
                            <span className='details'>Name</span>
                            <input type='text'
                                   placeholder='Enter Name of the Product Curriculum'
                                   name='name'
                            />
                            {errors.name &&  <span className="error-message">{errors.name} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Slug</span>
                            <input type='text'
                                   placeholder='Enter Slug of the Product Curriculum'
                                   name='slug'
                            />
                            {errors.slug &&  <span className="error-message">{errors.slug} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Focus</span>
                            <input type='text'
                                   placeholder='Enter Topic name of the Product Curriculum'
                                   name='focus'
                            />
                            {errors.focus &&  <span className="error-message">{errors.focus} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Description</span>
                            <Editor
                                apiKey="nchrb4md98keu69dxd2sjsc25q52blhw3z0ryes84hw6wscr"
                                init={{
                                    height: 300,
                                    plugins: [
                                        'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                                        'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                                        'table', 'emoticons', 'help'
                                    ],
                                    toolbar: 'undo redo | print preview code media fullscreen | styles bold italic | alignleft aligncenter alignright alignjustify |codeformat | ' +
                                        'bullist numlist outdent indent | link image | ' +
                                        'forecolor backcolor emoticons | help',
                                    menu: {
                                        favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                                    },
                                    menubar: 'favs file edit view insert format tools table help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                }}
                                onEditorChange={(content)=>handleChange({target:{name:'description',value:content}})}
                            />
                            {/*<textarea className='text-area' placeholder='Enter Short Description of the Product Curriculum' name='description'/>*/}
                            {errors.description && <span className="error-message">{errors.description} </span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Curriculum Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddCurriculum;
