// components/SchoolTable.js
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from '../../../hooks/useFetchData';
import {callDeleteDataWithLogFunction} from '../../../store/action/commonActions';
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import useAuth from "../../../hooks/useAuth";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import SchoolClientLeadInteractionForm from "./SchoolClientLeadInteractionForm";
import {indianDateFormat} from "../../../store/utility/DatatableUtility";

const SchoolClientLeadInteractionTable = ({setFetchDataTable,fetchDataTable,selectedLead}) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const { data:schoolData, fetchData,settings } = useFetchData(`sales/get-school-client-lead-interaction`, [], { school_client_lead_id: selectedLead?.id });
    const [isEditSchoolOpen, setIsEditSchoolOpen] = useState(false);
    const [selectedSchoolClientLead, setSelectedSchoolClientLead] = useState(null);

    const columns = [
        { name: 'Sr No', selector: row => row.id, selector_field:'id',
            cell: (row,index) => (
                <>
                    {index+1}
                </>
            ),width:'60px'  },
         { name: 'Note', selector: row => row.notes, selector_field:'notes', sortable: true ,width:'150px' },
        { name: 'Current Follow Up Type', selector: row => row.current_follow_up_type_name, selector_field:'current_follow_up_type_name', sortable: true ,width:'150px' },
        { name: 'Interaction Date', selector: row => row.interaction_date && indianDateFormat(row.interaction_date), selector_field:'interaction_date', sortable: true ,width:'150px' },
        { name: 'Next Follow Up Date', selector: row => row.next_follow_up_date && indianDateFormat(row.next_follow_up_date), selector_field:'next_follow_up_date', sortable: true ,width:'150px' },
        { name: 'Next Follow Up Type', selector: row => row.next_follow_up_type_name, selector_field:'next_follow_up_type_name', sortable: true ,width:'150px' },
        { name: 'Order Type', selector: row => row.order_type_name, selector_field:'order_type_name',sortable: true,width:'100px'  },
        { name: 'order_value', selector: row => row.order_value, selector_field:'order_value',sortable: true,width:'100px'  },

        { name: 'Sales Person', selector: row => row.creator, selector_field:'creator',sortable: true,width:'200px' },
        { name: 'Lead Status', selector: row => row.lead_status, selector_field:'lead_status',sortable: true,width:'100px' },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    <TableEditActions
                        row={row} tooltip={'Edit'}
                        onEdit={() => { setSelectedSchoolClientLead(row); setIsEditSchoolOpen(true); }}
                    />
                    <TableDeleteActions
                        row={row}
                        tooltip={'Delete'}
                        onDelete={(row) => dispatch(callDeleteDataWithLogFunction({ tableName: 'school_client_lead_interaction', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                </div>
            ), width: '150px' }
    ];

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);
    return (
        <>
            <DataTableLayout columns={columns} data={schoolData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={schoolData?.total} />
            {isEditSchoolOpen && (
                <SchoolClientLeadInteractionForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditSchoolOpen}
                    onRequestClose={() => setIsEditSchoolOpen(false)}
                    selectedData={selectedSchoolClientLead}
                    isEditMode={true}
                />
            )}
        </>
    );
};

export default SchoolClientLeadInteractionTable;
