import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {actionToGetRegionsApiCall} from "../../../store/action/salesAction";
import {toast} from "react-toastify";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";

const AddRegion = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.sales.salesEventTypeId);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const dispatch = useDispatch();
    const {auth} = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(callInsertDataWithLogFunction({
                tableName: 'region',
                data: obj,
                userId: auth?.id,
                eventTypeId
            })).then(res => {
                let status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);
                dispatch(actionToGetRegionsApiCall());

                if (status === 'success') {
                    resetForm();
                    toast.success('Region added successfully!'); // Success notification
                } else {
                    toast.error('Failed to add region.'); // Failure notification
                }
            });
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.'); // Validation error notification
        }
    };

    const resetForm = () => {
        document.getElementById('region-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Region is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={'Add Region'}/>
                <form onSubmit={handleSubmit} id='region-form'>
                    <div className='mt-3'>
                        <div className='input-box'>
                            <span className='details'>Region Name</span>
                            <input type='text' placeholder='Region Name*' name='name' className='common-text-area mt-2'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        {/*<div className='input-box'>*/}
                        {/*    <span className='details'>Slug</span>*/}
                        {/*    <input type='text' placeholder='slug' name='name' className='form-input'/>*/}
                        {/*</div>*/}
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Region Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddRegion;
