import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {
    actionToDeleteAwsObject,
    actionToDeleteStemcityAwsObject,
    callDeleteDataWithLogFunction
} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import {actionToGetProductCurriculumImagesApiCall} from "../../../../store/action/inventoryActions";
import AddCurriculumContent from "../../../Curriculum/curriculum/AddCurriculumContent";

const ProductCurriculumImage = (props) => {
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true);
    const productCurriculumImageData = useSelector((state) =>  state.inventory?.productCurriculumImageData);
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const selectedProductCurriculum = props.selectedProductCurriculum;
    const selectedProduct = props.selectedProduct;

    const deleteProductCurriculumPhoto = (id,imgUrl) =>{
        swal({
            title: "Are you sure?",
            text: "You want to delete this product curriculum photo ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'curriculum_content',id:id,userId:auth?.id,eventTypeId})).then(() => {
                        if(selectedProduct.company_name.toLowerCase() === 'stemcity'){
                            let imageUrl = imgUrl.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','');
                            dispatch(actionToDeleteStemcityAwsObject({key:imageUrl}))
                        }else{
                            let imageUrl = imgUrl.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/','');
                            dispatch(actionToDeleteAwsObject({key:imageUrl}))
                        }

                        dispatch(actionToGetProductCurriculumImagesApiCall({product_curriculum_id:selectedProductCurriculum.id}))
                    })
                }
            });

    }
    useEffect(() => {
        setLoading(true);
       (selectedProductCurriculum?.id && props.isOpen) &&
        dispatch(actionToGetProductCurriculumImagesApiCall({product_curriculum_id:selectedProductCurriculum.id})).then(()=>{
            setLoading(false);
        });
    }, [selectedProductCurriculum]);
    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='Modal'>
                {loading ? <>Loading ...</> :
                <div className='container'>
                    <div className='title'>Product curriculum File</div>
                    <AddCurriculumContent selectedCurriculum={selectedProductCurriculum} callFrom={'product-curriculum-image'} selectedProduct={selectedProduct}/>
                    <table className="image-table">
                        <thead>
                        <tr>
                            <th>Sr No</th>
                            <th>Image</th>
                            <th>Ordering</th>
                            <th>Type</th>
                            <th>Flipbook Code</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {productCurriculumImageData !== 'loading' && productCurriculumImageData?.map((image,index) => (
                            <tr key={image.id}>
                                <td>{index+1}</td>
                                <td><img src={image.url} alt={`Uploaded ${selectedProductCurriculum?.name} ${index+1}`} className="uploaded-image" /></td>
                                <td>{image.ordering}</td>
                                <td>{image.type}</td>
                                <td>{image.flipbook_code}</td>
                                <td> <FontAwesomeIcon className='action-icon' icon={faTrash} onClick={()=> deleteProductCurriculumPhoto(image.id,image.url)} /></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>}
            </Modal>
        </div>
    );
};

export default ProductCurriculumImage;
