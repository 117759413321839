import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import { actionToGetSubCategoriesApiCall} from "../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import CategoryDropdown from "../category/CategoryDropdown";
import {toast} from "react-toastify";


const EditSubCategory= ({ isOpen,selectedData, onRequestClose }) => {
    const listOfCategories = useSelector((state) => { return state.inventory?.categoryData});
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();

    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        slug: '',
    });

    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(callUpdateDataWithLogFunction({
                tableName: 'sub_categories',
                data: obj,
                id: selectedData?.id,
                userId: auth?.id,
                eventTypeId
            })).then(res => {
                let status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                if (status === 'success') {
                    dispatch(actionToGetSubCategoriesApiCall());
                    toast.success('Sub-category updated successfully!'); // Success toast
                    onRequestClose();
                } else {
                    toast.error('Failed to update sub-category.'); // Error toast
                }
            });
        } else {
            setSaveStatus('fail');
            toast.error('Form submission failed due to validation errors.'); // Validation error toast
        }
    };

    useEffect(() => {
        setFormData(selectedData);
    }, [selectedData]);


    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Sub Category is required';
        }

        if (!data.slug.trim()) {
            errors.slug = 'Slug is required';
        }

        if (!data.category_id.trim()) {
            errors.category_id = 'Main Category is required';
        }

        return errors;

    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Edit Sub-Category</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <CategoryDropdown errors={errors} name={'category_id'} source={formData?.source} category={formData?.category_id} handleChange={handleChange}/>

                        <div className='input-box'>
                            <span className='details'>Sub-Category Name</span>
                            <input type='text' placeholder='Sub-Category Name*'
                                   name='name' className='form-input'
                                   value={formData?.name}
                                   onChange={handleChange}/>
                            {errors?.name && <span className="error-message">{errors.name} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Slug Name</span>
                            <input type='text' name='slug' className='form-input'
                                   placeholder='Slug'
                                   value={formData?.slug}
                                   onChange={handleChange} />
                            {errors?.slug && <span className="error-message"> {errors.slug} </span>}
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Sub category updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};


export default EditSubCategory;
