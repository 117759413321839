import {api, generateDeviceFingerprint, setHeaderForAuthorization} from "../../hooks/api/ApiConfig";
import {USER_ROLE_LIST, USERS_TABLE_LIST} from "../constant/usersConstant";
import {parseJwt} from "../utility/jwtUtils";
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "./commonActions";
import {ROLE_LIST} from "../constant";

export const actionToGetUsersListApiCall = (payload) => async (dispatch) => {
    dispatch({ type: USERS_TABLE_LIST, payload: 'loading'});
    const {data} = await api.post(`users/get-user-list`,payload);
    dispatch({ type: USERS_TABLE_LIST, payload: data});
    return data;
}
export const actionToGetUserRoleApiCall = () => async (dispatch) => {
    dispatch({ type: USER_ROLE_LIST, payload: 'loading'});
    const {data} = await api.post(`users/get-user-role`);
    dispatch({ type: USER_ROLE_LIST, payload: data});
    let roles={};
    await data.map(res=>{
        roles[res.name]=res.id;
    });
    dispatch({ type: ROLE_LIST, payload: roles});
    return data;
}
export const actionToCreateUserAction = (data) => async (dispatch,getState) =>{
    const eventTypeId = getState().users.userEventTypeId;
    data['source'] =  getState().users.userCompanyId;
    return await api.post(`auth/create-user`,{tableName:'users',data,userId:data['created_by'],eventTypeId});
}
export const actionToDeleteUser = (data) => async (dispatch,getState) =>{
    const eventTypeId = getState().users.userEventTypeId;
    //data['source'] =  getState().users.userCompanyId;
    return await dispatch(callSoftDeleteDataWithLogFunction({tableName:'users',id:data['id'],userId:data['created_by'],eventTypeId}));
}
export const actionToUpdateUser = (data,id) => async (dispatch,getState) =>{
    const eventTypeId = getState().users.userEventTypeId;
    return await dispatch(callUpdateDataWithLogFunction({tableName:'users',data,id:id,userId:data['created_by'],eventTypeId}));
}
export const actionToLogin =  (param) => async (dispatch,getState) =>{
    param['deviceFingerprint'] = await generateDeviceFingerprint();
    //param['source'] =  getState().users.userCompanyId;
    return api.post("/auth/login", param)
        .then(async (response) => {
            if (response.data.accessToken) {
                localStorage.setItem("accessToken", response.data.accessToken);
                setHeaderForAuthorization(response.data.accessToken);
                // Parse the JWT token
                const parsedData = parseJwt(response.data.accessToken);
                return parsedData;
            }
            return response.data;
        });
}
export const actionToLogout = () => ()=>{
    return api.delete("/auth/logout")
        .then( () => {
            localStorage.removeItem("accessToken");
            window.location.href='/login';
            return true;
        });
}