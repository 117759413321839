import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetSubjectsApiCall} from "../../../store/action/curriculumAction";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";

const AddSubjects = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const dispatch = useDispatch();
    const {auth} = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            obj['created_by'] = auth?.id;
            dispatch(callInsertDataWithLogFunction({
                tableName: 'subjects',
                data: obj,
                userId: auth?.id,
                eventTypeId
            })).then(res => {
                // Check if response indicates success
                if (res?.success === 1) {
                    dispatch(actionToGetSubjectsApiCall());
                    setSaveStatus('success');
                    toast.success('Subject added successfully!'); // Success notification
                    resetForm();
                } else {
                    setSaveStatus('fail');
                    toast.error('Failed to add subject.'); // Failure notification
                }
            });
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.'); // Validation error notification
        }
    };


    const resetForm = () => {
        document.getElementById('subject-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Subject is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Subject</div>
                <form onSubmit={handleSubmit} id='subject-form'>
                    <div className='mt-3'>
                        <div className='input-box'>
                            <span className='details'>Subject Name</span>
                            <input type='text' placeholder='Subject Name*' name='name' className='common-text-area'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Subject Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddSubjects;
