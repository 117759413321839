import React, { useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PreLoader from "../../loader/PreLoader";
import swal from "sweetalert";
import EditUsers from "./EditUsers";
import {actionToDeleteUser, actionToGetUsersListApiCall} from "../../../store/action/usersActions";
import {capitalizeFirstLetter} from "../../../store/helper/commonHelper";
import useAuth from "../../../hooks/useAuth";

const UsersTable = React.memo(function (){
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const usersData = useSelector((state) =>  state.users?.usersListData);
    useEffectOnce(()=>{
        dispatch(actionToGetUsersListApiCall());
    })

    const deleteUser = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this User "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    let payload  = {id: row.id,created_by:auth?.id};
                    dispatch(actionToDeleteUser(payload)).then(() => {
                        dispatch(actionToGetUsersListApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(usersData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === usersData.length);
    };
    return(
        <>
            <div className='table-container'>
            {
                usersData === 'loading' ?
                    <PreLoader/>
                    :

        <div className="table">
            <div className="user-row-header">
                <div className="seven-column-cell">
                    <input className='checkbox' type="checkbox"
                           checked={selectAll}
                           onChange={handleSelectAll}/>
                    Name
                </div>
                <div className="seven-column-cell">Company</div>
                <div className="seven-column-cell">Role</div>
                <div className="seven-column-cell">Email</div>
                <div className="seven-column-cell">Mobile</div>
                <div className="seven-column-cell">Gender</div>
                <div className="seven-column-cell">Action</div>
            </div>
            <Virtuoso style={{ height: '600px' }} totalCount={usersData.length} itemContent={index =>
                <div className="table-row">
                    <div className="four-column-cell">
                        <input className='checkbox' type="checkbox"
                               checked={selectedRows.includes(usersData[index]?.id)}
                               onChange={() => handleSelectRow(usersData[index]?.id)}/>
                        {usersData[index]?.name}
                    </div>
                    <div className="four-column-cell">{usersData[index]?.company_name}</div>
                    <div className="four-column-cell">{usersData[index]?.role_name}</div>
                    <div className="four-column-cell">{usersData[index]?.email}</div>
                    <div className="four-column-cell">{usersData[index]?.mobile}</div>
                    <div className="four-column-cell">{capitalizeFirstLetter(usersData[index]?.gender)}</div>
                    <div className="four-column-cell">
                        <div className='action-components'>
                            <div className='action-buttons'>
                                <button className='action-button' onClick={() => {setIsEditOpen(true); setSelectedIndex(index)}} >
                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                </button>
                            </div>
                            <div className='action-buttons'>
                                <button onClick={()=>deleteUser(usersData[index])} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        </div>
            }
            <EditUsers isOpen={isEditOpen} selectedUser={usersData[selectedIndex]} onRequestClose={() => setIsEditOpen(false)} />
            </div>
        </>
    )
})

export default UsersTable;
