// utils/validation.js

export const validateForm = (formData, validationRules) => {
    const errors = {};

    for (let field in validationRules) {
        const rules = validationRules[field];
        const value = formData[field] != null ? formData[field].toString().trim() : ''; // Safely handle undefined/null
        const fieldName = rules['field_name'] ?? field;


        // Required field validation
        if (rules.required && (!value || value === '')) {
            errors[field] = `${fieldName} is required`;
        }

        // Email validation
        if (rules.type === 'email' && value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            errors[field] = `Invalid email format`;
        }

        // Phone validation (example: 10-digit number)
        if (rules.type === 'phone' && value && !/^\d{10}$/.test(value)) {
            errors[field] = `Phone number must be 10 digits`;
        }

        // Pincode validation (6 digits)
        if (rules.type === 'pincode' && value && value.length !== 6) {
            errors[field] = `Pincode must be 6 digits`;
        }

        // Max length validation
        if (rules.maxLength && value && value.length > rules.maxLength) {
            errors[field] = `${fieldName} must be less than ${rules.maxLength} characters`;
        }

        // Min length validation
        if (rules.minLength && value && value.length < rules.minLength) {
            errors[field] = `${fieldName} must be at least ${rules.minLength} characters`;
        }
        // Min length amount
        if (rules.amount && !/^\d+(\.\d{1,2})?$/.test(value)) {
            errors[field] = `${fieldName} can have up to 2 decimal places`;
        }
    }

    return errors;
};
