// Reusable Input Component
import React from "react";
const InputField = ({ label, type, name, value, onChange, error, placeholder }) => (
    <div className='input-box'>
        <span className='details'>{label}</span>
        <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className='form-input'
        />
        {error && <span className="error-message">{error}</span>}
    </div>
);
export default InputField;
