import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionToGetMenuDetailApiCall } from "../../../../store/action/webSettingActions";
import { WebsiteSectionTable } from "../section_content/WebsiteSectionTable";
import { SeoSettingTable } from "../seo_setting/SeoSettingTable";

export default function MenuDetail() {
    let { menu_id } = useParams();
    const dispatch = useDispatch();
    const [tabName, setTabName] = useState('content');
    const menuDetail = useSelector((state) => state.webSetting?.menuDetail);


    const getActiveTable = (tab) => {
        switch (tab) {
            case 'content':
                return <WebsiteSectionTable contentData={menuDetail.content} />;
            case 'seo':
                return <SeoSettingTable seoData={menuDetail.seo} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        dispatch(actionToGetMenuDetailApiCall({ id: menu_id }));
    }, [menu_id, dispatch]);

    return (
        <>
            <div className='text-lg font-semibold flex justify-around mt-3 ml-5 mr-5'>
                Menu Name: {menuDetail?.name} <br />
                <div>Component Name: {menuDetail?.component_name}</div>

            </div>
            <div className="pipeline-tabs-container">
                <div
                    className={`pipeline-tab ${tabName === 'content' ? 'pipeline-active' : ''}`}
                    onClick={() => setTabName('content')}
                >
                    Content
                </div>
                <div
                    className={`pipeline-tab ${tabName === 'seo' ? 'pipeline-active' : ''}`}
                    onClick={() => setTabName('seo')}
                >
                    SEO
                </div>
            </div>
            <div className="pipeline-tab-content">
                {getActiveTable(tabName)}
            </div>
        </>
    );
}
