import {api} from "../../hooks/api/ApiConfig";
import {
    CLIENT_LEAD_LIST,
    CONTACT_PERSON_DATA,
    REGION_LIST
} from "../constant/salesConstant";
export const actionToGetRegionsApiCall = () => async (dispatch) => {
    const {data} = await api.post(`sales/get-regions`);
    dispatch({ type: REGION_LIST, payload: data });
    return data;
}
export const actionToGetContactPersonApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`sales/get-contact-person-by-school`,payload);
    dispatch({ type: CONTACT_PERSON_DATA, payload: data });
    return data;
}
export const actionToGetClientLeadApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`sales/get-client-lead-dropdown`,payload);
    dispatch({ type: CLIENT_LEAD_LIST, payload: data });
    return data;
}
export const actionToAddSchool = (payload) => async (dispatch) => {
    const {data} = await api.post(`sales/add-schools`,payload);
    return data;
}
export const actionToUpdateSchool = (payload) => async (dispatch) => {
    const {data} = await api.post(`sales/update-school`,payload);
    return data;
}