import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {actionToGetGradeSubjectTopicApiCall, actionToGetTopicsApiCall} from "../../../store/action/curriculumAction";
import {callDeleteDataWithLogFunction, callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import SelectGradeDropdown from "../grade/SelectGradeDropdown";
import SelectSubjectDropdown from "../subject/SelectSubjectDropdown";
import {toast} from "react-toastify";

const AddGradeSubjectTopic = (props) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [errors, setErrors] = useState({});
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const gradeSubjectTopicListData = useSelector((state) =>  state.curriculum.gradeSubjectTopicListData);
    const selectedData = props.selectedData;
    const [formData, setFormData] = useState({  });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = await validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(callInsertDataWithLogFunction({
                tableName: 'grade_subject_topic',
                data: { grade_id: obj['grade_id'], subject_id: obj['subject_id'], topic_id: selectedData.id },
                userId: auth?.id,
                eventTypeId: eventTypeId
            })).then(res => {
                if (res?.success === 1) {
                    dispatch(actionToGetGradeSubjectTopicApiCall({ topic_id: selectedData?.id }));
                    resetForm();
                    toast.success('Grade subject topic added successfully!'); // Success notification
                } else {
                    toast.error('Failed to add grade subject topic.'); // Failure notification
                }
            });
        } else {
            toast.error('Please correct the errors in the form.'); // Validation error notification
        }
    };
    const resetForm = () => {
        document.getElementById('grade-subject-topic')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.grade_id.trim()) {
            errors.grade_id = 'Grade is required';
        }

        if (!data.subject_id.trim()) {
            errors.subject_id = 'Subject is required';
        }

        return errors;
    };
    const deleteCurriculumTopic = (id) =>{
        swal({
            title: "Are you sure?",
            text: "You want to delete this curriculum topic ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'grade_subject_topic',id:id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetGradeSubjectTopicApiCall({topic_id: selectedData?.id}))
                    })
                }
            });

    }

    useEffect(()=>{
        dispatch(actionToGetGradeSubjectTopicApiCall({topic_id: selectedData?.id}))
    },[selectedData])
    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='region-state-modal'>

                    <div className='container'>
                        <div className='title'>Grade Subject Topic</div>
                        <form id={'grade-subject-topic'} onSubmit={handleSubmit} className='mt-2'>
                            <SelectGradeDropdown errors={errors} name={'grade_id'} grade_id={formData.grade_id} handleChange={handleChange}/>
                            <SelectSubjectDropdown errors={errors} name={'subject_id'} subject_id={formData.subject_id} handleChange={handleChange}/>
                            <div className='flex justify-center m-4'>
                                <button className='submit-button' type={"submit"}>Add</button>
                            </div>
                        </form>
                        <table className="image-table">
                            <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>Grade Name</th>
                                <th>Subject Name</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {gradeSubjectTopicListData?.map((topic,index) => (
                                <tr key={topic.id}>
                                    <td>{index+1}</td>
                                    <td>{topic.grade_name}</td>
                                    <td>{topic.subject_name}</td>
                                    <td> <FontAwesomeIcon className='action-icon' icon={faTrash} onClick={()=> deleteCurriculumTopic(topic.id)} /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
            </Modal>
        </div>
    );
};

export default AddGradeSubjectTopic;
