import { api } from "./ApiConfig";

/**
 * Generic API call action
 * @param {string} endpoint - The API endpoint to call
 * @param {string} actionType - Redux action type to dispatch
 * @param {Function} dispatch - Redux dispatch function
 * @param {Object|FormData} payload - Payload to send in the API call (optional)
 * @param {Object} config - Additional Axios config options (optional)
 * @returns {Promise<any>} - Returns the API data
 */
export const makeApiCall = async (endpoint, actionType, dispatch, payload = {}, config = {}) => {
    try {
        const { data } = await api.post(endpoint, payload, config);
        if (dispatch && actionType) {
            dispatch({ type: actionType, payload: data });
        }
        return data;
    } catch (error) {
        console.error(`Error while making API call to ${endpoint}:`, error);
        throw error;
    }
};
