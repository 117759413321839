import Dashboard from "../Components/Dashboard/Dashboard";
import {Suspense} from "react";
import useAuth from "../hooks/useAuth";
import {useSelector} from "react-redux";
import SalesDashboard from "../Components/Dashboard/SalesDashboard";
export default function Home() {
    const {auth} =useAuth();
    const ROLES = useSelector((state) => state.webSetting.roleList);
    return (
        <>
            {/* Suspense for lazy-loaded components */}
            <Suspense fallback={<div>Loading...</div>}>
                {auth?.role === ROLES.SalesTeam ? <SalesDashboard /> : <Dashboard/>}
            </Suspense>
        </>
    )
}
