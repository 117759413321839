import React, { useState } from 'react';
import InputField from '../../Layout/Form/InputField';
import { makeApiCall } from "../../../hooks/api/makeApiCall";
import { toast } from "react-toastify";
import SelectField from "../../Layout/Form/SelectField";
import Modal from "react-modal";
import { validateForm } from "../../../store/utility/validation";
import { useDispatch } from "react-redux";

const OrderRelateFileUploadFormModal = ({ selectedOrder, isOpen, onRequestClose }) => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const [file, setFile] = useState(null); // State for the file
    const dispatch = useDispatch();

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Save the selected file in state
    };

    const validationRules = {
        document_type: { required: true, field_name: 'Document Type' },
        order_document: { required: true, field_name: 'Order Document File' },
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a new FormData object
        const formDataPayload = new FormData();

        // Append form fields to FormData
        formDataPayload.append('order_id', selectedOrder?.id || '');
        formDataPayload.append('document_type', formData.document_type || '');
        formDataPayload.append('folderName', `Schools/${selectedOrder?.school_name || 'Default'}`);

        // Append file if selected
        if (file) {
            formDataPayload.append('order_document', file); // Field name matches input name
        } else {
            toast.error('Please select a file to upload.');
            return;
        }

        // Validate the form fields
        const newErrors = validateForm(Object.fromEntries(formDataPayload.entries()), validationRules);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                // Make the API call with FormData
                await makeApiCall('/products/upload-order-related-document', null, dispatch, formDataPayload,{
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                setSaveStatus('success');
                toast.success('File uploaded successfully!');
                onRequestClose(); // Close modal after success
            } catch (error) {
                setSaveStatus('fail');
                toast.error('Something went wrong. Please try again.');
            }
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.');
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="Modal">
            <div className="container">
                <div className="title">Upload File</div>
                <div className="vendor-details">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <SelectField
                            label="Document Type"
                            name="document_type"
                            value={formData.document_type || ''}
                            onChange={handleChange}
                            valueArray={[
                                { value: '', label: 'Please select document type' },
                                { value: 'Invoice', label: 'Invoice' },
                                { value: 'Packing Photos', label: 'Packing Photos' },
                                { value: 'Others', label: 'Others' },
                            ]}
                            error={errors.document_type}
                            className="select"
                        />

                        {/* Receiving Receipt File Input */}
                        <InputField
                            label="Order Document File"
                            type="file"
                            name="order_document"
                            onChange={handleFileChange} // File change handler
                            error={errors.order_document}
                        />

                        <div className="buttons">
                            <button className="submit-button" type="submit">
                                Upload
                            </button>
                            <button className="close-button" type="button" onClick={onRequestClose}>
                                Close
                            </button>
                        </div>

                        {saveStatus === 'success' ? (
                            <div className="success-message">File uploaded successfully!</div>
                        ) : saveStatus === 'fail' && (
                            <div className="failure-message">Something went wrong!</div>
                        )}
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default OrderRelateFileUploadFormModal;
