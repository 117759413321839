// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import SchoolContactPersonTable from "./SchoolContactPersonTable";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";

const SchoolContactPersonTableModal = ({ isOpen, onRequestClose,selectedSchool }) => {

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='description-modal'>
            <div className='m-5'>
                <BreadCrumb first_crumb={'School Contact Person Table'} second_crumb={selectedSchool.name}/>
                <SchoolContactPersonTable selectedSchool={selectedSchool} />
            </div>
            <div className='flex justify-center'>
                <button className='submit-button' onClick={onRequestClose}>Close</button>
            </div>
        </Modal>
    );
};

export default SchoolContactPersonTableModal;
