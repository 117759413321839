import DatePicker from "react-datepicker";
import React from "react";
const DateField = ({ placeholder, label, className, name, onChange, value, error, minDate='' }) => (
<div className='input-box'>
    <span className='details'>{label}</span>
    <DatePicker
        showIcon
        className={`${className} custom-datepicker`}
        name={name}
        minDate={minDate}
        dateFormat="yyyy-MM-dd"
        selected={value}
        placeholderText={placeholder}
        popperPlacement="top-end"
        onChange={(date) => onChange({target:{name:name,value:date}})}
    />
    {error && <span className="error-message">{error}</span>}
</div>)

export default DateField;
