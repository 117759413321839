import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetTopicsApiCall} from "../../../store/action/curriculumAction";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import SelectGradeDropdown from "../grade/SelectGradeDropdown";
import SelectSubjectDropdown from "../subject/SelectSubjectDropdown";
import {toast} from "react-toastify";

const AddTopics = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [formData, setFormData] = useState({  });
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            let payload = { grade_id: obj['grade_id'], subject_id: obj['subject_id'] };
            obj['created_by'] = auth?.id;
            delete obj['grade_id'];
            delete obj['subject_id'];

            dispatch(callInsertDataWithLogFunction({ tableName: 'topics', data: obj, userId: auth?.id, eventTypeId }))
                .then(res => {
                    if (res?.id) {
                        payload['topic_id'] = res.id;
                        dispatch(callInsertDataWithLogFunction({
                            tableName: 'grade_subject_topic',
                            data: payload,
                            userId: auth?.id,
                            eventTypeId: eventTypeId
                        }));
                    }
                    dispatch(actionToGetTopicsApiCall());
                    let status = res?.success === 1 ? 'success' : 'fail';
                    setSaveStatus(status);

                    if (status === 'success') {
                        resetForm();
                        toast.success('Topic created successfully!'); // Success notification
                    } else {
                        toast.error('Failed to create topic.'); // Failure notification
                    }
                });
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.'); // Validation error notification
        }
    };

    const resetForm = () => {
        document.getElementById('topic-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Topic is required';
        }
        if (!data.grade_id.trim()) {
            errors.grade_id = 'Grade is required';
        }
        if (!data.subject_id.trim()) {
            errors.subject_id = 'Subject is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        setFormData({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='region-state-modal'>
            <div className="container">
                <div className='title'>Add Topic</div>
                <form onSubmit={handleSubmit} id='topic-form'>
                    <div className='vendor-details'>
                        <SelectGradeDropdown errors={errors} name={'grade_id'} grade_id={formData.grade_id} handleChange={handleChange}/>
                        <SelectSubjectDropdown errors={errors} name={'subject_id'} subject_id={formData.subject_id} handleChange={handleChange}/>
                        <div className='input-box'>
                            <span className='details'>Topic Name</span>
                            <input type='text' placeholder='Enter Topic Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Topic Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddTopics;
