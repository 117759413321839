// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import TextArea from "../../Layout/Form/TextArea";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import SelectCityDropdown from "../../Sales/region/SelectCityDropdown";
import SelectStateDropdown from "../../Sales/region/SelectStateDropdown";
import {PinCodeText} from "../../Sales/region/PinCodeText";
import SelectDesignationDropdown from "../../Sales/contact-person/SelectDesignationDropdown";

const TransporterForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch,type }) => {
    const {auth} = useAuth();
    //const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.inventory.inventoryEventTypeId);

    const validationRules = {
        name: { required: true, minLength: 3, maxLength: 50 },
        phone: { required: true, type: 'phone' },
    };
    const onSuccess = (res)=>{
        onRequestClose();
        handleExternalFetch?.(res,['transporter']);

    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged,updatePinCode } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Transporter' : 'Add Transporter'} />
                <form onSubmit={(e) => handleSubmit(e,validationRules,'transporter',{created_by:auth?.id},[])}>
                    <div className='vendor-details'>
                        <InputField
                            label="Transporter Company Name"
                            type="text"
                            name="name"
                            value={formData.name || ''}
                            onChange={handleChange}
                            error={errors.name} placeholder='Enter Transporter Name'
                        />
                        <InputField
                            label="Transporter Company Phone"
                            type="text"
                            name="phone"
                            value={formData.phone || ''}
                            onChange={handleChange}
                            error={errors.phone} placeholder='Enter Phone Number'
                        />
                        <input type={"hidden"} value={type} name={"type"} />
                        <InputField
                            label="Transporter Company Email"
                            type="email"
                            name="email"
                            value={formData.email || ''}
                            onChange={handleChange}
                            error={errors.email} placeholder='Enter Email'
                        />
                        <InputField
                            label="GST Number"
                            type="text"
                            name="gst"
                            value={formData.gst || ''}
                            onChange={handleChange}
                            error={errors.gst} placeholder='Enter GST'
                        />
                        {!isEditMode ?<> <InputField
                            label="Contact Person Name"
                            type="text"
                            name="contact_person_name"
                            value={formData.contact_person_name || ''}
                            onChange={handleChange}
                            error={errors.contact_person_name} placeholder='Enter Contact Person Name'
                        />
                            <SelectDesignationDropdown errors={errors} name="designation_id" designation_id={formData.designation_id} handleChange={handleChange}  />
                            <InputField
                                label="Contact Person Phone"
                                type="text"
                                name="contact_person_phone"
                                value={formData.contact_person_phone || ''}
                                onChange={handleChange}
                                error={errors.contact_person_phone} placeholder='Enter Phone Number'
                            />
                            <InputField
                                label="Contact Person Phone2"
                                type="text"
                                name="contact_person_phone2"
                                value={formData.contact_person_phone2 || ''}
                                onChange={handleChange}
                                error={errors.contact_person_phone2} placeholder='Enter Second Phone Number'
                            /></> : <input type={"hidden"} value={selectedData?.address_id} name={"address_id"} />}
                        <TextArea
                            className='text-area'
                            name="address"
                            label="Address"
                            value={formData.address || ''}
                            onChange={handleChange} placeholder='Enter Transporter Company Address'
                        />
                        <PinCodeText errors={errors} name="pincode" pin_code={formData.pincode} updatePinCode={updatePinCode} />
                        <SelectStateDropdown errors={errors} name="state_id" state_id={formData.state_id} handleChange={handleChange} />
                        <SelectCityDropdown errors={errors} name="city_id" state_id={formData.state_id} city_id={formData.city_id} handleChange={handleChange} />
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default TransporterForm;
