import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetTopicsApiCall} from "../../../store/action/curriculumAction";
import useAuth from "../../../hooks/useAuth";
import {callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import {toast} from "react-toastify";


const EditTopics= ({ isOpen,selectedData, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [formData, setFormData] = useState({
        name: ''
    });

    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedData));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(callUpdateDataWithLogFunction({
                tableName: 'topics',
                data: obj,
                id: selectedData?.id,
                userId: auth?.id,
                eventTypeId
            })).then(res => {
                let status = res?.success === 1 ? 'success' : 'fail';
                setSaveStatus(status);

                if (status === 'success') {
                    dispatch(actionToGetTopicsApiCall());
                    onRequestClose();
                    toast.success('Topic updated successfully!'); // Success notification
                } else {
                    toast.error('Failed to update topic.'); // Failure notification
                }
            });
        } else {
            setSaveStatus('fail');
            toast.error('Please correct the errors in the form.'); // Validation error notification
        }
    };

    useEffect(() => {
        setFormData(selectedData);
    }, [selectedData]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Topic Name is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Edit Topic</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='mt-4'>
                        <div className='input-box'>
                            <span className='details'>Topic Name</span>
                            <input type='text' placeholder='Topic Name*'
                                   name='name' className='common-text-area'
                                   value={formData?.name}
                                   onChange={handleChange}/>
                            {errors?.name && <span className="error-message">{errors.name} </span>}
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'  disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Topic Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};


export default EditTopics;
