import React from 'react';
import InventoryHeader from "../InventoryHeader.js";
import PurchaseForm from "./PurchaseForm.js";
import PurchaseTable from "./PurchaseTable.js";

const PurchaseManagement = () => {

    return (

        <section className='admin-tab-section'>
            <InventoryHeader/>
            <div className={"row"}>
                <div className={"col-md-5"}>
                    <PurchaseForm />
                </div>
                <div className={"col-md-7"}>
                    <PurchaseTable />
                </div>

            </div>


        </section>
    )
}

export default PurchaseManagement
